import React, {useState, useCallback} from 'react';
import {
    DndContext,
    closestCenter,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    PointerSensor,
} from '@dnd-kit/core';
import {arrayMove, SortableContext, rectSortingStrategy} from '@dnd-kit/sortable';
import Grid from "../grid/Grid";
import SortableItem from "../sortable-item/SortableItem";
import GridItem from "../grid-item/GridItem";
import {createPortal} from 'react-dom';

const Dnd = ({items, exportItems, reorderItems, ...props}) => {
    const [dndItems, setDndItems] = useState(items)
    const [activeId, setActiveId] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: {
            distance: 8,
        },
    }), useSensor(TouchSensor));

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
        setActiveItem(dndItems.find((item) => item.id === event.active.id));
    }, []);
    const handleDragEnd = useCallback((event) => {
        const {active, over} = event;

        if (active.id !== over?.id) {
            setDndItems((dndItems) => {

                const oldIndex = dndItems.findIndex((item) => item.id === active.id);
                const newIndex = dndItems.findIndex((item) => item.id === over.id);

                const newArr = arrayMove(dndItems, oldIndex, newIndex)

                newArr.forEach((newItem, index) => newItem.order = index);

                reorderItems(newArr)

                return newArr;
            });
        }

        setActiveId(null);
    }, []);
    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={dndItems} strategy={rectSortingStrategy}>
                <Grid columns={4}>
                    {dndItems.map((item) => (
                        <SortableItem key={item.id} item={item}  isChecked={!!exportItems[item.id]} {...props}/>
                    ))}
                </Grid>
            </SortableContext>
            {createPortal(
                <DragOverlay adjustScale style={{transformOrigin: '0 0 '}}>
                    {activeId ? <GridItem item={activeItem} isDragging/> : null}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
};

export default Dnd;