import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import styles from './overlay.module.scss';

import timesIcon from '../../assets/icons/times.svg';

import OverlayDetailPage from '../overlay-detail-page/OverlayDetailPage';
import OverlayFavoritesPage from '../overlay-favorites-page/OverlayFavoritesPage';

import { useTranslation } from 'react-i18next';
import { router } from '../../router';
import OverlayHelpPage from '../overlay-help-page/OverlayHelpPage';
import OverlayHistoryPage from '../overlay-history-page/OverlayHistoryPage';
import OverlayNotificationsPage from '../overlay-notifications-page/OverlayNotificationsPage';
import OverlayReferenceSubmitPage from '../overlay-reference-submit/OverlayReferenceSubmit';
import OverlaySearchLogPage from '../overlay-search-log/OverlaySearchLog';
import OverlaySearchPage from '../overlay-search-page/OverlaySearchPage';
import OverlaySubmitedReferences from '../overlay-submited-references/OverlaySubmitedReferences';

export const VIEW_MODES = {
  DETAIL_PAGE: 'detail_page',
  FAVORITES_PAGES: 'favorites_page',
  SEARCH_PAGE: 'search_page',
  HISTORY_PAGE: 'history_page',
  SEARCHLOG_PAGE: 'searchlog_page',
  SUBMIT_REFERENCE_PAGE: 'submit_reference_page',
  SUBMITED_REFERENCES_PAGE: 'submited_references_page',
  NOTIFICATIONS_PAGE: 'notifications_page',
  HELP_PAGE: 'help_page',
};

const Overlay = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [t] = useTranslation('translations');
  const [viewMode, setViewMode] = useState(null);
  const searchParam = window.location.hash
    .split('?')[1]
    ?.split('=')
    .find((el) => el === 'search');

  const currentViewMode = useSelector((state) => state.overlay.currentViewMode);
  const currentDetailPage = useSelector((state) => state.overlay.currentDetailPageMarkerId);

  useEffect(() => {
    setOpen(currentDetailPage !== null);
  }, [currentDetailPage]);

  useEffect(() => {
    setViewMode(currentViewMode);
  }, [currentViewMode]);

  const close = () => {
    if (searchParam && currentViewMode === VIEW_MODES.DETAIL_PAGE) {
      router.navigate(`/${t('navigation.search')}.html`);
      setOpen(false);
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.SEARCH_PAGE));

      return;
    }

    router.navigate('/');

    setOpen(false);
    setTimeout(() => {
      dispatch(actions.overlayActions.setViewMode(null));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    }, 300);
  };

  return (
    <div
      className={classNames(styles.overlay, {
        [styles.open]: open || viewMode,
      })}
    >
      <div onClick={close} className={styles.close}>
        <SVG src={timesIcon} />
      </div>
      <div className={styles.contentHolder}>
        <div className={styles.content}>
          {VIEW_MODES.DETAIL_PAGE === viewMode && <OverlayDetailPage />}
          {VIEW_MODES.FAVORITES_PAGES === viewMode && <OverlayFavoritesPage />}
          {VIEW_MODES.SEARCH_PAGE === viewMode && <OverlaySearchPage />}
          {VIEW_MODES.SEARCHLOG_PAGE === viewMode && <OverlaySearchLogPage />}
          {VIEW_MODES.SUBMIT_REFERENCE_PAGE === viewMode && <OverlayReferenceSubmitPage />}
          {VIEW_MODES.SUBMITED_REFERENCES_PAGE === viewMode && <OverlaySubmitedReferences />}
          {VIEW_MODES.HISTORY_PAGE === viewMode && <OverlayHistoryPage />}
          {VIEW_MODES.NOTIFICATIONS_PAGE === viewMode && <OverlayNotificationsPage />}
          {VIEW_MODES.HELP_PAGE === viewMode && <OverlayHelpPage />}
        </div>
      </div>
    </div>
  );
};

export default Overlay;
