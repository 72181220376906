import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import { Animated } from 'react-animated-css';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import timesIcon from '../../assets/icons/times.svg';
import Headline from '../headline/Headline';
import Text from '../text/Text';
import styles from './modal.module.scss';

const Modal = (props) => {
  const [t] = useTranslation('translations');
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);

    setTimeout(() => {
      props.onClose();
    }, 300);
  };

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props.showModal]);

  return createPortal(
    <>
      <div
        onClick={closeModal}
        className={classNames(
          styles.modal,
          [showModal ? styles.modalShow : ''],
          [props.black ? styles.modalBlack : '']
        )}
      >
        <Animated
          animationIn='zoomIn'
          animationOut='zoomOut'
          className={styles.animation}
          isVisible={showModal}
          animationInDuration={300}
          animationOutDuration={300}
        >
          <div
            style={props.style ? props.style : {}}
            onClick={(e) => e.stopPropagation()}
            className={classNames(styles.modalContent)}
          >
            {(props.title || props.subTitle || !props.hideClose) && (
              <div className={classNames(styles.modalHeader)}>
                {props.title && <Headline size='h1'>{props.title}</Headline>}
                {props.subTitle && (
                  <Text>
                    {t('detail.refNumber')} {props.subTitle}
                  </Text>
                )}
                {!props.hideClose && (
                  <SVG
                    className={classNames(styles.closeIcon)}
                    onClick={closeModal}
                    src={timesIcon}
                  />
                )}
              </div>
            )}
            <div className={classNames(styles.modalBody)}>{props.children}</div>
          </div>
        </Animated>
      </div>
    </>,
    document.body
  );
};

export default Modal;
