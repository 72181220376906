export const ReferenceSubmitFields = {
  title: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  name: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  street: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  contact: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  zip: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  city: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  phone: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  email: {
    value: '',
    type: 'email',
    valid: true,
    validation: '',
  },
  newlyBuilt: {
    value: false,
    type: 'checkbox',
  },
  renovationOrExtension: {
    value: false,
    type: 'checkbox',
  },
  differentAddress: {
    value: false,
    type: 'checkbox',
  },
  differentStreet: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  differentZip: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  differentCity: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectName: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectStreet: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectContact: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectZip: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectCity: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectPhone: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  architectEmail: {
    value: '',
    type: 'email',
    valid: true,
    validation: '',
  },
  directly: {
    value: false,
    type: 'checkbox',
  },
  qualityPartner: {
    value: false,
    type: 'checkbox',
  },
  architect: {
    value: false,
    type: 'checkbox',
  },
  sales: {
    value: false,
    type: 'text',
  },
  establishContact: {
    value: '',
    type: 'text',
    valid: true,
    validation: '^(?!\\s*$).+',
  },
  establishPhone: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  establishEmail: {
    value: '',
    type: 'email',
    valid: true,
    validation: '',
  },
  systems: [
    [
      {
        name: 'product',
        value: '',
        type: 'select',
        valid: true,
        validation: '^(?!\\s*$).+',
      },
      {
        name: 'system',
        value: '',
        type: 'select',
        valid: true,
        validation: '^(?!\\s*$).+',
      },
    ],
  ],
  specialFeatures: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  year: {
    value: '',
    type: 'number',
    valid: true,
    validation: '',
  },
  yearPatio: {
    value: '',
    type: 'number',
    valid: true,
    validation: '',
  },
  area: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  shading: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  aeration: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  opening: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  other: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  interestingArchitecture: {
    value: false,
    type: 'checkbox',
  },
  specialUse: {
    value: false,
    type: 'checkbox',
  },
  otherUse: {
    value: false,
    type: 'checkbox',
  },
  additionalInfo: {
    value: '',
    type: 'text',
    valid: true,
    validation: '',
  },
  orderNumber1: {
    value: '',
    type: 'text',
    valid: true,
  },
  orderNumber2: {
    value: '',
    type: 'text',
    valid: true,
  },
  orderNumber3: {
    value: '',
    type: 'text',
    valid: true,
  },
  orderNumber4: {
    value: '',
    type: 'text',
    valid: true,
  },
  orderNumber5: {
    value: '',
    type: 'text',
    valid: true,
  },
};
