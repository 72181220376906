import axios from 'axios';

const getLangId = (language) => {
  switch (language) {
    case 'de':
      return 79;
    case 'en':
      return 211;
    default:
      return 79;
  }
};

export const spacesApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  params: {
    langId: getLangId(localStorage.getItem('i18nextLng')),
  },
});
