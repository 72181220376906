import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import store from './redux/store';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider } from 'react-i18next';
import translations_de from './translations/de/translations.json';
import translations_en from './translations/en/translations.json';

import App from './components/App';
import Router from './components/router/Router';

import './index.scss';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'urlPathDetector',

  lookup(options) {
    const current = localStorage.getItem('i18nextLng');

    if (/projekt|suche|favoriten]/.test(window.location.href)) {
      localStorage.setItem('i18nextLng', 'de');
      if (current !== 'de') {
        window.location.reload();
      }

      return 'de';
    }

    if (/project|search|favorites]/.test(window.location.href)) {
      localStorage.setItem('i18nextLng', 'en');
      if (current !== 'en') {
        window.location.reload();
      }

      return 'en';
    }

    if (null === current) {
      return 'de';
    }
  },

  cacheUserLanguage(lng, options) {},
});

const options = {
  order: ['urlPathDetector', 'localStorage'],
};
i18next.use(languageDetector).init({
  detection: options,
  interpolation: { escapeValue: false },
  resources: {
    de: {
      translations: translations_de,
    },
    en: {
      translations: translations_en,
    },
  },
});

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <React.Fragment>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <Router />
        <App />
      </Provider>
    </I18nextProvider>
  </React.Fragment>
);
