import React from 'react';

import classNames from 'classnames';

import styles from './input.module.scss';

const Input = ({ type, name, label, onChange, placeholder, disabled, value, invalid, design, mandatory }) => {
  return (
    <label
      className={classNames(
        styles.label,
        {
          [styles[design]]: design,
        },
        {
          [styles.noLabel]: !label,
        }
      )}
      htmlFor={`input-${name}`}
    >
      {label} {mandatory ? (<span className={styles.mandatory}>*</span>) : ''}
      <input
        id={`input-${name}`}
        name={name}
        className={classNames(styles.field, {
          [styles.error]: invalid,
        })}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        disabled={disabled}
        defaultValue={value}
      />
    </label>
  );
};

export default Input;
