import { LOAD_HISTORY, SET_HISTORY_ITEM } from '../redux/actionTypes';

import actions from '../redux/actions';

export const fetchHistory = (storeAPI) => (next) => (action) => {
  if (action.type !== LOAD_HISTORY) return next(action);

  const history = localStorage.getItem('history') ?? [];

  if (history.length) {
    storeAPI.dispatch(actions.historyActions.setHistory(JSON.parse(history)));
  }
};

export const setHistory = (storeAPI) => (next) => (action) => {
  if (action.type !== SET_HISTORY_ITEM) return next(action);

  const history = action.payload.history;

  localStorage.setItem('history', JSON.stringify(history));
  storeAPI.dispatch(actions.historyActions.setHistory(history));
};
