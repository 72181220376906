import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import SVG from 'react-inlinesvg';
import resetFilterIcon from '../../assets/icons/filter-circle-xmark-light.svg';
import TypologyFilterItem from '../typology-filter-item/TypologyFilterItem';
import styles from './drawerContentTypologies.module.scss';

const DrawerContentTypologies = () => {
  const dispatch = useDispatch();

  const typologies = useSelector((state) => state.filter.typologies);
  const currentFilter = useSelector((state) => state.filter.options);

  const addTypologyFilter = (typology) => {
    const selectedTypologies = currentFilter.typologies;
    selectedTypologies.push(typology);

    const typologies = selectedTypologies;
    const products = [];

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const removeAllTypologyFilters = () => {
    const typologies = [];
    const products = [];

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const removeTypologyFilter = (typology) => {
    const typologies = currentFilter.typologies.filter((item) => item !== typology);
    const products = [];

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  return (
    <div className={styles.typologies}>
      {currentFilter.typologies.length ? (
        <div onClick={removeAllTypologyFilters} className={styles.removeFilters}>
          <SVG className={styles.expanded} src={resetFilterIcon} />
        </div>
      ) : (
        <></>
      )}
      {typologies.map((typology, index) => {
        return (
          <TypologyFilterItem
            key={index}
            typology={typology}
            onChange={(added) => {
              if (added) {
                addTypologyFilter(typology.tagId);
              } else {
                removeTypologyFilter(typology.tagId);
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default DrawerContentTypologies;
