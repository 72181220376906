import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import i18next from 'i18next';
import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import logo from '../../assets/logo_no_r.png';

Font.register({
  family: 'ProximaNova',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'light',
      src: 'https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
    },
  ],
});
Font.register({
  family: 'BrandonGrotesque',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'light',
      src: 'https://use.typekit.net/af/2f8f3d/0000000000000000000132dd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 40,
  },
  logo: {
    marginLeft: 'auto',
    marginBottom: 20,
    maxWidth: 150,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'ProximaNova',
    color: '#000000',
    textTransform: 'uppercase',
  },
  textStyle: {
    fontWeight: 'bold',
    fontFamily: 'ProximaNova',
  },
  refNr: {
    fontSize: 12,
    fontFamily: 'ProximaNova',
    color: '#4a4a49',
    marginBottom: 25,
  },
  systems: {
    fontSize: 12,
    fontFamily: 'ProximaNova',
    color: '#a8a8a7',
    marginRight: 15,
  },
  contact: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'ProximaNova',
    color: '#4a4a49',
    marginRight: 15,
  },
  systemItem1: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '38%',
  },
  systemItem2: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '29%',
  },
  systemItem3: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '33%',
  },
  image: {
    width: 200,
  },
  productDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  products: {
    marginBottom: 20,
  },
  contactWrapper: {},
  elementsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  elementWrapper: {
    marginBottom: 20,
  },
  marginLeft: {
    marginBottom: 20,
  },
  productDetails: {
    borderLeft: 3,
    paddingLeft: 3,
    marginRight: 50,
    borderLeftColor: '#000000',
  },
  productDetailsText: {
    color: '#000000',
    fontWeight: 'bold',
    fontFamily: 'ProximaNova',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  productDetailsLink: {
    color: '#D51130',
    fontFamily: 'ProximaNova',
    fontSize: 14,
    textDecoration: 'none',
    paddingBottom: 2,
  },
  textStyleDesc: {
    marginTop: 30,
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'ProximaNova',
    color: '#000000',
    textTransform: 'uppercase',
  },
  textStyleDescText: {
    marginTop: 10,
    fontSize: 15,
    fontFamily: 'ProximaNova',
    color: '#000000',
  },
  link: {
    fontFamily: 'ProximaNova',
    color: '#D51130',
  },
});

const PdfDocument = (props) => {
  const { data } = props;

  const format = (inputDate) => {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${date}.${month}.${year}`;
  };

  return (
    <Document>
      {props.data ? (
        <Page style={styles.page}>
          <View>
            <Image style={styles.logo} source={logo} />
          </View>
          <View>
            <Text style={styles.title}>
              <p>
                <Text>{data.title}</Text>
              </p>
            </Text>
            <Text style={styles.refNr}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.contact')}</Text>
                    <Text> </Text>
                    <Text>{data.establishContact}</Text>
                  </p>
                )}
              </Translation>
            </Text>
          </View>
          <View style={styles.products}>
            {JSON.parse(data.productsSystems).map((system, index) => {
              return (
                <View key={index} style={styles.productDetailsWrapper}>
                  <View style={styles.systemItem1}>
                    <Text style={styles.contact}>
                      <Translation ns={'translations'} i18n={i18next}>
                        {(t, i18next) => (
                          <p>
                            <Text style={styles.textStyle}>{t('submitReference.product')}</Text>
                            <Text> </Text>
                            <Text>{system.product}</Text>
                          </p>
                        )}
                      </Translation>
                    </Text>
                  </View>
                  <View style={styles.systemItem2}>
                    <Text style={styles.contact}>
                      <Translation ns={'translations'} i18n={i18next}>
                        {(t, i18next) => (
                          <p>
                            <Text style={styles.textStyle}>{t('submitReference.system')}</Text>
                            <Text> </Text>
                            <Text>{system.system}</Text>
                          </p>
                        )}
                      </Translation>
                    </Text>
                  </View>

                  {index + 1 === JSON.parse(data.productsSystems).length && (
                    <View style={styles.systemItem3}>
                      <Text style={styles.contact}>
                        <Translation ns={'translations'} i18n={i18next}>
                          {(t, i18next) => (
                            <p>
                              <Text style={styles.textStyle}>
                                {t('submitedReferences.dateModal')}
                              </Text>
                              <Text> </Text>
                              <Text>{format(new Date(data.createdAt))}</Text>
                            </p>
                          )}
                        </Translation>
                      </Text>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
          <View className={styles.contactWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.contactInfo')}</Text>
                  </p>
                )}
              </Translation>
            </Text>
          </View>
          <View style={styles.elementsWrapper}>
            <View style={styles.systemItem1}>
              <Text style={styles.contact}>{data.name}</Text>
              <Text style={styles.contact}>{data.street}</Text>
              <Text style={styles.contact}>
                {data.zip} {data.city}
              </Text>
            </View>
            <View style={styles.systemItem1}>
              <Text style={styles.contact}>{data.contact}</Text>
              <Text style={styles.contact}>
                <a style={styles.link} href={`mailto:${data.email}`}>
                  <Text>{data.email}</Text>
                </a>
              </Text>
              <Text style={styles.contact}>{data.phone}</Text>
            </View>
          </View>

          <View className={styles.contactWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.objectIs')}</Text>
                  </p>
                )}
              </Translation>
            </Text>
          </View>

          <View style={styles.elementWrapper}>
            {data.newlyBuilt && (
              <Text style={styles.contact}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.newlyBuilt')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
            )}
            {data.renovationOrExtension && (
              <Text style={styles.contact}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>
                        {t('submitReference.renovationOrExtension')}
                      </Text>
                    </p>
                  )}
                </Translation>
              </Text>
            )}
          </View>

          {data.differentAddress && (
            <View style={styles.elementsWrapper}>
              <View style={styles.systemItem1}>
                <Text style={styles.contact}>{data.differentStreet}</Text>
                <Text style={styles.contact}>
                  {data.differentZip} {data.differentCity}
                </Text>
              </View>
            </View>
          )}

          <View className={styles.contactWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>
                      {t('submitReference.contactArchitectInfo')}
                    </Text>
                  </p>
                )}
              </Translation>
            </Text>
          </View>
          <View style={styles.elementsWrapper}>
            <View style={styles.systemItem1}>
              <Text style={styles.contact}>{data.architectName}</Text>
              <Text style={styles.contact}>{data.architectStreet}</Text>
              <Text style={styles.contact}>
                {data.architectZip} {data.architectCity}
              </Text>
            </View>
            <View style={styles.systemItem1}>
              <Text style={styles.contact}>{data.architectContact}</Text>
              <Text style={styles.contact}>
                <a style={styles.link} href={`mailto:${data.architectEmail}`}>
                  <Text>{data.architectEmail}</Text>
                </a>
              </Text>
              <Text style={styles.contact}>{data.architectPhone}</Text>
            </View>
          </View>

          <View style={styles.elementsWrapper}>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.establishContact')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              {data.directly && (
                <Text style={styles.contact}>
                  <Translation ns={'translations'} i18n={i18next}>
                    {(t, i18next) => (
                      <p>
                        <Text style={styles.textStyle}>{t('submitReference.directly')}</Text>
                      </p>
                    )}
                  </Translation>
                </Text>
              )}
              {data.qualityPartner && (
                <Text style={styles.contact}>
                  <Translation ns={'translations'} i18n={i18next}>
                    {(t, i18next) => (
                      <p>
                        <Text style={styles.textStyle}>{t('submitReference.qualityPartner')}</Text>
                      </p>
                    )}
                  </Translation>
                </Text>
              )}
              {data.architect && (
                <Text style={styles.contact}>
                  <Translation ns={'translations'} i18n={i18next}>
                    {(t, i18next) => (
                      <p>
                        <Text style={styles.textStyle}>{t('submitReference.architect')}</Text>
                      </p>
                    )}
                  </Translation>
                </Text>
              )}
              {data.sales && (
                <Text style={styles.contact}>
                  <Translation ns={'translations'} i18n={i18next}>
                    {(t, i18next) => (
                      <p>
                        <Text style={styles.textStyle}>{t('submitReference.sales')}</Text>
                      </p>
                    )}
                  </Translation>
                </Text>
              )}
            </View>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.contact')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.establishContact}</Text>
              <Text style={styles.contact}>
                <a style={styles.link} href={`mailto:${data.establishEmail}`}>
                  <Text>{data.establishEmail}</Text>
                </a>
              </Text>
              <Text style={styles.contact}>{data.establishPhone}</Text>
            </View>
          </View>

          <View className={styles.contactWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.specialFeatures')}</Text>
                  </p>
                )}
              </Translation>
            </Text>

            <View>
              <Text style={styles.contact}>
                {data.specialFeatures?.replace(/(\r\n|\r|\n)/g, '\n')}
              </Text>
            </View>
          </View>

          <View className={styles.contactWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.keyData')}</Text>
                  </p>
                )}
              </Translation>
            </Text>
          </View>
          <View style={styles.elementsWrapper}>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.year')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.year}</Text>
            </View>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.yearPatio')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.yearPatio}</Text>
            </View>
          </View>
          <View style={styles.elementsWrapper}>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.area')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.area}</Text>
            </View>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.shading')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.shading}</Text>
            </View>
          </View>
          <View style={styles.elementsWrapper}>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.aeration')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.aeration}</Text>
            </View>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.opening')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.opening}</Text>
            </View>
          </View>

          <View style={styles.elementsWrapper}>
            <View style={styles.elementWrapper}>
              <Text style={styles.systems}>
                <Translation ns={'translations'} i18n={i18next}>
                  {(t, i18next) => (
                    <p>
                      <Text style={styles.textStyle}>{t('submitReference.other')}</Text>
                    </p>
                  )}
                </Translation>
              </Text>
              <Text style={styles.contact}>{data.other}</Text>
            </View>
          </View>

          <View className={styles.elementWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.additionalInfo')}</Text>
                  </p>
                )}
              </Translation>
            </Text>

            <View>
              <Text style={styles.contact}>
                {data.additionalInfo?.replace(/(\r\n|\r|\n)/g, '\n')}
              </Text>
            </View>
          </View>

          <View style={styles.elementWrapper}>
            <Text style={styles.systems}>
              <Translation ns={'translations'} i18n={i18next}>
                {(t, i18next) => (
                  <p>
                    <Text style={styles.textStyle}>{t('submitReference.orderNumbers')}</Text>
                  </p>
                )}
              </Translation>
            </Text>
            <Text style={styles.contact}>{data.orderNumber1}</Text>
            <Text style={styles.contact}>{data.orderNumber2}</Text>
            <Text style={styles.contact}>{data.orderNumber3}</Text>
            <Text style={styles.contact}>{data.orderNumber4}</Text>
            <Text style={styles.contact}>{data.orderNumber5}</Text>
          </View>
        </Page>
      ) : (
        ''
      )}
    </Document>
  );
};

export default withTranslation('translations')(PdfDocument);
