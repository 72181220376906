import React, { useState } from 'react';

import styles from './collapsible.module.scss';

import angleDownIcon from '../../assets/icons/angle-down.svg';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

const Collapsible = ({ elementStyle, defaultOpen, children }) => {
  const [open, setOpen] = useState(!!defaultOpen);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.title, elementStyle ? styles[elementStyle] : '')}
        onClick={() => setOpen(!open)}
      >
        {children[0]}
        <SVG
          src={angleDownIcon}
          className={classNames({
            [styles.flipAngle]: open,
          })}
        />
      </div>
      <div
        className={classNames(styles.body, {
          [styles.open]: open,
        })}
      >
        {children[1]}
      </div>
    </div>
  );
};

export default Collapsible;
