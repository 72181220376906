import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import styles from './markerContent.module.scss';

import angleRightIcon from '../../assets/icons/angle-right.svg';
import closeIcon from '../../assets/icons/times.svg';

import { Animated } from 'react-animated-css';
import { router } from '../../router';

const MarkerContent = ({ markerId, content, open, date, onClose }) => {
  const [t] = useTranslation('translations');

  const today = new Date();
  const [limit] = useState(new Date(today.setDate(today.getDate() - 5)));
  const [markerDate] = useState(new Date(date));
  const openDetailView = () => {
    router.navigate(`/${t('navigation.reference')}/${markerId}-${content.slug}.html`);
  };

  const close = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };

  return (
    <>
      <Animated
        key={content.id}
        animationIn='zoomIn'
        animationOut='zoomOut'
        isVisible={open}
        animationInDuration={500}
        animationOutDuration={500}
      >
        <div
          style={{ display: open ? 'block' : 'none' }}
          onClick={openDetailView}
          className={classNames(styles.content)}
        >
          <div onClick={close} className={styles.close}>
            <SVG src={closeIcon} />
          </div>
          <div className={styles.image}>
            <img src={content.file} alt='Img' />
          </div>
          <div className={styles.spacer}>
            <div className={styles.product}>{content.product}</div>
            <div className={styles.location}>{content.city}</div>
            <div className={styles.infoRow}>{content.system}</div>
            <div onClick={openDetailView} className={styles.openDetails}>
              <SVG src={angleRightIcon} />
            </div>
            {markerDate.getTime() > limit.getTime() && open && (
              <span className={styles.new}>{t('marker.new')}</span>
            )}
          </div>
        </div>
      </Animated>
    </>
  );
};

export default withTranslation('translations')(MarkerContent);
