import React from 'react';

import classNames from 'classnames';

import styles from './select.module.scss';

const Select = ({ name, label, onChange, disabled, selected, options, mandatory }) => {
  const selectedOption = options.find((option) =>
    option.type && option.type === 'optgroup'
      ? option.options.find((suboption) => suboption.label === selected)
      : option.label === selected
  );

  return (
    <label className={classNames(styles.checkboxLabel, styles.label)} htmlFor={`input-${name}`}>
      <span>
        {label}
        {mandatory ? <span className={styles.mandatory}>*</span> : ''}
      </span>
      <select
        onChange={onChange}
        name={name}
        defaultValue={selectedOption?.id}
        // value={selectedOption.id}
      >
        {options.map((option, index) => {
          if (option.type && option.type === 'optgroup') {
            return (
              <optgroup key={option.label + index} label={option.label}>
                {option.options.map((suboption, subIndex) => (
                  <option key={suboption.label + index + subIndex} value={suboption.id}>
                    {suboption.label}
                  </option>
                ))}
              </optgroup>
            );
          } else {
            return (
              <option key={index} value={option.id}>
                {option.label}
              </option>
            );
          }
        })}
      </select>
    </label>
  );
};

export default Select;
