import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import SVG from 'react-inlinesvg';

import styles from './projectTeaser.module.scss';

import starIcon from '../../assets/icons/star.svg';

const ProjectTeaser = ({
  referenceId,
  image,
  title,
  isTopReference,
  product,
  onClick,
  date,
  canExport,
  exportCheck,
  isChecked,
}) => {
  const [t] = useTranslation('translations');
  const today = new Date();
  const [limit] = useState(new Date(today.setDate(today.getDate() - 5)));
  const markerDate = date ? new Date(date) : null;

  const checkItem = (e) => {
    e.stopPropagation();
    exportCheck();
  };

  return (
    <div onClick={onClick} className={styles.projectTeaser}>
      <div className={styles.image}>
        <img src={image} alt={title} />
        {markerDate && markerDate.getTime() > limit.getTime() && (
          <span className={styles.new}>{t('marker.new')}</span>
        )}
        {isTopReference && (
          <div className={styles.topProject}>
            <SVG src={starIcon} />
            {t('detail.top')}
            <SVG src={starIcon} />
          </div>
        )}
        {canExport && (
          <div
            className={classNames(styles.projectTeaserExport, {
              [styles.projectTeaserExportSelected]: isChecked,
            })}
            onClick={checkItem}
          >
            {' '}
          </div>
        )}
      </div>
      <div className={styles.title}>{title}</div>
      {referenceId && (
        <div className={styles.refId}>
          {t('detail.refNumber')} {referenceId}
        </div>
      )}
      {product && <div className={styles.product}>{product}</div>}

    </div>
  );
};

export default withTranslation('translations')(ProjectTeaser);
