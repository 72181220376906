import {
  CLOSE_DRAWER,
  LOADED_REFERENCE,
  LOAD_REFERENCE,
  OPEN_DETAIL_PAGE,
  SET_OVERLAY_VIEW_MODE,
} from '../actionTypes';

const loadReference = (referenceId) => ({
  type: LOAD_REFERENCE,
  payload: { referenceId },
});

const setDetailPageMarkerId = (markerId) => ({
  type: OPEN_DETAIL_PAGE,
  payload: {
    id: markerId,
  },
});

const setReference = (reference) => ({
  type: LOADED_REFERENCE,
  payload: { ...reference },
});

const setViewMode = (viewMode) => ({
  type: SET_OVERLAY_VIEW_MODE,
  payload: { viewMode },
});

const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export default {
  closeDrawer,
  loadReference,
  setDetailPageMarkerId,
  setReference,
  setViewMode,
};
