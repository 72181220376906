import { SET_SEARCHLOG_ITEMS } from '../actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCHLOG_ITEMS: {
      const searchlog = action.payload.searchlog;
      return [...searchlog];
    }
    default:
      return state;
  }
};
