import { LOAD_SUBMITED_REFERENCES, SET_SUBMITED_REFERENCES_ITEMS } from '../actionTypes';

const loadSubmitedReferences = () => ({
  type: LOAD_SUBMITED_REFERENCES,
});

const setSubmitedReferences = (submitedReferences) => ({
  type: SET_SUBMITED_REFERENCES_ITEMS,
  payload: { submitedReferences },
});

export default {
  loadSubmitedReferences,
  setSubmitedReferences,
};
