import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { sendDataLayerEvent } from '../../gtm/datalayerSendEvent';
import styles from './brochureRequest.module.scss';

const spacesIdeaBook = {
  color: '#4A4A49',
  image: 'https://solarlux.com/files/jpg3/solarlux-living-broschuere-de.jpg',
  translations: {
    de: {
      link: 'https://solarlux.com/de-de/inspirationen/broschueren/living/living-broschuere.html',
      image: 'https://solarlux.com/files/jpg3/solarlux-living-broschuere-de.jpg',
      description:
        'Möchten Sie sich noch ausführlicher über einzelne Systeme und technische Details unserer Produkte informieren? Dann bestellen Sie gerne unsere umfangreiche Produktbroschüre. Entweder digital per E-Mail oder in gedruckter Form als Printversion.',
    },
    en: {
      link: 'https://solarlux.com/en/inspiration/brochures/living/order.html',
      image: 'https://solarlux.com/files/jpg3/solarlux-living-broschuere-en.jpg',
      description:
        'Would you like even more in-depth information on our individual systems and the technical details of our products? Order our comprehensive digital product brochure today and get inspired.',
    },
  },
};

const brochureData = {
  15479: {
    color: '#A2AA68',
    image: 'https://solarlux.com/files/jpg3/solarlux-gfw-broschuere-de.jpg',
    translations: {
      de: {
        type: 'Glas-Faltwand',
        image: 'https://solarlux.com/files/jpg3/solarlux-gfw-broschuere-de.jpg',
        link: 'https://solarlux.com/de-de/inspirationen/broschueren/glas-faltwaende/glas-faltwand-broschuere.html',
        description:
          'Möchten Sie sich noch ausführlicher über einzelne Systeme und technische Details unserer Glas-Faltwand informieren? Dann bestellen Sie gerne unsere umfangreiche Produktbroschüre. Entweder digital per E-Mail oder in gedruckter Form als Printversion.',
      },
      en: {
        type: 'Bi-Folding Door',
        image: 'https://solarlux.com/files/jpg3/solarlux-gfw-2022-broschuere-en.jpg',
        link: 'https://solarlux.com/en/inspiration/brochures/bi-folding-doors/order.html',
        description:
          'Would you like even more in-depth information on our individual systems and the technical details of our bi-folding doors? Order our comprehensive digital product brochure today and get inspired.',
      },
    },
  },
  15485: {
    color: '#7c929e',
    image: 'https://solarlux.com/files/jpg3/solarlux-urban-2022-broschuere-de.jpg',
    translations: {
      de: {
        type: 'Urban',
        image: 'https://solarlux.com/files/jpg3/solarlux-urban-2022-broschuere-de.jpg',
        link: 'https://solarlux.com/de-de/inspirationen/broschueren/urban/urban-broschuere.html',
        description:
          'Möchten Sie sich noch ausführlicher über einzelne Systeme und technische Details unserer Balkonverglasungen informieren? Dann bestellen Sie gerne unsere umfangreiche Produktbroschüre. Entweder digital per E-Mail oder in gedruckter Form als Printversion.',
      },
      en: {
        type: 'Urban',
        image: 'https://solarlux.com/files/jpg3/broschuere_urban_210x297-titelbild-v11.jpg',
        link: 'https://solarlux.com/en/inspiration/brochures/urban/urban-brochure.html',
        description:
          'Would you like even more in-depth information on our individual systems and the technical details of our balcony glazing products? Order our comprehensive digital product brochure today and get inspired.',
      },
    },
  },
  22357: {
    color: '#97999B',
    image: 'https://solarlux.com/files/jpg3/schiebefenster-cero-broschuere-solarlux.jpg',
    translations: {
      de: {
        type: 'Cero',
        image: 'https://solarlux.com/files/jpg3/schiebefenster-cero-broschuere-solarlux.jpg',
        link: 'https://solarlux.com/de-de/inspirationen/broschueren/cero-schiebefenster/cero-schiebefenster-broschuere.html',
        description:
          'Möchten Sie sich noch ausführlicher über einzelne Systeme und technische Details unseres Schiebefensters cero informieren? Dann bestellen Sie gerne unsere umfangreiche Produktbroschüre. Entweder digital per E-Mail oder in gedruckter Form als Printversion.',
      },
      en: {
        type: 'Cero',
        image: 'https://solarlux.com/files/jpg3/sliding-system-cero-solarlux.jpg',
        link: 'https://solarlux.com/en/inspiration/brochures/cero-sliding-system/order.html',
        description:
          'Would you like even more in-depth information on our individual systems and the technical details of our cero sliding window? Order our comprehensive digital product brochure today and get inspired.',
      },
    },
  },
  15476: {
    color: '#AC9074',
    image: 'https://solarlux.com/files/jpg3/solarlux-wiga-broschuere-2021-de.jpg',
    translations: {
      de: {
        type: 'Wintergarten',
        image:
          'https://solarlux.com/files/jpg3/broschuere-wiga-2022-broschuerenbestellung-de-at-ch.jpg',
        link: 'https://solarlux.com/de-de/inspirationen/broschueren/wintergaerten/wintergarten-broschuere.html',
        description:
          'Möchten Sie sich noch ausführlicher über einzelne Systeme und technische Details unserer Wintergärten informieren? Dann bestellen Sie gerne unsere umfangreiche Produktbroschüre. Entweder digital per E-Mail oder in gedruckter Form als Printversion.',
      },
      en: {
        type: 'Wintergarden',
        image: 'https://solarlux.com/files/jpg3/solarlux-wiga-2022-broschuere-en.jpg',
        link: 'https://solarlux.com/en/inspiration/brochures/wintergarden/order.html',
        description:
          'Would you like even more in-depth information on our individual systems and the technical details of our wintergardens? Order our comprehensive digital product brochure today and get inspired.',
      },
    },
  },
  15489: {
    color: '#CEB976',
    image: 'https://solarlux.com/files/png2/formular-terrassendach-547x774-web.png',
    translations: {
      de: {
        type: 'Terrassenüberdachung / Glashaus',
        image: 'https://solarlux.com/files/png2/formular-terrassendach-547x774-web.png',
        link: 'https://solarlux.com/de-de/inspirationen/broschueren/terrassenueberdachungen/terrassendach-broschuere.html',
        description:
          'Möchten Sie sich noch ausführlicher über einzelne Systeme und technische Details unserer Wintergärten informieren? Dann bestellen Sie gerne unsere umfangreiche Produktbroschüre. Entweder digital per E-Mail oder in gedruckter Form als Printversion.',
      },
      en: {
        type: 'Glass Canopy',
        image: 'https://solarlux.com/files/jpg3/solarlux-gh-2022-broschuere-en.jpg',
        link: 'https://solarlux.com/en/inspiration/brochures/glass-canopies/order.html',
        description:
          'Would you like even more in-depth information on our individual systems and the technical details of our glass canopies and glass houses? Order our comprehensive digital product brochure today and get inspired.',
      },
    },
  },
  15497: spacesIdeaBook,
  15519: spacesIdeaBook,
  15494: spacesIdeaBook,
};

const getBrochureBackgroundColor = (tagId) => {
  return {
    backgroundColor: brochureData[tagId].color,
    width: '100%',
    height: '100%',
  };
};

const getBrochureImage = (tagId) => {
  const locale = localStorage.getItem('i18nextLng');
  return brochureData[tagId].translations[locale].image;
};

const getBrochureText = (tagId) => {
  const locale = localStorage.getItem('i18nextLng');
  return brochureData[tagId].translations[locale].description;
};

const getBrochureLink = (tagId, queryParams) => {
  const locale = localStorage.getItem('i18nextLng');
  let params = '';
  if (Object.keys(queryParams).length) {
    Object.keys(queryParams).forEach((param) => (params += param + '=' + queryParams[param]));
  }

  return brochureData[tagId].translations[locale].link + (params ? '?' + params : '');
};

const BrochureRequest = ({ tagId }) => {
  const [t] = useTranslation('translations');

  const [brochureAvailable, setBrochureAvailable] = useState(false);
  const queryParams = useSelector((state) => state.defaultdata.queryParams);

  useEffect(() => {
    setBrochureAvailable(undefined !== brochureData[tagId]);
  }, []);

  const clickBrochure = () => {
    const locale = localStorage.getItem('i18nextLng');
    sendDataLayerEvent('spaces_cta_click', '', {
      brochure_type: brochureData[tagId].translations[locale].type,
    });
  };

  return (
    <React.Fragment>
      {brochureAvailable && (
        <div className={styles.gridContainer}>
          <div className={styles.color} style={getBrochureBackgroundColor(tagId)} />
          <div className={styles.image}>
            <img
              src={getBrochureImage(tagId)}
              className={classNames({
                [styles.pullTop]:
                  getBrochureBackgroundColor(tagId).backgroundColor === '#4A4A49' ||
                  getBrochureBackgroundColor(tagId).backgroundColor === '#7c929e',
              })}
            />
          </div>
          <div className={styles.text}>
            <h2>{t('detail.brochure')}</h2>
            <p>{getBrochureText(tagId)}</p>
            <a
              target='_blank'
              className={styles.button}
              onClick={clickBrochure}
              href={getBrochureLink(tagId, queryParams)}
            >
              {t('detail.order')}
            </a>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation('translations')(BrochureRequest);
