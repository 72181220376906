import { SET_HISTORY_ITEMS } from '../actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY_ITEMS: {
      const history = action.payload.history;
      return [...history];
    }
    default:
      return state;
  }
};
