import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-animated-css';
import SVG from 'react-inlinesvg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import angleDownIcon from '../../assets/icons/angle-down.svg';
import angleLeftIcon from '../../assets/icons/angle-left.svg';
import angleUpIcon from '../../assets/icons/angle-up.svg';
import angleRightIcon from '../../assets/icons/gallery-angle-right.svg';
import Modal from '../modal/Modal';
import styles from './gallery.module.scss';

const Gallery = ({ reference, images }) => {
  const pages = images.length - 2; // the number of pages should be the number if images minus the two we already show
  const [currentImage, setCurrentImage] = useState(0);
  const [currentModalImage, setCurrentModalImage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isMobileViewport, setIsMobileViewport] = useState(false);
  const [loadedImages, setLoadedImages] = useState(0);
  const [imageLoaderFallbackTriggered, setImageLoaderFallbackTriggered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentImage(newIndex);
      setCurrentModalImage(newIndex);
    },
  };

  const getNavigationThumbStyles = () => {
    const axis = isMobileViewport ? 'X' : 'Y';

    return {
      transform: `translate${axis}(-${offset * 100}%)`,
    };
  };

  const checkMobileViewport = () => {
    setIsMobileViewport(window.innerWidth <= 768);
  };

  const showPreviousImage = () => {
    if (0 === offset) {
      return;
    }

    setOffset(offset - 1);
  };

  const showNextImages = () => {
    if (offset === pages) {
      return;
    }

    setOffset(offset + 1);
  };

  const showPreviousModalImage = () => {
    if (currentModalImage === 0) {
      setCurrentModalImage(images.length - 1);
      sliderRef.current.slickGoTo(images.length - 1);
    } else {
      setCurrentModalImage(currentModalImage - 1);
      sliderRef.current.slickGoTo(currentModalImage - 1);
    }
  };

  const showNextModalImage = () => {
    if (images.length - 1 === currentModalImage) {
      setCurrentModalImage(0);
      sliderRef.current.slickGoTo(0);
    } else {
      setCurrentModalImage(currentModalImage + 1);
      sliderRef.current.slickGoTo(currentModalImage + 1);
    }
  };

  useEffect(() => {
    checkMobileViewport();

    const imageLoaderFallback = setTimeout(() => {
      setImageLoaderFallbackTriggered(true);
    }, 1000);

    return () => {
      clearInterval(imageLoaderFallback);
    };
  }, []);

  window.addEventListener('resize', () => {
    checkMobileViewport();
  });

  return (
    <div
      className={classNames(styles.gallery, {
        [styles.fadeIn]: loadedImages === images.length || imageLoaderFallbackTriggered,
      })}
    >
      <div className={styles.currentImage}>
        <Animated
          key={images[currentImage]}
          animationIn='fadeIn'
          animationOut='fadeOut'
          isVisible={true}
          animationInDuration={1200}
        >
          <img onClick={() => setShowModal(true)} src={images[currentImage]} />
        </Animated>
      </div>
      <div className={styles.navigation}>
        {images.length > 2 && (
          <div
            className={classNames(styles.navigationIcon, styles.up, {
              [styles.navigationIconDisabled]: 0 === offset,
            })}
          >
            <SVG onClick={showPreviousImage} src={angleUpIcon} />
          </div>
        )}
        <div className={styles.navigationList}>
          {images.map((image, index) => {
            return (
              <div
                className={classNames(styles.navigationThumb, {
                  [styles.navigationThumbActive]: index === currentImage,
                })}
                style={getNavigationThumbStyles()}
                key={index}
              >
                <img
                  onLoad={() => setLoadedImages(loadedImages + 1)}
                  onClick={() => {
                    setCurrentImage(index);
                    setCurrentModalImage(index);
                  }}
                  src={image}
                  alt=''
                />
              </div>
            );
          })}
        </div>
        {images.length > 2 && (
          <div
            className={classNames(styles.navigationIcon, styles.down, {
              [styles.navigationIconDisabled]: pages === offset,
            })}
          >
            <SVG onClick={showNextImages} src={angleDownIcon} />
          </div>
        )}
      </div>

      <div className={styles.navigationMobile}>
        <div className={styles.navigationList}>
          <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => {
              return (
                <div className={classNames(styles.slickImage)} key={index}>
                  <img onClick={() => setShowModal(true)} src={image} alt='' />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <Modal
        onClose={() => setShowModal(false)}
        showModal={showModal}
        title={reference.title}
        subTitle={reference.id}
        style={{ width: '60%', maxHeight: '100%' }}
      >
        {images.length >= 2 && (
          <div className={classNames(styles.modalNavigationIcon, styles.modalNavigationPrev)}>
            <SVG onClick={showPreviousModalImage} src={angleLeftIcon} />
          </div>
        )}
        <div className={styles.modalCurrentImage}>
          <img src={images[currentModalImage]} />
        </div>
        {images.length >= 2 && (
          <div className={classNames(styles.modalNavigationIcon, styles.modalNavigationNext)}>
            <SVG onClick={showNextModalImage} src={angleRightIcon} />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Gallery;
