import React from 'react';

import classNames from 'classnames';

import styles from './checkbox.module.scss';

const Checkbox = ({ name, label, onClick, disabled, checked }) => {
  return (
    <label className={classNames(styles.checkboxLabel, styles.label)} htmlFor={`input-${name}`}>
      <span>{label}</span>
      <input
        id={`input-${name}`}
        name={name}
        className={classNames(styles.checkbox)}
        type='checkbox'
        onClick={onClick}
        disabled={disabled}
        defaultChecked={checked}
      />
      <span className={styles.checkMark}></span>
    </label>
  );
};

export default Checkbox;
