import React from 'react';

import styles from './metaInformation.module.scss';

const MetaInformation = ({ name, value, onClick }) => {
  const isArray = Array.isArray(value);
  let tags;
  if (isArray && value.length) {
    tags = value
      .map((tag, index) => {
        if (undefined !== onClick) {
          return (
            <span
              key={index}
              className={styles.metaInformationLinked}
              onClick={() => onClick(tag.tagId)}
            >
              {tag.tagValue}
            </span>
          );
        }

        return <span key={index}>{tag.tagValue}</span>;
      })
      .reduce((prev, curr) => [prev, ', ', curr]);
  } else {
    if (undefined !== onClick) {
      tags = (
        <span className={styles.metaInformationLinked} onClick={onClick}>
          {value}
        </span>
      );
    } else {
      tags = <span>{value}</span>;
    }
  }

  return (
    <div className={styles.metaInformation}>
      <div className={styles.metaInformationName}>{name}</div>
      <div className={styles.metaInformationValue}>{tags}</div>
    </div>
  );
};

export default MetaInformation;
