import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { MARKER_TYPES } from '../marker/Marker';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import gridView from '../../assets/grid-view.png';
import notificationsIcon from '../../assets/icons/bell-light.svg';
import typologyIcon from '../../assets/icons/buildingTypology.svg';
import favoriteIcon from '../../assets/icons/favorite.svg';
import historyIcon from '../../assets/icons/history.svg';
import productsIcon from '../../assets/icons/products.svg';
import searchIcon from '../../assets/icons/search.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import apartmentBuildingIcon from '../../assets/icons/typologies/apartmentBuilding.svg';
import commercialAndIndustrialIcon from '../../assets/icons/typologies/commercialAndIndustrie.svg';
import cultureAndSacredBuildingIcon from '../../assets/icons/typologies/cultureAndSacredBuldings.svg';
import educationAndScienceIcon from '../../assets/icons/typologies/educationAndScience.svg';
import facadeIcon from '../../assets/icons/typologies/facade.svg';
import healthAndCareIcon from '../../assets/icons/typologies/healthAndCare.svg';
import hotelsAndGastronomyIcon from '../../assets/icons/typologies/hotelsAndGastronomy.svg';
import livingAndGardenIcon from '../../assets/icons/typologies/livingAndGarden.svg';
import officeAndAdministrationIcon from '../../assets/icons/typologies/officeAndAdministration.svg';
import otherIcon from '../../assets/icons/typologies/other.svg';
import stadiumsAndArenasIcon from '../../assets/icons/typologies/stadiumsAndArenas.svg';
import wellnessAndFitnessIcon from '../../assets/icons/typologies/wellnessAndFitness.svg';
import balconyImage from '../../assets/img/balcony.svg';
import biFoldingImage from '../../assets/img/biFolding.svg';
import ceroImage from '../../assets/img/cero.svg';
import hsdImage from '../../assets/img/hsd.svg';
import roofImage from '../../assets/img/roof.svg';
import slidingImage from '../../assets/img/sliding.svg';
import wintergardenImage from '../../assets/img/wintergarden.svg';
import mapSwitch from '../../assets/map-switch.png';
import mapView from '../../assets/map-view.png';
import searchView from '../../assets/search-view.png';
import mapZoomIn from '../../assets/map-zoom-in.png';
import mapZoom from '../../assets/map-zoom.png';
import Headline from '../headline/Headline';
import Tooltip from '../tooltip/Tooltip';
import styles from './overlayHelpPage.module.scss';

const components = {
  apartmentBuildingIcon: apartmentBuildingIcon,
  commercialAndIndustrialIcon: commercialAndIndustrialIcon,
  cultureAndSacredBuildingIcon: cultureAndSacredBuildingIcon,
  educationAndScienceIcon: educationAndScienceIcon,
  facadeIcon: facadeIcon,
  healthAndCareIcon: healthAndCareIcon,
  hotelsAndGastronomyIcon: hotelsAndGastronomyIcon,
  livingAndGardenIcon: livingAndGardenIcon,
  officeAndAdministrationIcon: officeAndAdministrationIcon,
  otherIcon: otherIcon,
  stadiumsAndArenasIcon: stadiumsAndArenasIcon,
  wellnessAndFitnessIcon: wellnessAndFitnessIcon,
};

const navigationComponents = {
  typologies: typologyIcon,
  products: productsIcon,
  search: searchIcon,
  favorites: favoriteIcon,
  history: historyIcon,
  notifications: notificationsIcon,
  settings: settingsIcon,
};

const OverlayHelpPage = () => {
  const [t] = useTranslation('translations');
  const typologies = useSelector((state) => state.filter.typologies);
  const products = useSelector((state) => state.filter.products);

  const getProductImage = (tagId) => {
    switch (tagId) {
      case 15485:
        return balconyImage;
      case 22357:
        return ceroImage;
      case 15479:
        return biFoldingImage;
      case 15497:
        return hsdImage;
      case 15494:
        return slidingImage;
      case 15519:
        return slidingImage;
      case 15523:
        return roofImage;
      case 15489:
        return roofImage;
      case 15476:
        return wintergardenImage;
      default:
        return '';
    }
  };

  return (
    <div className={styles.help}>
      <div className={styles.heading}>
        <Headline size='h1'>{t('help.headline')}</Headline>
      </div>
      <div className={styles.description}>
        <Headline size='h2'>{t('help.changeView')}</Headline>
        <p>{t('help.changeViewDescription')}</p>
        <div className={styles.mapSwitch}>
          <img alt={t('help.mapSwitch')} src={mapSwitch} />
        </div>

        <div className={styles.mapDetails}>
          <div className={styles.mapImage}>
            <img alt={t('help.mapView')} src={mapView} />
            <span>{t('help.mapViewDetails')}</span>
          </div>
          <div className={styles.mapImage}>
            <img alt={t('help.gridView')} src={gridView} />
            <span>{t('help.gridViewDetails')}</span>
          </div>
        </div>

        <Headline size='h2'>{t('help.mapZoom')}</Headline>
        <p>{t('help.mapZoomDescription')}</p>
        <div className={styles.mapDetails}>
          <div className={styles.mapImage}>
            <img alt={t('help.mapZoomAlt')} src={mapZoom} />
          </div>
          <div className={styles.mapImage}>
            <img alt={t('help.mapZoomInAlt')} src={mapZoomIn} />
          </div>
        </div>

        <Headline size='h2'>{t('help.navigation')}</Headline>
        <p>{t('help.navigationDescription')}</p>

        <div className={styles.navigationIcons}>
          {Object.keys(navigationComponents).map((navigation, index) => {
            return (
              <Tooltip key={index} text={t(`help.${navigation}`)}>
                <div className={styles.navigationIcon}>
                  <SVG src={navigationComponents[navigation]} />
                </div>
              </Tooltip>
            );
          })}
        </div>

        <Headline size='h2'>{t('help.typologies')}</Headline>
        <p>{t('help.description')}</p>
        <div className={styles.icons}>
          {Object.values(MARKER_TYPES).map((type, key) => {
            const slug = type
              .split('_')
              .map((element, index) =>
                index === 0
                  ? element
                  : element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
              )
              .join('');
            const componentName = slug + 'Icon';
            const Component = components[componentName];
            const text = typologies.find((typology) => typology.type === type)?.text;
            return (
              <Tooltip key={key} text={text ?? ''}>
                <div className={classNames(styles.marker, styles[slug])}>
                  <div className={styles.icon}>
                    <SVG src={Component} className={styles.svg} />
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </div>
        <p>
          {t('help.description_sub')} <SVG src={apartmentBuildingIcon} className={styles.svg} />{' '}
          {t('help.description_sub2')}
        </p>

        <Headline size='h2'>{t('help.products')}</Headline>
        <p>{t('help.productsDescription')}</p>

        <div className={styles.productIcons}>
          {products.map((product, index) => {
            return (
              <Tooltip key={index} text={product.name}>
                <div className={styles.productIcon}>
                  <SVG src={getProductImage(product.tagId)} />
                </div>
              </Tooltip>
            );
          })}
        </div>

        <Headline size='h2'>{t('help.search')}</Headline>
        <p>{t('help.searchDescription')}</p>
        <ul>
          <li>{t('help.searchDescriptionList.item1')}</li>
          <li>{t('help.searchDescriptionList.item2')}</li>
          <li>{t('help.searchDescriptionList.item3')}</li>
          <li>{t('help.searchDescriptionList.item4')}</li>
          <li>{t('help.searchDescriptionList.item5')}</li>
          <li>{t('help.searchDescriptionList.item6')}</li>
        </ul>
        <p>{t('help.searchDescriptionSub')}</p>
        <img alt={t('help.searchView')} src={searchView} />
        <p>{t('help.searchDescriptionSub2')}</p>
        <Headline size='h2'>{t('help.favorites')}</Headline>
        <Headline size='h2'>{t('help.history')}</Headline>
        <Headline size='h2'>{t('help.notifications')}</Headline>
        <Headline size='h2'>{t('help.settings')}</Headline>
        <Headline size='h2'>{t('help.profile')}</Headline>
      </div>
    </div>
  );
};

export default withTranslation('translations')(OverlayHelpPage);
