import { LOAD_SEARCHLOG, SET_SEARCHLOG_ITEM } from '../redux/actionTypes';

import * as Cookies from 'js-cookie';
import { spacesApi } from '../api';
import actions from '../redux/actions';

export const fetchSearchlog = (storeAPI) => (next) => (action) => {
  if (action.type !== LOAD_SEARCHLOG) return next(action);

  const headers = {
    Authorization: `Bearer ${Cookies.get('token')}`,
  };

  spacesApi
    .get('/spaces/searchlog', { headers })
    .then((res) => {
      storeAPI.dispatch(actions.searchLogActions.setSearchlog(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setSearchlog = (storeAPI) => (next) => (action) => {
  if (action.type !== SET_SEARCHLOG_ITEM) return next(action);

  const history = action.payload.history;

  storeAPI.dispatch(actions.historyActions.setHistory(history));
};
