import { LOAD_HISTORY, SET_HISTORY_ITEMS } from '../actionTypes';

const loadHistory = () => ({
  type: LOAD_HISTORY,
});

const setHistory = (history) => ({
  type: SET_HISTORY_ITEMS,
  payload: { history },
});

export default {
  loadHistory,
  setHistory,
};
