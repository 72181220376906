import React from 'react';
import { withTranslation } from 'react-i18next';

import classNames from 'classnames';

import SVG from 'react-inlinesvg';

import styles from './drawerLink.module.scss';

const DrawerLink = (props) => {
  return (
    <div
      className={classNames(
        styles.item,
        {
          [styles.active]: props.active,
          [styles.desktopOnly]: props.desktopOnly,
          [styles.mobileOnly]: props.mobileOnly,
        },
        `item-icon-${props.name}`
      )}
      onClick={props.onMenuClick}
      title={props.iconAlt}
    >
      {props.imageSrc && <img src={props.imageSrc} alt={props.iconAlt} />}
      {!props.imageSrc && <SVG className={styles.icon} src={props.icon} alt={props.iconAlt} />}
      {undefined !== props.badge && 0 !== props.badge && (
        <span className={styles.badge}>{props.badge}</span>
      )}
    </div>
  );
};

export default withTranslation('translations')(DrawerLink);
