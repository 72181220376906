import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import styles from './overlaySearchLog.module.scss';

import { spacesApi } from '../../api';

import classNames from 'classnames';
import Headline from '../headline/Headline';
import LoginForm from '../login-form/LoginForm';
import Spinner from '../spinner/Spinner';
import angleDownIcon from '../../assets/icons/angle-down.svg';
import angleUpIcon from '../../assets/icons/angle-up.svg';
import SVG from "react-inlinesvg";

const OverlaySearchLogPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const locale = localStorage.getItem('i18nextLng');
  const currentResults = useSelector((state) => state.searchlog);
  const currentUserToken = useSelector((state) => state.user.token);
  const [results, setResults] = useState(currentResults);
  const [decodedToken, setDecodedToken] = useState(null);
  const [sortElement, setSortElement] = useState('term');
  const [sortDirection, setSortDirection] = useState('ASC');
  const visitDate = new Date();
  const month = visitDate.toLocaleString(
    `${locale}-${locale === 'de' ? locale.toUpperCase() : 'US'}`,
    { month: 'long' }
  );

  const loadSearchlog = async () => {
    setLoading(true);

    const headers = {
      Authorization: 'Bearer ' + currentUserToken,
    };

    spacesApi
      .get('/spaces/searchlog/', { headers })
      .then((res) => {
        dispatch(actions.searchLogActions.setSearchlog(res.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    loadSearchlog();

    setDecodedToken(jwtDecode(currentUserToken));
  }, [currentUserToken]);

  useEffect(() => {
    setResults(currentResults)
  }, [currentResults]);

  const sortTableBy = (column) => {
    const tempResults = JSON.parse(JSON.stringify(currentResults));
    let direction;
    if(sortElement === column) {
      direction = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      direction = 'ASC';
    }


    tempResults.sort((a, b) => {

      let titleA = a[column];
      let titleB = b[column];

      if(column === 'term') {
        titleA = a[column].toUpperCase();
        titleB = b[column].toUpperCase();
      }

      if (titleA < titleB) {
        return direction === 'ASC' ? -1 : 1;
      }
      if (titleA > titleB) {
        return direction === 'ASC' ? 1 : -1;
      }

      // names must be equal
      return 0;
    })

    setSortDirection(direction);
    setSortElement(column)
    setResults(tempResults);
  }


  return (
    <div
      className={classNames(styles.searchlog, {
        [styles.unauthenticated]: null === currentUserToken,
      })}
    >
      {null === currentUserToken && <LoginForm />}
      {currentUserToken && (
        <>
          <Headline size='h1'>
            {t('searchlog.headline')} - {month} {visitDate.getFullYear()}
          </Headline>
          {null !== currentResults && (
            <div className={styles.results}>
              <ul>
                <li className={styles.header}>{t('searchlog.nr')}</li>
                <li className={styles.header} onClick={() => sortTableBy('term')}>{t('searchlog.term')}
                  <span className={styles.sort}>
                    <SVG className={classNames( {
                      [styles.expanded]: sortElement === 'term' && sortDirection === 'DESC',
                    })} src={angleUpIcon} />
                    <SVG className={classNames( {
                      [styles.expanded]: sortElement === 'term' && sortDirection === 'ASC',
                    })} src={angleDownIcon} />
                  </span>
                </li>
                <li className={styles.header} onClick={() => sortTableBy('count')}>{t('searchlog.count')}
                  <span className={styles.sort}>
                    <SVG className={classNames( {
                      [styles.expanded]: sortElement === 'count' && sortDirection === 'DESC',
                    })} src={angleUpIcon} />
                    <SVG className={classNames( {
                      [styles.expanded]: sortElement === 'count' && sortDirection === 'ASC',
                    })} src={angleDownIcon} />
                  </span>
                </li>
                <li className={styles.header} onClick={() => sortTableBy('results')}>{t('searchlog.results')}
                  <span className={styles.sort} >
                    <SVG className={classNames( {
                      [styles.expanded]: sortElement === 'results' && sortDirection === 'DESC',
                    })} src={angleUpIcon} />
                    <SVG className={classNames( {
                      [styles.expanded]: sortElement === 'results' && sortDirection === 'ASC',
                    })} src={angleDownIcon} />
                  </span>
                </li>
                {results.map((result, index) => (
                  <React.Fragment key={index}>
                    <li className={classNames(styles.item, styles.left)}>{index + 1}.</li>
                    <li className={styles.item}>{result.term}</li>
                    <li className={styles.item}>{result.count}</li>
                    <li className={styles.item}>{result.results}</li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default withTranslation('translations')(OverlaySearchLogPage);
