import { SET_FILTER, SET_PRODUCTS, SET_TYPOLOGIES } from '../actionTypes';

const initialState = {
  typologies: [],
  products: [],
  options: {
    typologies: [],
    products: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TYPOLOGIES: {
      const { typologies } = action.payload;
      return {
        ...state,
        typologies,
      };
    }
    case SET_PRODUCTS: {
      const { products } = action.payload;
      return {
        ...state,
        products,
      };
    }
    case SET_FILTER: {
      const { options } = action.payload;
      return {
        ...state,
        options,
      };
    }
    default:
      return state;
  }
};
