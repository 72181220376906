import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import styles from './overlaySearchPage.module.scss';

import { spacesApi } from '../../api';
import { router } from '../../router';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import copyIcon from '../../assets/icons/copy-light.svg';
import shareIcon from '../../assets/icons/share.svg';
import Input from '../form/input/Input';
import Modal from '../modal/Modal';
import ProjectTeaser from '../project-teaser/ProjectTeaser';
import Spinner from '../spinner/Spinner';

const OverlaySearchPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const currentTerm = useSelector((state) => state.search.term);
  const originalResults = useSelector((state) => state.search.original_results);
  const currentResults = useSelector((state) => state.search.results);
  const currentFilter = useSelector((state) => state.filter.options);
  const currentUserToken = useSelector((state) => state.user.token);
  const [decodedToken, setDecodedToken] = useState(null);
  const [shareOpened, setShareOpened] = useState(false);
  const [searchQ, setSearchQ] = useState('');
  const [copied, setCopied] = useState(false);

  const filterResults = (results) => {
    return results.filter((result) => {
      let isInTypology = true;
      let isInProduct = true;
      if (currentFilter.typologies.length) {
        isInTypology = currentFilter.typologies.includes(result.typology);
      }

      if (currentFilter.products.length) {
        isInProduct =
          result.tags.filter((tag) => {
            return currentFilter.products.includes(tag);
          }).length > 0;
      }

      return isInTypology && isInProduct;
    });
  };

  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    setDecodedToken(jwtDecode(currentUserToken));
  }, [currentUserToken]);

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.hash.split('?')[1]).get('s');

    if (searchParam) {
      const searchParams = new URLSearchParams(window.location.hash.split('?')[1]);
      setSearchQ(searchParams.toString());

      dispatch(actions.searchActions.setSearchTerm(searchParam));

      dispatch(actions.searchActions.setSearchResults([]));
      setLoading(true);
      spacesApi
        .get('/spaces/search/', {
          params: {
            term: searchParam.replace(/^0+/, ''),
            user: decodedToken ? decodedToken.id : null,
          },
        })
        .then((response) => {
          const results = filterResults(response.data);

          dispatch(actions.searchActions.setOriginalResults(response.data));
          dispatch(actions.searchActions.setSearchResults(results));
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (originalResults) {
      const results = filterResults(originalResults);
      dispatch(actions.searchActions.setSearchResults(results));
    }
  }, [currentFilter]);

  const search = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(window.location.hash.split('?')[1]);
    searchParams.set('s', currentTerm.replace(/^0+/, ''));
    setSearchQ(searchParams.toString());

    router.navigate(`/${t('navigation.search')}.html?` + searchParams.toString());

    dispatch(actions.searchActions.setSearchResults([]));
    setLoading(true);
    spacesApi
      .get('/spaces/search/', {
        params: {
          term: currentTerm.replace(/^0+/, ''),
          user: decodedToken ? decodedToken.id : null,
        },
      })
      .then((response) => {
        const results = filterResults(response.data);

        dispatch(actions.searchActions.setOriginalResults(response.data));
        dispatch(actions.searchActions.setSearchResults(results));
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  });

  const navigateTo = (referenceId, slug) => {
    router.navigate(`/${t('navigation.reference')}/${referenceId}-${slug}.html?search=1`);
    window.scrollTo(0, 0);
  };

  const copyURL = (url) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <div className={styles.search}>
      <form onSubmit={search}>
        <input
          className={styles.box}
          placeholder={t('search.placeholder')}
          value={currentTerm}
          onChange={(e) => dispatch(actions.searchActions.setSearchTerm(e.target.value))}
        />
        <div className={styles.helpTextWrapper}>
          <span className={styles.helpText}>{t('search.helpText')}</span>
          {!loading && null !== currentResults && (
            <span className={styles.resultsCount}>
              {t('search.resultCount').replace('%s', currentResults.length)}
            </span>
          )}
        </div>
      </form>
      {loading && <Spinner />}
      {null !== currentResults && (
        <>
          <div className={styles.share}>
            <SVG
              onClick={() => setShareOpened(!shareOpened)}
              className={styles.icon}
              src={shareIcon}
            />
            {shareOpened && (
              <Modal
                onClose={() => setShareOpened(false)}
                showModal={shareOpened}
                style={{ width: '35%' }}
              >
                <div className={styles.copyUrl}>
                  <Input
                    type={'text'}
                    label={''}
                    name={'url'}
                    value={
                      router.root.replace('/#!', '') +
                      `/#!/${t('navigation.search')}.html?` +
                      searchQ
                    }
                    disabled={true}
                  />
                  <SVG
                    onClick={() =>
                      copyURL(
                        router.root.replace('/#!', '') +
                          `/#!/${t('navigation.search')}.html?` +
                          searchQ
                      )
                    }
                    className={classNames(styles.icon, [copied ? styles.copied : ''])}
                    src={copyIcon}
                  />
                </div>
              </Modal>
            )}
          </div>
          <div className={styles.results}>
            {currentResults.map((item) => {
              return (
                <ProjectTeaser
                  key={item.id}
                  referenceId={item.id}
                  image={item.filename}
                  title={item.title}
                  date={item.date}
                  //isTopReference={item.isTopReference}
                  onClick={() => navigateTo(item.id, item.slug)}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation('translations')(OverlaySearchPage);
