import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import styles from './marker.module.scss';

import apartmentBuildingIcon from '../../assets/icons/typologies/apartmentBuilding.svg';
import commercialAndIndustrialIcon from '../../assets/icons/typologies/commercialAndIndustrie.svg';
import cultureAndSacredBuildingsIcon from '../../assets/icons/typologies/cultureAndSacredBuldings.svg';
import educationAndScienceIcon from '../../assets/icons/typologies/educationAndScience.svg';
import facadeIcon from '../../assets/icons/typologies/facade.svg';
import healthAndCareIcon from '../../assets/icons/typologies/healthAndCare.svg';
import hotelsAndGastronomyIcon from '../../assets/icons/typologies/hotelsAndGastronomy.svg';
import livingAndGardenIcon from '../../assets/icons/typologies/livingAndGarden.svg';
import officeAndAdministrationIcon from '../../assets/icons/typologies/officeAndAdministration.svg';
import otherIcon from '../../assets/icons/typologies/other.svg';
import stadiumsAndArenasIcon from '../../assets/icons/typologies/stadiumsAndArenas.svg';
import wellnessAndFitnessIcon from '../../assets/icons/typologies/wellnessAndFitness.svg';

import { useTranslation } from 'react-i18next';
import { sendDataLayerEvent } from '../../gtm/datalayerSendEvent';
import MarkerContent from '../marker-content/MarkerContent';

export const MARKER_TYPES = {
  APARTMENT_BUILDING: 'apartment_building',
  COMMERCIAL_AND_INDUSTRIAL: 'commercial_and_industrial',
  CULTURE_AND_SACRED_BUILDINGS: 'culture_and_sacred_building',
  EDUCATION_AND_SCIENCE: 'education_and_science',
  FACADE: 'facade',
  HEALTH_AND_CARE: 'health_and_care',
  HOTELS_AND_GASTRONOMY: 'hotels_and_gastronomy',
  LIVING_AND_GARDEN: 'living_and_garden',
  OFFICE_AND_ADMINISTRATION: 'office_and_administration',
  OTHER: 'other',
  STADIUMS_AND_ARENAS: 'stadiums_and_arenas',
  WELLNESS_AND_FITNESS: 'wellness_and_fitness',
};

const Marker = (props) => {
  const dispatch = useDispatch();
  const [t] = useTranslation('translations');
  const [open, setOpen] = useState(false);
  const currentMarker = useSelector((state) => state.map.currentMarker);
  const today = new Date();
  const [limit] = useState(new Date(today.setDate(today.getDate() - 5)));
  const [markerDate] = useState(new Date(props.date));

  useEffect(() => {
    setOpen(currentMarker === props.id);
  }, [currentMarker, props.id]);

  const isOfTypology = (typologyName) => {
    return props.typology === typologyName;
  };

  const onMarkerClick = () => {
    if (!open) {
      window.pageData.reference_name = props.title;
      sendDataLayerEvent('spaces_clicks');
    }
    setOpen(!open);
    dispatch(actions.mapActions.setOpenMarker(props.id));
  };

  return (
    <div>
      <div
        className={classNames(styles.marker, {
          [styles.apartmentBuilding]: isOfTypology(MARKER_TYPES.APARTMENT_BUILDING),
          [styles.commercialAndIndustrial]: isOfTypology(MARKER_TYPES.COMMERCIAL_AND_INDUSTRIAL),
          [styles.cultureAndSacredBuildings]: isOfTypology(
            MARKER_TYPES.CULTURE_AND_SACRED_BUILDINGS
          ),
          [styles.educationAndScience]: isOfTypology(MARKER_TYPES.EDUCATION_AND_SCIENCE),
          [styles.facade]: isOfTypology(MARKER_TYPES.FACADE),
          [styles.healthAndCare]: isOfTypology(MARKER_TYPES.HEALTH_AND_CARE),
          [styles.hotelsAndGastronomy]: isOfTypology(MARKER_TYPES.HOTELS_AND_GASTRONOMY),
          [styles.livingAndGarden]: isOfTypology(MARKER_TYPES.LIVING_AND_GARDEN),
          [styles.officeAndAdministration]: isOfTypology(MARKER_TYPES.OFFICE_AND_ADMINISTRATION),
          [styles.other]: isOfTypology(MARKER_TYPES.OTHER),
          [styles.stadiumsAndArenas]: isOfTypology(MARKER_TYPES.STADIUMS_AND_ARENAS),
          [styles.wellnessAndFitness]: isOfTypology(MARKER_TYPES.WELLNESS_AND_FITNESS),
          [styles.open]: open,
        })}
        onClick={onMarkerClick}
      >
        {markerDate.getTime() > limit.getTime() && !open && (
          <span className={styles.new}>{t('marker.new')}</span>
        )}
        <div className={styles.icon}>
          {isOfTypology(MARKER_TYPES.APARTMENT_BUILDING) && (
            <SVG src={apartmentBuildingIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.COMMERCIAL_AND_INDUSTRIAL) && (
            <SVG src={commercialAndIndustrialIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.CULTURE_AND_SACRED_BUILDINGS) && (
            <SVG src={cultureAndSacredBuildingsIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.EDUCATION_AND_SCIENCE) && (
            <SVG src={educationAndScienceIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.FACADE) && <SVG src={facadeIcon} className={styles.svg} />}
          {isOfTypology(MARKER_TYPES.HEALTH_AND_CARE) && (
            <SVG src={healthAndCareIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.HOTELS_AND_GASTRONOMY) && (
            <SVG src={hotelsAndGastronomyIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.LIVING_AND_GARDEN) && (
            <SVG src={livingAndGardenIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.OFFICE_AND_ADMINISTRATION) && (
            <SVG src={officeAndAdministrationIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.OTHER) && <SVG src={otherIcon} className={styles.svg} />}
          {isOfTypology(MARKER_TYPES.STADIUMS_AND_ARENAS) && (
            <SVG src={stadiumsAndArenasIcon} className={styles.svg} />
          )}
          {isOfTypology(MARKER_TYPES.WELLNESS_AND_FITNESS) && (
            <SVG src={wellnessAndFitnessIcon} className={styles.svg} />
          )}
        </div>
      </div>
      {
        // <Animated
        //     key={props.id}
        //     animationIn='zoomIn'
        //     animationOut='zoomOut'
        //     isVisible={open}
        //     animationInDuration={500}
        // >
        <MarkerContent
          open={open}
          markerId={props.id}
          onClose={onMarkerClick}
          content={{
            file: props.file,
            product: props.product,
            system: props.system,
            slug: props.slug,
            city: props.city,
            date: props.date,
          }}
        />
        // </Animated>
      }
    </div>
  );
};

export default Marker;
