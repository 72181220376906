import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import SVG from 'react-inlinesvg';
import {useDispatch, useSelector} from 'react-redux';
import arIcon from '../../assets/icons/cube-thin.svg';
import linkIcon from '../../assets/icons/external-link.svg';
import facebookIcon from '../../assets/icons/facebook.svg';
import favoriteIcon from '../../assets/icons/favorite.svg';
import linkedinIcon from '../../assets/icons/linkedin.svg';
import mailIcon from '../../assets/icons/mail.svg';
import mapIcon from '../../assets/icons/map-location-dot-light.svg';
import pinterestIcon from '../../assets/icons/pinterest.svg';
import shareIcon from '../../assets/icons/share.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import {sendDataLayerEvent} from '../../gtm/datalayerSendEvent';
import actions from '../../redux/actions';
import {router} from '../../router';
import BrochureRequest from '../brochure-request/BrochureRequest';
import Button from '../button/Button';
import Gallery from '../gallery/Gallery';
import Headline from '../headline/Headline';
import IconLink from '../icon-link/IconLink';
import MetaInformation from '../meta-information/MetaInformation';
import OverlayDetailPageProjectSlider from '../overlay-detail-page-project-slider/OverlayDetailPageProjectSlider';
import OverlayDetailPageSimiliarProjects
    from '../overlay-detail-page-similiar-projects/OverlayDetailPageSimiliarProjects';
import ProductAndSystemLinks from '../product-and-system-links/ProductAndSystemLinks';
import Spinner from '../spinner/Spinner';
import Techdocs from '../techdocs/Techdocs';
import Text from '../text/Text';
import styles from './overlayDetailPage.module.scss';

const OverlayDetailPage = () => {
    const [t] = useTranslation('translations');
    const dispatch = useDispatch();

    const currentFilterResults = useSelector((state) => state.map.markerList);
    const currentUserToken = useSelector((state) => state.user.token);
    const currentFavorites = useSelector((state) => state.user.favorites);
    const addingToFavorites = useSelector((state) => state.user.addingToFavorites);
    const currentViewport = useSelector((state) => state.map.viewport);
    const markerList = useSelector((state) => state.map.markerList);
    const history = useSelector((state) => state.history);
    const [showShare, setShowShare] = useState(false);
    const linkRef = useRef(null);

    const [loadingBasicInformation, setLoadingBasicInformation] = useState(true);
    const searchParam = window.location.hash
        .split('?')[1]
        ?.split('=')
        .find((el) => el === 'search');

    const navigateTo = (referenceId, title) => {
        router.navigate(`/${t('navigation.reference')}/${referenceId}-${title}.html?search=1`);
        window.scrollTo(0, 0);
    };

    const navigateToItem = (reference) => {
        router.navigate(`/`);
        const marker = markerList.find((markerItem) => markerItem.properties.id === reference.id);

        if (marker) {
            const [longitude, latitude] = marker.geometry.coordinates;


            dispatch(actions.userActions.setTileViewEnabled(false));
            setTimeout(() => {
                dispatch(
                    actions.mapActions.flyTo({
                        latitude,
                        longitude,
                    })
                );
                dispatch(actions.mapActions.setOpenMarker(marker.properties.id));
            }, 500)
        }
    };

    const currentReference = useSelector((state) => state.overlay.currentReference);
    useEffect(() => {
        if (currentReference) {
            const today = new Date().toLocaleDateString('de-DE');
            const index = history.findIndex((item) => item.visitDate === today);
            let item;

            if (index < 0) {
                item = {visitDate: today, visited: [currentReference.id]};
                history.push(item);
                dispatch(actions.userActions.setHistoryItem(history));
            } else {
                item = {...history[index]};
                if (!item.visited.find((visited) => visited === currentReference.id)) {
                    history[index].visited = [...item.visited, currentReference.id];
                    dispatch(actions.userActions.setHistoryItem(history));
                }
            }
        }

        setLoadingBasicInformation(null === currentReference);
    }, [currentReference]);

    const closeShare = (e) => {
        if (linkRef.current && !linkRef.current.contains(e.target)) {
            setShowShare(false);
        }
    };

    const moreInfoClick = () => {
        sendDataLayerEvent('cta_click');
        window.open(currentReference.report, '_blank');
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeShare);

        return () => {
            document.removeEventListener('mousedown', closeShare);
        };
    }, [linkRef, showShare]);

    const isInFavorites = (referenceId) => {
        if (null === currentFavorites) {
            return false;
        }

        return (
            currentFavorites.filter((favorite) => {
                return favorite.id === referenceId;
            }).length !== 0
        );
    };

    const addToFavorites = (id) => {
        dispatch(actions.userActions.addFavorite(id));
    };

    const removeFromFavorites = (id) => {
        const newFavorites = currentFavorites.filter((item) => item.id !== id);
        dispatch(actions.userActions.removeFavorite(id));
        dispatch(actions.userActions.setFavorites(newFavorites));
    };

    const setTypologyFilter = (type) => {
        setFilter([type], []);
    };

    const setProductFilter = (tagId) => {
        setFilter([], [tagId]);
    };

    const setFilter = (typologies, products) => {
        const options = {typologies, products};
        dispatch(actions.filterActions.setFilter(options));
        dispatch(actions.mapActions.filterMap(options));
        dispatch(actions.mapActions.setOpenMarker(null));

        router.navigate('/');
        window.scrollTo(0, 0);
    };

    const getCurrentIndexInFilterResults = () => {
        return currentFilterResults.findIndex(
            (reference) => reference.properties.id === currentReference.id
        );
    };

    const openReference = (index) => {
        const reference = currentFilterResults[index];
        const {id, slug} = reference.properties;

        dispatch(actions.mapActions.setOpenMarker(id));
        navigateTo(id, slug);
    };

    return (
        <div className={styles.detailPage}>
            {loadingBasicInformation && <Spinner/>}
            {!loadingBasicInformation && null !== currentReference && (
                <div>
                    <Headline size='h1'>{currentReference.title}</Headline>
                    <Text>
                        {t('detail.refNumber')} {currentReference.id}
                    </Text>

                    <div className={styles.addToFavorites}>
                        {(addingToFavorites || null === currentFavorites) && null !== currentUserToken && (
                            <Button size='small' look='grey-10' onClick={() => {
                            }} disabled={true}>
                                <FontAwesomeIcon icon={faSpinnerThird} spin/>
                            </Button>
                        )}
                        {!isInFavorites(currentReference.id) &&
                            null !== currentFavorites &&
                            !addingToFavorites && (
                                <a className={styles.linkTop} onClick={() => addToFavorites(currentReference.id)}>
                                    <SVG className={styles.icon} src={favoriteIcon}/> {t('detail.addToFavorites')}
                                </a>
                            )}
                        {isInFavorites(currentReference.id) &&
                            null !== currentFavorites &&
                            !addingToFavorites && (
                                <a
                                    className={styles.linkTop}
                                    onClick={() => removeFromFavorites(currentReference.id)}
                                >
                                    <SVG className={styles.iconFav} src={favoriteIcon}/>{' '}
                                    {t('detail.removeFromFavorites')}
                                </a>
                            )}
                        <div ref={linkRef} className={styles.shareIcons}>
                            <a className={styles.linkTop} onClick={() => setShowShare(!showShare)}>
                                <SVG className={styles.icon} src={shareIcon}/> {t('detail.share')}
                            </a>
                            {showShare && (
                                <div className={styles.shareWrapper}>
                                    <ul>
                                        <li>
                                            <a
                                                href={`mailto:?body=${window.location.href}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <SVG className={styles.icon} src={mailIcon}/> {t('detail.email')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`//www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                    window.location.href
                                                )}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <SVG className={styles.icon} src={facebookIcon}/> {t('detail.facebook')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`//pinterest.com/pin/create/button/?url=${encodeURIComponent(
                                                    window.location.href
                                                )}&image_base64=${encodeURIComponent(window.location.href)}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <SVG className={styles.icon}
                                                     src={pinterestIcon}/> {t('detail.pinterest')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`//www.linkedin.com/cws/share?url=${encodeURIComponent(
                                                    window.location.href
                                                )}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <SVG className={styles.icon} src={linkedinIcon}/> {t('detail.linkedin')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`//twitter.com/intent/tweet?text=A+New+Page&url=${encodeURIComponent(
                                                    window.location.href
                                                )}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <SVG className={styles.icon} src={twitterIcon}/> {t('detail.twitter')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        {searchParam && (
                            <a className={styles.linkTop} onClick={() => navigateToItem(currentReference)}>
                                <SVG className={styles.icon} src={mapIcon}/> {t('detail.viewOnMap')}
                            </a>
                        )}
                        {currentReference.arLink && (
                            <a className={styles.linkTop} target={`_blank`} href={currentReference.arLink}>
                                <SVG className={styles.icon} src={arIcon}/> {t('detail.arLink')}
                            </a>
                        )}
                    </div>

                    <div className={styles.gallery}>
                        <Gallery reference={currentReference} images={currentReference.filenames}/>
                    </div>

                    <div className={styles.addToFavoritesMobile}>
                        {(addingToFavorites || null === currentFavorites) && null !== currentUserToken && (
                            <a onClick={() => {
                            }}>
                                <FontAwesomeIcon icon={faSpinnerThird} spin/>
                            </a>
                        )}
                        {!isInFavorites(currentReference.id) &&
                            null !== currentFavorites &&
                            !addingToFavorites && (
                                <a
                                    size='thin'
                                    className={styles.favorite}
                                    onClick={() => addToFavorites(currentReference.id)}
                                >
                                    <SVG className={styles.icon} src={favoriteIcon}/>
                                </a>
                            )}
                        {searchParam && (
                            <a onClick={() => navigateToItem(currentReference)}>
                                <SVG className={styles.icon} src={mapIcon}/>
                            </a>
                        )}
                        {currentReference.arLink && (
                            <a className={styles.linkTop} target={`_blank`} href={currentReference.arLink}>
                                <SVG className={styles.icon} src={arIcon}/>
                            </a>
                        )}
                    </div>

                    <div className={styles.metaInformation}>
                        <MetaInformation
                            name={t('detail.product')}
                            value={currentReference.product.tagValue}
                            onClick={() => setProductFilter(currentReference.product.tagId)}
                        />
                        <MetaInformation
                            name={t('detail.system')}
                            value={currentReference.system}
                            onClick={setProductFilter}
                        />
                        {currentReference.typology && (
                            <MetaInformation
                                name={t('detail.typology')}
                                value={currentReference.typology.tagValue}
                                onClick={() => setTypologyFilter(currentReference.typology.tagId)}
                            />
                        )}
                    </div>

                    <Headline size='h3'>{currentReference.headline}</Headline>
                    <div className={styles.description}>
                        <Text>{currentReference.description}</Text>
                    </div>

                    <div className={styles.documents}>
                        <Headline size='h3'>{t('detail.techdocsNew')}</Headline>
                        <br/>
                        <Text>{t('detail.techdocsDetail')}</Text>
                        <Techdocs/>
                    </div>

                    <div className={styles.moreInformation}>
                        <Headline size='h3'>{t('detail.moreInformation')}</Headline>
                        <div className={styles.moreInformationLinks}>
                            {currentReference.report && (
                                <IconLink
                                    icon={linkIcon}
                                    label={t('detail.objectReport')}
                                    onClick={moreInfoClick}
                                />
                            )}

                            <ProductAndSystemLinks
                                productTag={currentReference.product}
                                systemTags={currentReference.system}
                            />
                        </div>
                    </div>

                    <OverlayDetailPageProjectSlider
                        loadNextProject={() => {
                            const currentIndex = getCurrentIndexInFilterResults();
                            openReference(
                                currentIndex === currentFilterResults.length - 1 ? 0 : currentIndex + 1
                            );
                        }}
                        loadPreviousProject={() => {
                            const currentIndex = getCurrentIndexInFilterResults();
                            openReference(
                                currentIndex === 0 ? currentFilterResults.length - 1 : currentIndex - 1
                            );
                        }}
                    />

                    <div className={styles.similarProjects}>
                        <Headline size='h3'>{t('detail.similarProjects')}</Headline>
                        <div className={styles.similarProjectsList}>
                            <OverlayDetailPageSimiliarProjects
                                navigateTo={navigateTo}
                                productId={currentReference.product?.tagId}
                                typologyId={currentReference.typology?.tagId}
                            />
                        </div>
                    </div>

                    <div className={styles.brochureRequest}>
                        <BrochureRequest tagId={currentReference.product.tagId}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withTranslation('translations')(OverlayDetailPage);
