import { spacesApi } from '../api';

import actions from '../redux/actions';
import { LOAD_REFERENCE } from '../redux/actionTypes';

export const fetchSingleReference = (storeAPI) => (next) => (action) => {
  if (action.type !== LOAD_REFERENCE) return next(action);

  spacesApi
    .get(`/spaces/references/${action.payload.referenceId}`)
    .then((res) => {
      storeAPI.dispatch(actions.overlayActions.setReference(res.data));
    })
    .catch((err) => {
      console.log(err);
    });

  return next(action);
};
