import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers';

import {
  addFavorite,
  fetchFavorites,
  reorderFavorites,
  getMoreReferences,
  removeFavorite,
} from '../middleware/favorites';
import { fetchInitialMarkerList } from '../middleware/fetchInitialMarkerList';
import { fetchProducts } from '../middleware/fetchProducts';
import { fetchSingleReference } from '../middleware/fetchSingleReference';
import { fetchTypologies } from '../middleware/fetchTypologies';
import { fetchHistory, setHistory } from '../middleware/history';
import { setNotification } from '../middleware/notifications';
import { fetchSearchlog, setSearchlog } from '../middleware/searchlog';

const middleware = applyMiddleware(
  addFavorite,
  getMoreReferences,
  fetchFavorites,
  fetchInitialMarkerList,
  fetchSingleReference,
  fetchTypologies,
  fetchProducts,
  removeFavorite,
  reorderFavorites,
  fetchHistory,
  setHistory,
  fetchSearchlog,
  setSearchlog,
  setNotification,
);

let store;
if (window.navigator.userAgent.includes('Chrome') && 'on' === localStorage.getItem('debugMode')) {
  store = createStore(
    rootReducer,
    compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  store = createStore(rootReducer, compose(middleware));
}

export default store;
