import { SET_SEARCH_ORIGINAL_RESULTS, SET_SEARCH_RESULTS, SET_SEARCH_TERM } from '../actionTypes';

const setSearchResults = (results) => ({
  type: SET_SEARCH_RESULTS,
  payload: { results },
});

const setOriginalResults = (original_results) => ({
  type: SET_SEARCH_ORIGINAL_RESULTS,
  payload: { original_results },
});

const setSearchTerm = (term) => ({
  type: SET_SEARCH_TERM,
  payload: { term },
});

export default {
  setOriginalResults,
  setSearchResults,
  setSearchTerm,
};
