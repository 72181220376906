import {
  LOAD_PRODUCTS,
  LOAD_TYPOLOGIES,
  SET_FILTER,
  SET_PRODUCTS,
  SET_TYPOLOGIES,
} from '../actionTypes';

const loadTypologies = () => ({
  type: LOAD_TYPOLOGIES,
});

const loadProducts = () => ({
  type: LOAD_PRODUCTS,
});

const setTypologies = (typologies) => ({
  type: SET_TYPOLOGIES,
  payload: { typologies },
});

const setProducts = (products) => ({
  type: SET_PRODUCTS,
  payload: { products },
});

const setFilter = (options) => ({
  type: SET_FILTER,
  payload: { options },
});

export default {
  loadTypologies,
  loadProducts,
  setTypologies,
  setProducts,
  setFilter,
};
