import React from 'react';

import SVG from 'react-inlinesvg';

import styles from './iconLink.module.scss';

const IconLink = ({ icon, label, onClick }) => {
  return (
    <div className={styles.iconLink}>
      <a className={styles.iconLinkItem} onClick={onClick}>
        <SVG className={styles.icon} src={icon} />
        <span className={styles.label}>{label}</span>
      </a>
    </div>
  );
};

export default IconLink;
