import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import i18next from 'i18next';
import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import logo from '../../assets/logo.png';

Font.register({
  family: 'ProximaNova',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'light',
      src: 'https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
    },
  ],
});
Font.register({
  family: 'BrandonGrotesque',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      src: 'https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      src: 'https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'light',
      src: 'https://use.typekit.net/af/2f8f3d/0000000000000000000132dd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 40,
  },
  logo: {
    marginLeft: 'auto',
    marginBottom: 20,
    maxWidth: 200,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'BrandonGrotesque',
    color: '#000000',
    textTransform: 'uppercase',
  },
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'BrandonGrotesque',
    color: '#000000',
    textTransform: 'uppercase',
  },
  refNr: {
    fontSize: 12,
    fontFamily: 'ProximaNova',
    color: '#4a4a49',
    marginBottom: 25,
  },
  image: {
    width: 200,
  },
  productDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  productDetails: {
    borderLeft: 3,
    paddingLeft: 3,
    marginRight: 50,
    borderLeftColor: '#000000',
  },
  productDetailsText: {
    color: '#000000',
    fontWeight: 'bold',
    fontFamily: 'BrandonGrotesque',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  productDetailsLink: {
    color: '#D51130',
    fontFamily: 'ProximaNova',
    fontSize: 14,
    textDecoration: 'none',
    paddingBottom: 2,
  },
  textStyleDesc: {
    marginTop: 30,
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'BrandonGrotesque',
    color: '#000000',
    textTransform: 'uppercase',
  },
  textStyleDescText: {
    marginTop: 10,
    fontSize: 15,
    fontFamily: 'ProximaNova',
    color: '#000000',
  },
  referenceLink: {
    marginTop: 10,
    fontSize: 15,
    fontFamily: 'ProximaNova',
  },
});

const PdfDocument = (props) => {
  return (
    <Document>
      {props.data
        ? Object.values(props.data).map((item, index) => {
            const favItem = props.favorites.find((favorite) => favorite.id === item.id);
            return (
              <Page key={index} style={styles.page}>
                <View>
                  <Image style={styles.logo} source={logo} />
                </View>
                <View>
                  <Text style={styles.title}>{item.title}</Text>
                  <Text style={styles.refNr}>
                    <Translation ns={'translations'} i18n={i18next}>
                      {(t, i18next) => (
                        <p>
                          <Text>
                            {t('detail.refNumber')} {item.id}
                          </Text>
                        </p>
                      )}
                    </Translation>
                  </Text>
                  <Image style={styles.image} source={item.image ?? item.filenames[0]} />
                </View>
                <View style={styles.productDetailsWrapper}>
                  <View style={styles.productDetails}>
                    <Text style={styles.productDetailsText}>
                      <Translation ns={'translations'} i18n={i18next}>
                        {(t, i18next) => (
                          <p>
                            <Text>{t('detail.product')}</Text>
                          </p>
                        )}
                      </Translation>
                    </Text>
                    <Text>
                      <a
                        style={styles.productDetailsLink}
                        target={'_blank'}
                        href={`${window.location.origin}/#!/filter/product/${item.product.tagId}`}
                      >
                        <Text>{item.product.tagValue}</Text>
                      </a>
                    </Text>
                  </View>
                  <View style={styles.productDetails}>
                    <Text style={styles.productDetailsText}>
                      <Translation ns={'translations'} i18n={i18next}>
                        {(t) => (
                          <p>
                            <Text>{t('detail.system')}</Text>
                          </p>
                        )}
                      </Translation>
                    </Text>
                    <Text>
                      {item.system
                        .map((tag, index) => {
                          return (
                            <a
                              style={styles.productDetailsLink}
                              key={index}
                              target={'_blank'}
                              href={`${window.location.origin}/#!/filter/product/${tag.tagId}`}
                            >
                              <span>
                                <Text>{tag.tagValue}</Text>
                              </span>
                            </a>
                          );
                        })
                        .reduce((prev, curr) => [prev, ', ', curr])}
                    </Text>
                  </View>
                  {item.typology && (
                    <View style={styles.productDetails}>
                      <Text style={styles.productDetailsText}>
                        <Translation ns={'translations'} i18n={i18next}>
                          {(t) => (
                            <p>
                              <Text>{t('detail.typology')}</Text>
                            </p>
                          )}
                        </Translation>
                      </Text>
                      <Text>
                        <a
                          style={styles.productDetailsLink}
                          target={'_blank'}
                          href={`${window.location.origin}/#!/filter/typology/${item.typology.tagId}`}
                          rel='noreferrer'
                        >
                          <Text>{item.typology.tagValue}</Text>
                        </a>
                      </Text>
                    </View>
                  )}
                </View>
                <Text style={styles.textStyleDesc}>{item.headline || ''}</Text>
                <Text style={styles.textStyleDescText}>{item.description || ''}</Text>
                <Text style={styles.referenceLink}>
                  <a
                    style={styles.productDetailsLink}
                    target={'_blank'}
                    href={`${window.location.origin}/#!/${props.projectSlug}/${item.id}-${favItem.slug}.html`}
                  >
                    <Translation ns={'translations'} i18n={i18next}>
                      {(t) => (
                        <p>
                          <Text>{t('favorites.reference')}</Text>
                        </p>
                      )}
                    </Translation>
                  </a>
                </Text>
              </Page>
            );
          })
        : ''}
    </Document>
  );
};

export default withTranslation('translations')(PdfDocument);
