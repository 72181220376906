import React from 'react';

import styles from './headline.module.scss';

const Headline = ({ children, size }) => {
  return (
    <div>
      {size === 'h1' && <h1 className={styles.default}>{children}</h1>}
      {size === 'h2' && <h2 className={styles.default}>{children}</h2>}
      {size === 'h3' && <h3 className={styles.default}>{children}</h3>}
      {size === 'h4' && <h4 className={styles.default}>{children}</h4>}
      {size === 'h5' && <h5 className={styles.default}>{children}</h5>}
      {size === 'h6' && <h6 className={styles.default}>{children}</h6>}
    </div>
  );
};

export default Headline;
