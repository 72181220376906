import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import styles from './drawer.module.scss';

import logoDark from '../../assets/logo-white.png';
import logo from '../../assets/logo.png';

import angleRightIcon from '../../assets/icons/angle-right.svg';
import notificationsIcon from '../../assets/icons/bell-light.svg';
import notificationsActiveIcon from '../../assets/icons/bell-on-light.svg';
import typologyIcon from '../../assets/icons/buildingTypology.svg';
import questionIcon from '../../assets/icons/circle-question-light.svg';
import favoriteIcon from '../../assets/icons/favorite.svg';
import historyIcon from '../../assets/icons/history.svg';
import productsIcon from '../../assets/icons/products.svg';
import searchIcon from '../../assets/icons/search.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import userAltIcon from '../../assets/icons/user-alt.svg';
import userIcon from '../../assets/icons/user-circle.svg';

import DrawerLink from '../drawer-link/DrawerLink';
import { VIEW_MODES } from '../overlay/Overlay';

import SVG from 'react-inlinesvg';
import { router } from '../../router';
import DrawerContentProducts from '../drawer-content-products/DrawerContentProducts';
import DrawerContentProfile from '../drawer-content-profile/DrawerContentProfile';
import DrawerContentSettings from '../drawer-content-settings/DrawerContentSettings';
import DrawerContentTypologies from '../drawer-content-typologies/DrawerContentTypologies';
import DrawerContent from '../drawer-content/DrawerContent';

const Drawer = () => {
  const [collapseMobileDrawer, setCollapseMobileDrawer] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [darkMode] = useState(localStorage.getItem('darkMode'));

  const currentViewMode = useSelector((state) => state.overlay.currentViewMode);
  const avatar = useSelector((state) => state.user.avatar);
  const currentFavorites = useSelector((state) => state.user.favorites);
  const currentFilter = useSelector((state) => state.filter.options);
  const forceCloseDrawer = useSelector((state) => state.overlay.closeDrawer);
  const lastNotification = useSelector((state) => state.user.lastNotification)
    ?.split('.')
    .reverse()
    .join('/');

  const viewedNotificationRefs = useSelector((state) => state.user.viewedNotificationRefs);
  const tileView = useSelector((state) => state.user.tileViewEnabled);
  const markerList = useSelector((state) => state.map.markerList);
  const [notificationNr, setNotificationNr] = useState(0);
  const [t] = useTranslation('translations');

  const [offset, setOffset] = useState(0);
  const [menuVisible, setMenuVisible] = useState(true);
  const [isMobileViewport, setIsMobileViewport] = useState(false);

  const checkMobileViewport = () => {
    setIsMobileViewport(window.innerWidth <= 768);
  };

  const onScroll = () => {
    const tiles = document.getElementById('tiles');

    if (isMobileViewport) {
      if (tiles.scrollTop > offset) {
        setMenuVisible(false);
      } else {
        setMenuVisible(true);
      }
    } else {
      setMenuVisible(true);
    }

    setOffset(tiles.scrollTop);
  };

  useEffect(() => {
    const tiles = document.getElementById('tiles');
    checkMobileViewport();

    if (tiles && tileView) {
      tiles.removeEventListener('scroll', onScroll);
      tiles.addEventListener('scroll', onScroll, { passive: true });
    }
  }, [markerList, offset]);

  useEffect(() => {
    const lastNotificationDate = new Date(lastNotification);
    lastNotificationDate.setHours(23, 59, 59, 0);
    const nr = markerList.filter(
      (marker) =>
        new Date(marker.properties.date) > lastNotificationDate &&
        !viewedNotificationRefs.includes(marker.properties.id)
    ).length;
    setNotificationNr(nr);
  });

  useEffect(() => {
    if (open) {
      closeDrawer();
    }
  }, [forceCloseDrawer]);

  const closeDrawer = () => {
    setOpen(false);

    setTimeout(() => {
      setCollapseMobileDrawer(true);
      setSelectedContent(null);
    }, 300);
  };

  const toggleDrawer = (newSelectedContent) => {
    setCollapseMobileDrawer(false);

    if (null === selectedContent) {
      setOpen(!open);
      setSelectedContent(newSelectedContent);

      if (open) {
        setTimeout(() => {
          setCollapseMobileDrawer(true);
        }, 300);
      }

      return;
    }

    if (newSelectedContent !== selectedContent) {
      setSelectedContent(newSelectedContent);

      return;
    }

    closeDrawer();
  };

  const setSearchContent = () => {
    if (currentViewMode === VIEW_MODES.SEARCH_PAGE) {
      router.navigate('/');
    } else {
      router.navigate(`/${t('navigation.search')}.html`);
    }
  };

  const setTypologyContent = () => {
    toggleDrawer('typology');
  };

  const setProductsContent = () => {
    toggleDrawer('products');
  };

  const setProfileContent = () => {
    toggleDrawer('profile');
  };

  const setFavoritesContent = () => {
    if (currentViewMode === VIEW_MODES.FAVORITES_PAGES) {
      router.navigate('/');
    } else {
      router.navigate(`/${t('navigation.favorites')}.html`);
    }
  };

  const setHistoryContent = () => {
    if (currentViewMode === VIEW_MODES.HISTORY_PAGE) {
      router.navigate('/');
    } else {
      router.navigate(`/${t('navigation.history')}.html`);
    }
  };

  const setNotificationsContent = () => {
    if (currentViewMode === VIEW_MODES.NOTIFICATIONS_PAGE) {
      router.navigate('/');
    } else {
      router.navigate(`/${t('navigation.notifications')}.html`);
    }
  };

  const setHelpContent = () => {
    if (currentViewMode === VIEW_MODES.HELP_PAGE) {
      router.navigate('/');
    } else {
      router.navigate(`/${t('navigation.help')}.html`);
    }
  };

  const setSettingsContent = () => {
    toggleDrawer('settings');
  };

  return (
    <React.Fragment>
      {menuVisible && (
        <div
          className={classNames(styles.holder, {
            [styles.open]: open,
            [styles.delayedHeight]: collapseMobileDrawer,
          })}
        >
          <div className={styles.side}>
            <div>
              <DrawerLink
                name='profile'
                icon={userIcon}
                iconAlt={t('drawer.icons.profile')}
                onMenuClick={setProfileContent}
                active={selectedContent === 'profile'}
                desktopOnly={true}
                useImage={true}
                imageSrc={avatar}
              />
              <DrawerLink
                name='search'
                icon={searchIcon}
                iconAlt={t('drawer.icons.search')}
                onMenuClick={setSearchContent}
                active={currentViewMode === VIEW_MODES.SEARCH_PAGE}
                desktopOnly={true}
              />
              <DrawerLink
                name='typology'
                icon={typologyIcon}
                iconAlt={t('drawer.icons.typologies')}
                onMenuClick={setTypologyContent}
                active={selectedContent === 'typology'}
                badge={currentFilter.typologies.length}
              />
              <DrawerLink
                name='products'
                icon={productsIcon}
                iconAlt={t('drawer.icons.products')}
                onMenuClick={setProductsContent}
                active={selectedContent === 'products'}
                badge={currentFilter.products.length}
              />
              <DrawerLink
                name='profile'
                icon={userAltIcon}
                iconAlt={t('drawer.icons.profile')}
                onMenuClick={setProfileContent}
                active={selectedContent === 'profile'}
                mobileOnly={true}
              />
              <DrawerLink
                name='favorites'
                icon={favoriteIcon}
                iconAlt={t('drawer.icons.favorites')}
                onMenuClick={setFavoritesContent}
                active={currentViewMode === VIEW_MODES.FAVORITES_PAGES}
                badge={null === currentFavorites ? 0 : currentFavorites.length}
              />
              <DrawerLink
                name='history'
                icon={historyIcon}
                iconAlt={t('drawer.icons.history')}
                onMenuClick={setHistoryContent}
                active={currentViewMode === VIEW_MODES.HISTORY_PAGE}
              />
            </div>

            <div>
              <DrawerLink
                name='notifications'
                icon={notificationNr > 0 ? notificationsActiveIcon : notificationsIcon}
                iconAlt={t('drawer.icons.notifications')}
                onMenuClick={setNotificationsContent}
                active={currentViewMode === VIEW_MODES.NOTIFICATIONS_PAGE}
                badge={notificationNr}
                desktopOnly={true}
              />
              <DrawerLink
                name='help'
                icon={questionIcon}
                iconAlt={t('drawer.icons.help')}
                onMenuClick={setHelpContent}
                active={currentViewMode === VIEW_MODES.HELP_PAGE}
                desktopOnly={true}
              />
              <DrawerLink
                name='settings'
                icon={settingsIcon}
                iconAlt={t('drawer.icons.settings')}
                onMenuClick={setSettingsContent}
                active={selectedContent === 'settings'}
                desktopOnly={true}
              />
            </div>
          </div>
          <div className={classNames(styles.main, { [styles.mobileOpen]: open })}>
            <div className={styles.sideContentTop}>
              <SVG onClick={closeDrawer} className={styles.close} src={angleRightIcon} />
              <div className={styles.sideContentLogo}>
                <a href={window.location.origin}>
                  <img src={darkMode === 'true' ? logoDark : logo} />
                </a>
              </div>
            </div>

            <div className={styles.sideContent}>
              {selectedContent === 'profile' && (
                <DrawerContent title={t('drawer.icons.profile')}>
                  <DrawerContentProfile />
                </DrawerContent>
              )}
              {selectedContent === 'typology' && (
                <DrawerContent title={t('drawer.icons.typologies')}>
                  <DrawerContentTypologies />
                </DrawerContent>
              )}
              {selectedContent === 'products' && (
                <DrawerContent title={t('drawer.icons.products')}>
                  <DrawerContentProducts />
                </DrawerContent>
              )}
              {selectedContent === 'filter' && <DrawerContent title={t('drawer.icons.filter')} />}
              {selectedContent === 'settings' && (
                <DrawerContent title={t('drawer.icons.settings')}>
                  <DrawerContentSettings />
                </DrawerContent>
              )}
            </div>
          </div>
        </div>
      )}

      {menuVisible && (
        <div className={styles.mobileMenu}>
          <DrawerLink
            name='notifications'
            icon={notificationNr > 0 ? notificationsActiveIcon : notificationsIcon}
            iconAlt={t('drawer.icons.notifications')}
            onMenuClick={setNotificationsContent}
            active={currentViewMode === VIEW_MODES.NOTIFICATIONS_PAGE}
            badge={notificationNr}
            mobileOnly={true}
          />
          <DrawerLink
            name='help'
            icon={questionIcon}
            iconAlt={t('drawer.icons.help')}
            onMenuClick={setHelpContent}
            active={currentViewMode === VIEW_MODES.HELP_PAGE}
            mobileOnly={true}
          />
          <DrawerLink
            name='search'
            icon={searchIcon}
            iconAlt={t('drawer.icons.search')}
            onMenuClick={setSearchContent}
            active={currentViewMode === VIEW_MODES.SEARCH_PAGE}
            mobileOnly={true}
          />
          <DrawerLink
            name='settings'
            icon={settingsIcon}
            iconAlt={t('drawer.icons.settings')}
            onMenuClick={setSettingsContent}
            active={selectedContent === 'settings'}
            mobileOnly={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation('translations')(Drawer);
