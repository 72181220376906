import {
  COOKIES_ACCEPTED,
  FILTER_MAP,
  FLY_TO,
  LOADED_INITIAL_MARKER,
  LOAD_INITIAL_MARKER,
  OPEN_MARKER,
  SET_VIEWPORT,
} from '../actionTypes';

const cookiesAccepted = () => ({
  type: COOKIES_ACCEPTED,
});

const loadInitialMarker = () => ({
  type: LOAD_INITIAL_MARKER,
});

const setInitialMarkerList = (markers) => ({
  type: LOADED_INITIAL_MARKER,
  payload: { markers },
});

const setOpenMarker = (markerId) => ({
  type: OPEN_MARKER,
  payload: {
    id: markerId,
  },
});

const filterMap = (options) => ({
  type: FILTER_MAP,
  payload: { options },
});

const setViewport = (viewport) => ({
  type: SET_VIEWPORT,
  payload: { viewport },
});

const flyTo = (coords) => ({
  type: FLY_TO,
  payload: { coords },
});

export default {
  cookiesAccepted,
  loadInitialMarker,
  setInitialMarkerList,
  setOpenMarker,
  filterMap,
  setViewport,
  flyTo,
};
