import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { faMapMarkedAlt, faTh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';

import useLocalStorage from '../../hooks/useLocalStorage';
import actions from '../../redux/actions';
import styles from './viewSwitch.module.scss';
import Tooltip from "../tooltip/Tooltip";
import {useTranslation} from "react-i18next";
import shareIcon from "../../assets/icons/share.svg";
import SVG from "react-inlinesvg";
import Modal from "../modal/Modal";

const ViewSwitch = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation('translations');
  const [localStorageTileView, setLocalStorageTileView] = useLocalStorage('tileViewEnabled', false);
  const tileViewEnabled = useSelector((state) => state.user.tileViewEnabled);
  const [offset, setOffset] = useState(0);
  const [menuVisible, setMenuVisible] = useState(true);
  const [isMobileViewport, setIsMobileViewport] = useState(false);
  const markerList = useSelector((state) => state.map.markerList);
  const currentViewport = useSelector((state) => state.map.viewport);
  const [modalOpen, setModalOpen] = useState(false);

  const onScroll = () => {
    const tiles = document.getElementById('tiles');
    checkMobileViewport();
    if (isMobileViewport) {
      if (tiles.scrollTop > offset) {
        setMenuVisible(false);
      } else {
        setMenuVisible(true);
      }
    } else {
      setMenuVisible(true);
    }

    setOffset(tiles.scrollTop);
  };

  useEffect(() => {
    const tiles = document.getElementById('tiles');

    if (tiles && localStorageTileView) {
      tiles.removeEventListener('scroll', onScroll);
      tiles.addEventListener('scroll', onScroll, { passive: true });
    }
  }, [markerList, offset]);

  const checkMobileViewport = () => {
    setIsMobileViewport(window.innerWidth <= 768);
  };

  window.addEventListener('resize', () => {
    checkMobileViewport();
  });

  const toggleTileViewSwitch = (enabled) => {
    if (enabled === tileViewEnabled) {
      return;
    }

    setLocalStorageTileView(enabled);
    dispatch(actions.userActions.setTileViewEnabled(enabled));
  };

  const createShareLink = () => {
    const shareLink = `${window.location.origin}/#!/location/${currentViewport.latitude}/${currentViewport.longitude}/${currentViewport.zoom}`;
    navigator.clipboard.writeText(shareLink);
    setModalOpen(true)
  };

  return (
    <>
      {menuVisible && (
          <>
            {!tileViewEnabled && (
                <div className={styles.shareButton}>
                  <div style={{marginBottom: '1rem'}}>
                    <SVG className={styles.icon} onClick={createShareLink} src={shareIcon} />
                  </div>
                </div>
            )}

            <div className={styles.viewSwitch}>
              <div
                  onClick={() => toggleTileViewSwitch(false)}
                  className={classNames({
                    [styles.active]: !tileViewEnabled,
                  })}
              >
                <Tooltip className={styles.switchTooltip} text={t(`viewSwitch.map`)}>
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                </Tooltip>

              </div>
              <div
                  onClick={() => toggleTileViewSwitch(true)}
                  className={classNames({
                    [styles.active]: tileViewEnabled,
                  })}
              >
                <Tooltip className={styles.switchTooltip} text={t(`viewSwitch.grid`)}>
                  <FontAwesomeIcon icon={faTh} />
                </Tooltip>
              </div>
            </div>

            <Modal
                onClose={() => setModalOpen(false)}
                showModal={modalOpen}
                title={t('drawerContent.profile.snippetMessageTitle')}
            >
              <p className={styles.snippetMessage}>
                {t('drawerContent.profile.snippetMessage')}
              </p>
            </Modal>
          </>
      )}
    </>
  );
};

export default ViewSwitch;
