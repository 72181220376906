import React, { useState } from 'react';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import styles from './projectFavorite.module.scss';

import favoriteFilledIcon from '../../assets/icons/favorite-filled.svg';

const ProjectFavorite = ({ children, onRemove }) => {
  const [removed, setRemoved] = useState(false);

  const onClick = () => {
    setRemoved(true);
    onRemove();
  };

  return (
    <div
      className={classNames(styles.projectFavorite, {
        [styles.fadeOut]: removed,
      })}
    >
      <div className={styles.icon}>
        <SVG onClick={onClick} src={favoriteFilledIcon} />
      </div>
      {children}
    </div>
  );
};

export default ProjectFavorite;
