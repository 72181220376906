import React from 'react';
import styles from './grid.module.scss';

const Grid = ({ children, columns }) => {
    return (
        <div
            className={styles.grid}
            style={{
                gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            }}
        >
            {children}
        </div>
    );
};

export default Grid;