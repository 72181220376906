import classNames from 'classnames';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';

import styles from './typologyFilterItem.module.scss';

import apartmentBuildingIcon from '../../assets/icons/typologies/apartmentBuilding.svg';
import commercialAndIndustrialIcon from '../../assets/icons/typologies/commercialAndIndustrie.svg';
import cultureAndSacredBuildingsIcon from '../../assets/icons/typologies/cultureAndSacredBuldings.svg';
import educationAndScienceIcon from '../../assets/icons/typologies/educationAndScience.svg';
import facadeIcon from '../../assets/icons/typologies/facade.svg';
import healthAndCareIcon from '../../assets/icons/typologies/healthAndCare.svg';
import hotelsAndGastronomyIcon from '../../assets/icons/typologies/hotelsAndGastronomy.svg';
import livingAndGardenIcon from '../../assets/icons/typologies/livingAndGarden.svg';
import officeAndAdministrationIcon from '../../assets/icons/typologies/officeAndAdministration.svg';
import otherIcon from '../../assets/icons/typologies/other.svg';
import stadiumsAndArenasIcon from '../../assets/icons/typologies/stadiumsAndArenas.svg';
import wellnessAndFitnessIcon from '../../assets/icons/typologies/wellnessAndFitness.svg';

import FilterItem from '../filter-item/FilterItem';
import { MARKER_TYPES } from '../marker/Marker';

const TypologyFilterItem = ({ typology, onChange }) => {
  const { tagId, text, type } = typology;

  const currentFilter = useSelector((state) => state.filter.options);

  const getIcon = (type) => {
    switch (type) {
      case MARKER_TYPES.FACADE:
        return facadeIcon;
      case MARKER_TYPES.APARTMENT_BUILDING:
        return apartmentBuildingIcon;
      case MARKER_TYPES.OFFICE_AND_ADMINISTRATION:
        return officeAndAdministrationIcon;
      case MARKER_TYPES.COMMERCIAL_AND_INDUSTRIAL:
        return commercialAndIndustrialIcon;
      case MARKER_TYPES.STADIUMS_AND_ARENAS:
        return stadiumsAndArenasIcon;
      case MARKER_TYPES.WELLNESS_AND_FITNESS:
        return wellnessAndFitnessIcon;
      case MARKER_TYPES.LIVING_AND_GARDEN:
        return livingAndGardenIcon;
      case MARKER_TYPES.EDUCATION_AND_SCIENCE:
        return educationAndScienceIcon;
      case MARKER_TYPES.HOTELS_AND_GASTRONOMY:
        return hotelsAndGastronomyIcon;
      case MARKER_TYPES.HEALTH_AND_CARE:
        return healthAndCareIcon;
      case MARKER_TYPES.CULTURE_AND_SACRED_BUILDINGS:
        return cultureAndSacredBuildingsIcon;
      default:
        return otherIcon;
    }
  };

  return (
    <div className={styles.typologyFilterItem}>
      <FilterItem onChange={onChange} isChecked={-1 !== currentFilter.typologies.indexOf(tagId)}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <SVG src={getIcon(type)} />
            <div
              className={classNames(styles.splash, {
                [styles.facade]: MARKER_TYPES.FACADE === type,
                [styles.apartment]: MARKER_TYPES.APARTMENT_BUILDING === type,
                [styles.office]: MARKER_TYPES.OFFICE_AND_ADMINISTRATION === type,
                [styles.commercial]: MARKER_TYPES.COMMERCIAL_AND_INDUSTRIAL === type,
                [styles.stadiums]: MARKER_TYPES.STADIUMS_AND_ARENAS === type,
                [styles.wellness]: MARKER_TYPES.WELLNESS_AND_FITNESS === type,
                [styles.living]: MARKER_TYPES.LIVING_AND_GARDEN === type,
                [styles.education]: MARKER_TYPES.EDUCATION_AND_SCIENCE === type,
                [styles.hotels]: MARKER_TYPES.HOTELS_AND_GASTRONOMY === type,
                [styles.health]: MARKER_TYPES.HEALTH_AND_CARE === type,
                [styles.culture]: MARKER_TYPES.CULTURE_AND_SACRED_BUILDINGS === type,
                [styles.other]: MARKER_TYPES.OTHER === type,
              })}
            />
          </div>
          <div className={styles.text}>{text}</div>
        </div>
      </FilterItem>
    </div>
  );
};

export default TypologyFilterItem;
