import React from 'react';

import styles from './drawerContent.module.scss';

const DrawerContent = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
    </div>
  );
};

export default DrawerContent;
