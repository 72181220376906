import classNames from 'classnames';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import {faFacebook, faInstagram, faYoutube, faPinterest, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import blogIcon from '../../assets/icons/blog.svg';
import Cookies from 'js-cookie';

import styles from './drawerContentSettings.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SVG from "react-inlinesvg";

const DrawerContentSettings = () => {
  const [t] = useTranslation('translations');

  const onChange = (e) => {
    localStorage.setItem('i18nextLng', e.target.value);
    setTimeout(() => {
      window.location.href = '/';
    }, 200);
  };

  const changeCookieSettings = () => {
    Cookies.remove('spaces_marketing');
    window.location.reload();
  };

  const changeDarkMode = (e) => {
    localStorage.setItem('darkMode', e.target.checked);

    setTimeout(() => {
      window.location.href = '/';
    }, 200);
  };

  return (
    <div className={styles.settings}>
      <label className={styles.label} htmlFor='language'>
        {t('drawer.settings.language')}
      </label>
      <select
        onChange={onChange}
        defaultValue={localStorage.getItem('i18nextLng')}
        className={styles.select}
        id='language'
      >
        <option value='de'>{t('drawer.settings.de')}</option>
        <option value='en'>{t('drawer.settings.en')}</option>
      </select>
      <div className={styles.copyright}>
        <a onClick={changeCookieSettings}>{t('drawer.settings.changeCookieSettings')}</a>
        <br />
        <a target='_blank' href={t('drawer.settings.imprintLink')}>
          {t('drawer.settings.imprint')}
        </a>
        <a target='_blank' href={t('drawer.settings.datapolicyLink')}>
          {t('drawer.settings.datapolicy')}
        </a>
      </div>
      <div className={styles.darkModeWrapper}>
        <span className={styles.darkModelabel}>{t('drawer.settings.darkMode')}</span>
        <label className={styles.switch}>
          <input
            onChange={changeDarkMode}
            defaultChecked={localStorage.getItem('darkMode') === 'true'}
            type='checkbox'
          />
          <span className={classNames(styles.slider, styles.round)}></span>
        </label>
      </div>

      <div className={styles.contact}>
        <p>
          Solarlux GmbH<br/>
          Industriepark 1<br/>
          49324 Melle
        </p>
        <p>
          T: <a href="tel:+49 5422 9271-0">+49 5422 9271-0</a><br/>
          F: <a href="tel:+49 5422 9271-8200">+49 5422 9271-8200</a>
        </p>
        <p>
          <a href="mailto:info@solarlux.com">info@solarlux.com</a><br/>
          <a target='_blank' href="https://solarlux.com">www.solarlux.com</a><br/>
        </p>
        <p>
          <a href={t('drawer.settings.contactLink')}>{t('drawer.settings.contact')}</a><br/>
        </p>
        <p className={styles.imprint}>
          <a target={'_blank'} href={t('drawer.settings.imprintLink')}>{t('drawer.settings.imprint')}</a><br/>
          <a target={'_blank'} href={t('drawer.settings.datapolicyLink')}>{t('drawer.settings.datapolicy')}</a>
        </p>
      </div>
      <div className={styles.iconsFooter}>
        <ul>
          <li>
            <a target="_blank" href="https://de-de.facebook.com/solarlux/"><FontAwesomeIcon icon={faFacebook} /></a>
          </li>
          <li>
            <a target="_blank" href="https://www.instagram.com/solarlux/"><FontAwesomeIcon icon={faInstagram} /></a>
          </li>
          <li>
            <a target="_blank" href="https://www.youtube.com/c/solarlux/"><FontAwesomeIcon icon={faYoutube} /></a>
          </li>
          <li>
            <a target="_blank" href="https://blog.solarlux.de/"><SVG className={styles.blogIcon} src={blogIcon}/></a>
          </li>
          <li>
            <a target="_blank" href="https://www.pinterest.de/solarlux/"><FontAwesomeIcon icon={faPinterest} /></a>
          </li>
          <li>
            <a target="_blank" href="https://de.linkedin.com/company/solarluxgmbh"><FontAwesomeIcon icon={faLinkedin} /></a>
          </li>


        </ul>
      </div>
    </div>
  );
};

export default withTranslation('translations')(DrawerContentSettings);
