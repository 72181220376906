import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import SVG from 'react-inlinesvg';

import styles from './overlayDetailPageProjectSlider.module.scss';

import angleLeftIcon from '../../assets/icons/angle-left.svg';

const OverlayDetailPageProjectSlider = ({ loadPreviousProject, loadNextProject }) => {
  const [t] = useTranslation('translations');

  return (
    <div className={styles.projectSlider}>
      <div onClick={loadPreviousProject} className={styles.projectSliderPrevious}>
        <SVG className={styles.projectSliderIcon} src={angleLeftIcon} />
        <span>{t('detail.previousProject')}</span>
      </div>
      <div onClick={loadNextProject} className={styles.projectSliderNext}>
        <span>{t('detail.nextProject')}</span>
        <SVG className={styles.projectSliderIcon} src={angleLeftIcon} />
      </div>
    </div>
  );
};

export default withTranslation('translations')(OverlayDetailPageProjectSlider);
