import {
  COOKIES_ACCEPTED,
  FILTER_MAP,
  FLY_TO,
  LOADED_INITIAL_MARKER,
  OPEN_MARKER,
  SET_VIEWPORT,
} from '../actionTypes';

const initialState = {
  currentMarker: null,
  originalMarkerList: [],
  markerList: [],
  cookiesAccepted: false,
  flyTo: null,
  viewport: {
    latitude: 52.1983899,
    longitude: 8.3042422,
    zoom: 1.5,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COOKIES_ACCEPTED: {
      return {
        ...state,
        cookiesAccepted: true,
      };
    }
    case LOADED_INITIAL_MARKER: {
      const { markers } = action.payload;
      return {
        ...state,
        markerList: markers,
        originalMarkerList: markers,
      };
    }
    case OPEN_MARKER: {
      const { id } = action.payload;
      return {
        ...state,
        currentMarker: id === state.currentMarker ? null : id,
      };
    }
    case FILTER_MAP: {
      const { typologies, products } = action.payload.options;
      if (typologies.length === 0 && products.length === 0) {
        return {
          ...state,
          markerList: state.originalMarkerList,
        };
      }

      if (typologies.length !== 0) {
        return {
          ...state,
          markerList: state.originalMarkerList.filter(
            (marker) => -1 !== typologies.indexOf(marker.properties.typologyId)
          ),
        };
      }

      if (products.length !== 0) {
        return {
          ...state,
          markerList: state.originalMarkerList.filter((marker) => {
            return 0 !== marker.properties.tags.filter((tag) => products.includes(tag)).length;
          }),
        };
      }
    }
    case SET_VIEWPORT: {
      const { viewport } = action.payload;
      return {
        ...state,
        viewport,
      };
    }
    case FLY_TO: {
      const { coords } = action.payload;
      return {
        ...state,
        flyTo: coords,
      };
    }
    default:
      return state;
  }
};
