import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './tabs.module.scss';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={styles.tabs}>
      <div className={styles.navigation}>
        {children.map((tab, index) => {
          return (
            <div
              key={index}
              onClick={() => setActiveTab(index)}
              className={classNames(styles.navigationItem, {
                [styles.navigationItemActive]: index === activeTab,
              })}
            >
              {tab.props['data-tab-label']}
            </div>
          );
        })}
      </div>

      <div className={styles.content}>{children[activeTab]}</div>
    </div>
  );
};

export default Tabs;
