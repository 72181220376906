import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SVG from 'react-inlinesvg';

import actions from '../../redux/actions';

import styles from './drawerContentProducts.module.scss';

import FilterItem from '../filter-item/FilterItem';

import resetFilterIcon from '../../assets/icons/filter-circle-xmark-light.svg';
import balconyImage from '../../assets/img/balcony.svg';
import biFoldingImage from '../../assets/img/biFolding.svg';
import ceroImage from '../../assets/img/cero.svg';
import hsdImage from '../../assets/img/hsd.svg';
import roofImage from '../../assets/img/roof.svg';
import slidingImage from '../../assets/img/sliding.svg';
import wintergardenImage from '../../assets/img/wintergarden.svg';
import Collapsible from '../collapsible/Collapsible';

const DrawerContentProducts = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.filter.products);
  const currentFilter = useSelector((state) => state.filter.options);

  const getProductImage = (tagId) => {
    switch (tagId) {
      case 15485:
        return balconyImage;
      case 22357:
        return ceroImage;
      case 15479:
        return biFoldingImage;
      case 15497:
        return hsdImage;
      case 15494:
        return slidingImage;
      case 15519:
        return slidingImage;
      case 15523:
        return roofImage;
      case 15489:
        return roofImage;
      case 15476:
        return wintergardenImage;
      default:
        return '';
    }
  };

  const addTag = (tagId) => {
    const selectedProducts = currentFilter.products;
    selectedProducts.push(tagId);

    const typologies = [];
    const products = selectedProducts;

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const removeTag = (tagId) => {
    const typologies = [];
    const products = currentFilter.products.filter((item) => item !== tagId);

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const removeAllProductFilters = () => {
    const typologies = [];
    const products = [];

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  return (
    <div className={styles.products}>
      {currentFilter.products.length ? (
        <div onClick={removeAllProductFilters} className={styles.removeFilters}>
          <SVG className={styles.expanded} src={resetFilterIcon} />
        </div>
      ) : (
        <></>
      )}

      {products.map((product, index) => {
        return (
          <div key={index} style={{ marginBottom: '1.5rem' }}>
            <Collapsible>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FilterItem
                  emptyLabel={true}
                  onChange={(added) => {
                    if (added) {
                      addTag(product.tagId);
                    } else {
                      removeTag(product.tagId);
                    }
                  }}
                  isChecked={-1 !== currentFilter.products.indexOf(product.tagId)}
                />
                <div className={styles.product}>
                  <SVG src={getProductImage(product.tagId)} />
                  <div className={styles.productName}>{product.name}</div>
                </div>
              </div>
              {product.filterGroups.map((filterGroup, fgIndex) => {
                return (
                  <div key={fgIndex} className={styles.filterGroup}>
                    <div className={styles.filterGroupName}>{filterGroup.name}</div>
                    {filterGroup.filter.map((system, sysIndex) => {
                      return (
                        <div key={sysIndex} style={{ marginBottom: '1.25rem' }}>
                          <FilterItem
                            onChange={(added) => {
                              if (added) {
                                addTag(system.tagId);
                              } else {
                                removeTag(system.tagId);
                              }
                            }}
                            isChecked={-1 !== currentFilter.products.indexOf(system.tagId)}
                          >
                            <div className={styles.systemName}>{system.name}</div>
                          </FilterItem>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Collapsible>
          </div>
        );
      })}
    </div>
  );
};

export default DrawerContentProducts;
