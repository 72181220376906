import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import classNames from 'classnames';
import ReactMapGL, { Marker as MapBoxMarker } from 'react-map-gl';
import useSupercluster from 'use-supercluster';

import Cluster from '../cluster/Cluster';
import Marker, {MARKER_TYPES} from '../marker/Marker';

import 'mapbox-gl/dist/mapbox-gl.css';

import SVG from 'react-inlinesvg';
import timesIcon from '../../assets/icons/times.svg';
import styles from './map.module.scss';
import balconyImage from "../../assets/img/balcony.svg";
import ceroImage from "../../assets/img/cero.svg";
import biFoldingImage from "../../assets/img/biFolding.svg";
import hsdImage from "../../assets/img/hsd.svg";
import slidingImage from "../../assets/img/sliding.svg";
import roofImage from "../../assets/img/roof.svg";
import wintergardenImage from "../../assets/img/wintergarden.svg";

const Map = () => {
  const dispatch = useDispatch();

  const currentViewport = useSelector((state) => state.map.viewport);
  const [openMarker, setOpenMarker] = useState(null);

  const mapRef = useRef();
  const bounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const currentFilters = useSelector((state) => state.filter);

  const removeTypologyFilter = (typology) => {
    const typologies = currentFilters.options.typologies.filter((item) => item !== typology);
    const products = [];

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const removeProductFilter = (product) => {
    const typologies = [];
    const products = currentFilters.options.products.filter((item) => item !== product);

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const points = useSelector((state) => state.map.markerList);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: currentViewport.zoom,
    options: { radius: 125, minZoom: 2, maxZoom: 10, minPoints: 2 },
  });

  const currentMarker = useSelector((state) => state.map.currentMarker);

  useEffect(() => {
    setOpenMarker(currentMarker);
  }, [currentMarker]);

  useEffect(() => {
    dispatch(actions.mapActions.loadInitialMarker());
  }, [dispatch]);

  const [showMap, setShowMap] = useState(false);
  const currentCookieState = useSelector((state) => state.map.cookiesAccepted);

  useEffect(() => {
    setShowMap(currentCookieState);
  }, [currentCookieState]);

  const flyToCoords = useSelector((state) => state.map.flyTo);

  useEffect(() => {
    if (flyToCoords === null) return;

    mapRef.current?.flyTo({
      center: [flyToCoords.longitude, flyToCoords.latitude],
      zoom: 12,
      duration: 3000,
    });
  }, [flyToCoords]);

  if (!showMap) {
    return <React.Fragment />;
  }

  const getProductImage = (tagId) => {
    switch (tagId) {
      case 15485:
        return balconyImage;
      case 22357:
        return ceroImage;
      case 15479:
        return biFoldingImage;
      case 15497:
        return hsdImage;
      case 15494:
        return slidingImage;
      case 15519:
        return slidingImage;
      case 15523:
        return roofImage;
      case 15489:
        return roofImage;
      case 15476:
        return wintergardenImage;
      default:
        return '';
    }
  };

  return (
    <>
      <div className={styles.filters}>
        {currentFilters.options.products.length ? (
          <>
            {currentFilters.options.products.map((product, index) => {
              const prod = currentFilters.products.find((item) => item.tagId === product);
              if (!prod) return <></>;

              return (
                <span key={index}  className={classNames(styles.filter, {
                  [styles.balcony]: prod.tagId === 15485,
                  [styles.cero]: prod.tagId === 22357,
                  [styles.bifolding]: prod.tagId === 15479,
                  [styles.hsd]: prod.tagId === 15497,
                  [styles.sliding]: prod.tagId === 15494 ||  prod.tagId === 15519,
                  [styles.roof]: prod.tagId === 15523 || prod.tagId === 15489,
                  [styles.wintergarten]: prod.tagId === 15476,

                })}>
                  {prod.name}
                  <SVG
                    className={classNames(styles.closeIcon)}
                    onClick={() => removeProductFilter(product)}
                    src={timesIcon}
                  />
                </span>
              );
            })}
          </>
        ) : (
          <></>
        )}

        {currentFilters.options.typologies.length ? (
          <>
            {currentFilters.options.typologies.map((typology, index) => {
              const typ = currentFilters.typologies.find((item) => item.tagId === typology);

              if (!typ) return <></>;
              return (
                <span key={index}
                      className={classNames(styles.filter, {
                        [styles.facade]: MARKER_TYPES.FACADE === typ.type,
                        [styles.apartment]: MARKER_TYPES.APARTMENT_BUILDING === typ.type,
                        [styles.office]: MARKER_TYPES.OFFICE_AND_ADMINISTRATION === typ.type,
                        [styles.commercial]: MARKER_TYPES.COMMERCIAL_AND_INDUSTRIAL === typ.type,
                        [styles.stadiums]: MARKER_TYPES.STADIUMS_AND_ARENAS === typ.type,
                        [styles.wellness]: MARKER_TYPES.WELLNESS_AND_FITNESS === typ.type,
                        [styles.living]: MARKER_TYPES.LIVING_AND_GARDEN === typ.type,
                        [styles.education]: MARKER_TYPES.EDUCATION_AND_SCIENCE === typ.type,
                        [styles.hotels]: MARKER_TYPES.HOTELS_AND_GASTRONOMY === typ.type,
                        [styles.health]: MARKER_TYPES.HEALTH_AND_CARE === typ.type,
                        [styles.culture]: MARKER_TYPES.CULTURE_AND_SACRED_BUILDINGS === typ.type,
                        [styles.other]: MARKER_TYPES.OTHER === typ.type,
                      })}
                >
                  {typ.text}
                  <SVG
                    className={classNames(styles.closeIcon)}
                    onClick={() => removeTypologyFilter(typology)}
                    src={timesIcon}
                  />
                </span>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </div>
      <ReactMapGL
        {...currentViewport}
        maxZoom={20}
        mapboxAccessToken={
          'pk.eyJ1Ijoic29sYXJsdXgiLCJhIjoiY2tsamYzOXA3MDZjZjJ4cDV6d3h5ZXp0eCJ9.kDIFmGyuNIduX6S13bXpGQ'
        }
        onMove={(e) => {
          dispatch(actions.mapActions.setViewport({ ...e.viewState }));
        }}
        ref={mapRef}
        mapStyle='mapbox://styles/solarlux/ckljg8r3k0gro17o2r90n2tg2'
        onLoad={() => {
          if (localStorage.getItem('darkMode') === 'true') {
            mapRef.current.getMap().setPaintProperty('water', 'fill-color', '#7d7c7d');
          }

          if ('de' !== localStorage.getItem('i18nextLng')) {
            mapRef.current
              .getMap()
              .setLayoutProperty('settlement-major-label', 'text-field', ['get', 'name_en']);

            mapRef.current
              .getMap()
              .setLayoutProperty('settlement-minor-label', 'text-field', ['get', 'name_en']);
          }
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <div key={`cluster-${cluster.id}`} className={styles.cluster}>
                <MapBoxMarker style={{ zIndex: 0 }} latitude={latitude} longitude={longitude}>
                  <Cluster
                    size={pointCount}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );

                      mapRef.current?.flyTo({
                        center: [longitude, latitude],
                        zoom: expansionZoom,
                        duration: 500,
                      });
                    }}
                  />
                </MapBoxMarker>
              </div>
            );
          }

          return (
            <div
              key={cluster.properties.id}
              className={classNames(styles.marker, {
                [styles.activeMarker]: cluster.properties.id === openMarker,
              })}
            >
              <MapBoxMarker
                style={{ zIndex: cluster.properties.id === openMarker ? 3 : 0 }}
                latitude={latitude}
                longitude={longitude}
              >
                <Marker
                  key={`marker-${cluster.properties.id}`}
                  title={cluster.properties.title}
                  id={cluster.properties.id}
                  typology={cluster.properties.typology}
                  file={cluster.properties.file}
                  product={cluster.properties.product}
                  system={cluster.properties.system}
                  slug={cluster.properties.slug}
                  city={cluster.properties.city}
                  date={cluster.properties.date}
                />
              </MapBoxMarker>
            </div>
          );
        })}
      </ReactMapGL>
    </>
  );
};

export default Map;
