import {
  ADD_FAVORITE,
  DISABLE_FAVORITE_LOADING_SPINNER,
  ENABLE_FAVORITE_LOADING_SPINNER,
  GET_MORE_REFERENCES,
  LOADED_FAVORITES,
  LOADED_MORE_REFERENCES,
  REMOVE_FAVORITE,
  REORDER_FAVORITES,
  SET_HISTORY_ITEM,
  SET_TILE_VIEW_ENABLED,
  SET_USER,
  SET_VIEWED_NOTIFICATION,
} from '../actionTypes';

const addFavorite = (id) => ({
  type: ADD_FAVORITE,
  payload: { id },
});

const getMoreReferences = (data, offset) => ({
  type: GET_MORE_REFERENCES,
  payload: { data: data, offset: offset },
});

const enableFavoriteLoadingSpinner = () => ({
  type: ENABLE_FAVORITE_LOADING_SPINNER,
});

const disableFavoriteLoadingSpinner = () => ({
  type: DISABLE_FAVORITE_LOADING_SPINNER,
});

const removeFavorite = (id) => ({
  type: REMOVE_FAVORITE,
  payload: { id },
});

const reorderFavorites = (items) => ({
  type: REORDER_FAVORITES,
  payload: { items },
});

const setFavorites = (favorites) => ({
  type: LOADED_FAVORITES,
  payload: { favorites },
});

const setMoreReferences = (favoriteReferences) => ({
  type: LOADED_MORE_REFERENCES,
  payload: { favoriteReferences },
});

const setUser = (user) => ({
  type: SET_USER,
  payload: { user },
});

const setTileViewEnabled = (tileViewEnabled) => ({
  type: SET_TILE_VIEW_ENABLED,
  payload: { tileViewEnabled },
});

const setHistoryItem = (history) => ({
  type: SET_HISTORY_ITEM,
  payload: { history },
});

const setViewedNotification = (viewedNotificationRefs) => ({
  type: SET_VIEWED_NOTIFICATION,
  payload: { viewedNotificationRefs },
});

export default {
  addFavorite,
  getMoreReferences,
  enableFavoriteLoadingSpinner,
  disableFavoriteLoadingSpinner,
  removeFavorite,
  reorderFavorites,
  setFavorites,
  setMoreReferences,
  setTileViewEnabled,
  setUser,
  setHistoryItem,
  setViewedNotification,
};
