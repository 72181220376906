import {
  ADD_FAVORITE,
  GET_MORE_REFERENCES,
  LOAD_FAVORITES,
  REMOVE_FAVORITE,
  REORDER_FAVORITES,
  SET_USER,
} from '../redux/actionTypes';

import * as Cookies from 'js-cookie';

import { spacesApi } from '../api';
import actions from '../redux/actions';

export const fetchFavorites = (storeAPI) => (next) => (action) => {
  if (action.type !== LOAD_FAVORITES && action.type !== SET_USER) return next(action);

  const headers = {
    Authorization: `Bearer ${Cookies.get('token')}`,
  };

  spacesApi
    .get('/spaces/favorites/', { headers })
    .then((res) => {
      storeAPI.dispatch(actions.userActions.setFavorites(res.data));
      const data = res.data.map((favorite) => ({
        productTag: favorite.product.tagId,
        typologyId: favorite.typology.tagId,
      }));

      if (data.length > 0) {
        spacesApi
          .get('/spaces/favorites/references', { params: { data: data } })
          .then((res) => {
            storeAPI.dispatch(actions.userActions.setMoreReferences(res.data));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return next(action);
};

export const getMoreReferences = (storeAPI) => (next) => (action) => {
  if (action.type !== GET_MORE_REFERENCES) return next(action);

  const headers = {
    Authorization: `Bearer ${Cookies.get('token')}`,
  };

  storeAPI.dispatch(actions.userActions.enableFavoriteLoadingSpinner());
  spacesApi
    .get('/spaces/favorites/references', {
      params: { data: action.payload.data, offset: action.payload.offset },
    })
    .then((res) => {
      storeAPI.dispatch(actions.userActions.disableFavoriteLoadingSpinner());
      storeAPI.dispatch(actions.userActions.setMoreReferences(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addFavorite = (storeAPI) => (next) => (action) => {
  if (action.type !== ADD_FAVORITE) return next(action);

  const headers = {
    Authorization: `Bearer ${Cookies.get('token')}`,
  };

  storeAPI.dispatch(actions.userActions.enableFavoriteLoadingSpinner());
  spacesApi
    .post('/spaces/favorites/', { referenceId: action.payload.id, sortOrder: 0 }, { headers })
    .then((res) => {
      storeAPI.dispatch(actions.userActions.disableFavoriteLoadingSpinner());
      storeAPI.dispatch(actions.userActions.setFavorites(res.data));
    })
    .catch((err) => {
      console.log(err);
    });

  return next(action);
};

export const removeFavorite = (storeAPI) => (next) => (action) => {
  if (action.type !== REMOVE_FAVORITE) return next(action);

  const headers = {
    Authorization: `Bearer ${Cookies.get('token')}`,
  };

  spacesApi.delete(`/spaces/favorites/${action.payload.id}`, { headers });

  return next(action);
};

export const reorderFavorites = (storeAPI) => (next) => (action) => {
  if (action.type !== REORDER_FAVORITES) return next(action);

  const headers = {
    Authorization: `Bearer ${Cookies.get('token')}`,
  };

  const items = [];
  action.payload.items.forEach((item) => items.push({referenceId: item.id, sortOrder: item.order}));

  spacesApi
      .post('/spaces/favorites/sort', { items: items }, { headers })
      .catch((err) => {
        console.log(err);
      });

  return next(action);
};
