import React from 'react';

import classNames from 'classnames';

import styles from './cluster.module.scss';

const Cluster = (props) => {
  const isSmall = () => {
    return props.size < 100;
  };

  const isMedium = () => {
    const { size } = props;
    return size >= 100 && size < 1000;
  };

  const isLarge = () => {
    return props.size >= 1000;
  };

  return (
    <div
      className={classNames(styles.cluster, {
        [styles.small]: isSmall(),
        [styles.medium]: isMedium(),
        [styles.large]: isLarge(),
      })}
      onClick={props.onClick}
    >
      {props.size}
    </div>
  );
};

export default Cluster;
