import { spacesApi } from '../api';

import actions from '../redux/actions';
import { LOAD_INITIAL_MARKER } from '../redux/actionTypes';

export const fetchInitialMarkerList = (storeAPI) => (next) => (action) => {
  if (action.type !== LOAD_INITIAL_MARKER) return next(action);

  spacesApi
    .get('/spaces/references/')
    .then((res) => {
      const options = storeAPI.getState().filter.options;

      storeAPI.dispatch(actions.mapActions.setInitialMarkerList(res.data));
      storeAPI.dispatch(actions.mapActions.filterMap(options));
    })
    .catch((err) => {
      console.log(err);
    });

  return next(action);
};
