import { spacesApi } from '../api';
import { LOAD_TYPOLOGIES } from '../redux/actionTypes';

import actions from '../redux/actions';

export const fetchTypologies = (storeAPI) => (next) => (action) => {
  if (action.type !== LOAD_TYPOLOGIES) return next(action);

  spacesApi
    .get('/spaces/filter/typology')
    .then((res) => {
      storeAPI.dispatch(actions.filterActions.setTypologies(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};
