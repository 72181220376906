import React, { Suspense, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux';

import * as Cookies from 'js-cookie';

import { spacesApi } from '../api';
import actions from '../redux/actions';

import useLocalStorage from '../hooks/useLocalStorage';
import CookieBanner from './cookie-banner/CookieBanner';
import Drawer from './drawer/Drawer';
import Map from './map/Map';
import Overlay from './overlay/Overlay';
import Tile from './tile/Tile';
import ViewSwitch from './view-switch/ViewSwitch';
import {preFetch} from "../gtm/gtm";
import {sendDataLayerEvent} from "../gtm/datalayerSendEvent";
import i18next from "i18next";

const App = () => {
  const dispatch = useDispatch();

  const [localStorageTileView, setLocalStorageTileView] = useLocalStorage('tileViewEnabled', false);
  const tileViewEnabled = useSelector((state) => state.user.tileViewEnabled);
  const i18nextLng = localStorage.getItem('i18nextLng');

  const autoLogin = async () => {
    const token = Cookies.get('token');
    const avatar = localStorage.getItem('avatar');
    const lastNotification = localStorage.getItem('lastNotification');

    if (!lastNotification) {
      localStorage.setItem('lastNotification', new Date().toLocaleDateString('de-DE'));
    }

    if (null === token) return;

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    try {
      const response = await spacesApi.get('/login/auth', { headers });
      const { data } = response;

      if (true === data.valid) {
        dispatch(actions.userActions.setUser({ token, avatar }));
      } else {
        Cookies.remove('token');
        localStorage.removeItem('avatar');
      }
    } catch (error) {
      Cookies.remove('token');
      localStorage.removeItem('avatar');
    }
  };

  const loadTileViewMode = async () => {
    if (null === tileViewEnabled) {
      dispatch(actions.userActions.setTileViewEnabled(localStorageTileView));
    }
  };

  const loadTypologies = async () => {
    dispatch(actions.filterActions.loadTypologies());
  };

  const loadProducts = async () => {
    dispatch(actions.filterActions.loadProducts());
  };

  const loadHistory = async () => {
    dispatch(actions.historyActions.loadHistory());
  };

  useEffect(() => {
    let hjid = 2325681;
    let hjsv = 6;

    if (i18nextLng === 'en') {
      hjid = 2987453;
    }

    hotjar.initialize(hjid, hjsv);

    preFetch(i18next.options.resources);
    sendDataLayerEvent('page_view')
  }, []);

  useEffect(() => {
    autoLogin();
    loadTileViewMode();
    loadTypologies();
    loadProducts();
    loadHistory();

    if (localStorage.getItem('darkMode') === 'true') {
      document.body.classList.add('dark');
    }
  });

  return (
    <Suspense fallback='loading'>
      <React.Fragment>
        {tileViewEnabled && <Tile />}
        {!tileViewEnabled && <Map />}
        <ViewSwitch />
        <Drawer />
        <Overlay />
        <CookieBanner />
      </React.Fragment>
    </Suspense>
  );
};

export default App;
