import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './filterItem.module.scss';

const FilterItem = ({ children, onChange, isChecked, emptyLabel }) => {
  const [checked, setChecked] = useState(undefined === isChecked ? false : isChecked);

  const onClick = (e) => {
    setChecked(e.target.checked);
    onChange(e.target.checked);
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <label
      className={classNames({
        [styles.filterItem]: !emptyLabel,
      })}
    >
      <div className={styles.checkbox}>
        <input type='checkbox' checked={checked} onChange={onClick} />
        <span />
      </div>
      <div
        className={classNames({
          [styles.labelChecked]: checked,
          [styles.label]: !emptyLabel,
        })}
      >
        {children}
      </div>
    </label>
  );
};

export default FilterItem;
