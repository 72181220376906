import React, { forwardRef } from 'react';
import styles from './gridItem.module.scss';
import ProjectTeaser from "../project-teaser/ProjectTeaser";
import ProjectFavorite from "../project-favorite/ProjectFavorite";
import {router} from "../../router";
import {useTranslation} from "react-i18next";


const GridItem = forwardRef(({ item, withOpacity, isDragging, style, fadeOutAndRemove, isChecked, setExport, ...props }, ref) => {
    const inlineStyles = {
        opacity: withOpacity ? '0.5' : '1',
        cursor: isDragging ? 'grabbing' : 'grab',
        // boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        ...style,
    };
    const [t] = useTranslation('translations');

    const navigateTo = (referenceId, slug) => {
        router.navigate(`/${t('navigation.reference')}/${referenceId}-${slug}.html`);
        window.scrollTo(0, 0);
    };


    return <div className={styles.gridItem} ref={ref} style={inlineStyles} {...props}>
        <ProjectFavorite
            key={item.id}
            onRemove={() => fadeOutAndRemove(item.id)}
        >
            <ProjectTeaser
                image={item.filenames[0]}
                title={item.title}
                product={item.product.tagValue}
                canExport={true}
                isChecked={isChecked}
                exportCheck={() => setExport(item.id)}
                onClick={() => navigateTo(item.id, item.slug)}
            />
        </ProjectFavorite>
    </div>;
});

export default GridItem;