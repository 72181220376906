import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './tooltip.module.scss';

const Tooltip = ({ children, text, className }) => {
  const [show, setShow] = useState(false);

  return (
    <div className={`${styles.tooltipWrapper} ${className}`}>
      <div className={styles.tooltip} style={show ? { visibility: 'visible', opacity: 1 } : {}}>
        {text}
      </div>
      <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </div>
    </div>
  );
};
export default withTranslation('translations')(Tooltip);
