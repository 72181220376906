import { SET_VIEWED_NOTIFICATION } from '../redux/actionTypes';

import actions from '../redux/actions';

export const setNotification = (storeAPI) => (next) => (action) => {
  if (action.type !== SET_VIEWED_NOTIFICATION) return next(action);

  const { viewedNotificationRefs } = action.payload;

  localStorage.setItem('viewedNotificationRefs', JSON.stringify(viewedNotificationRefs));

  /**
   * If we empty the id's then we also reset the last seen date to
   * today in order to basically have no more notifications
   */
  if (viewedNotificationRefs.length === 0) {
    localStorage.setItem('viewedNotificationRefs', JSON.stringify(viewedNotificationRefs));
    localStorage.setItem('lastNotification', new Date().toLocaleDateString('de-DE'));
    storeAPI.dispatch(
      actions.notificationActions.setLastNotification(new Date().toLocaleDateString('de-DE'))
    );
  }
  storeAPI.dispatch(actions.notificationActions.setNotifications(viewedNotificationRefs));
};
