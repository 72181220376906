import {
  CLOSE_DRAWER,
  LOADED_REFERENCE,
  LOAD_REFERENCE,
  OPEN_DETAIL_PAGE,
  SET_OVERLAY_VIEW_MODE,
} from '../actionTypes';

const initialState = {
  currentViewMode: null,
  currentDetailPageMarkerId: null,
  currentReference: null,
  closeDrawer: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_DRAWER: {
      return {
        ...state,
        closeDrawer: !state.closeDrawer,
      };
    }
    case LOAD_REFERENCE: {
      return {
        ...state,
        currentReference: null,
      };
    }
    case LOADED_REFERENCE: {
      return {
        ...state,
        currentReference: action.payload,
      };
    }
    case OPEN_DETAIL_PAGE: {
      const { id } = action.payload;
      return {
        ...state,
        currentDetailPageMarkerId: id === state.currentDetailPageMarkerId ? null : id,
      };
    }
    case SET_OVERLAY_VIEW_MODE: {
      const { viewMode } = action.payload;
      return {
        ...state,
        currentViewMode: viewMode,
      };
    }
    default:
      return state;
  }
};
