import {
  DISABLE_FAVORITE_LOADING_SPINNER,
  ENABLE_FAVORITE_LOADING_SPINNER,
  LOADED_FAVORITES,
  LOADED_MORE_REFERENCES,
  SET_LAST_NOTIFICATION,
  SET_TILE_VIEW_ENABLED,
  SET_USER,
  SET_VIEWED_NOTIFICATIONS,
} from '../actionTypes';

const initialState = {
  token: null,
  avatar: null,
  favorites: null,
  favoriteReferences: null,
  lastNotification:
    localStorage.getItem('lastNotification') || new Date().toLocaleDateString('de-DE'),
  viewedNotificationRefs: localStorage.getItem('viewedNotificationRefs')
    ? JSON.parse(localStorage.getItem('viewedNotificationRefs'))
    : [],
  addingToFavorites: false,
  tileViewEnabled: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_FAVORITE_LOADING_SPINNER: {
      return {
        ...state,
        addingToFavorites: true,
      };
    }
    case DISABLE_FAVORITE_LOADING_SPINNER: {
      return {
        ...state,
        addingToFavorites: false,
      };
    }
    case LOADED_FAVORITES: {
      const { favorites } = action.payload;
      return {
        ...state,
        favorites,
      };
    }
    case LOADED_MORE_REFERENCES: {
      const { favoriteReferences } = action.payload;
      let merged = null;
      if (state.favoriteReferences) {
        merged = state.favoriteReferences
          .concat(favoriteReferences)
          .reduce((accumulator, currentValue) => {
            if (!accumulator.find((item) => item.id === currentValue.id)) {
              accumulator.push(currentValue);
            }

            return accumulator;
          }, []);
      }
      return {
        ...state,
        favoriteReferences: merged ?? favoriteReferences,
      };
    }
    case SET_VIEWED_NOTIFICATIONS: {
      const { viewedNotificationRefs } = action.payload;
      return {
        ...state,
        viewedNotificationRefs,
      };
    }
    case SET_LAST_NOTIFICATION: {
      const { lastNotification } = action.payload;
      return {
        ...state,
        lastNotification,
      };
    }
    case SET_USER: {
      const { token, avatar } = action.payload.user;
      return {
        ...state,
        token,
        avatar,
      };
    }
    case SET_TILE_VIEW_ENABLED: {
      const { tileViewEnabled } = action.payload;
      return {
        ...state,
        tileViewEnabled,
      };
    }
    default:
      return state;
  }
};
