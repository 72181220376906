import { LOAD_SEARCHLOG, SET_SEARCHLOG_ITEMS } from '../actionTypes';

const loadSearchlog = () => ({
  type: LOAD_SEARCHLOG,
});

const setSearchlog = (searchlog) => ({
  type: SET_SEARCHLOG_ITEMS,
  payload: { searchlog },
});

export default {
  loadSearchlog,
  setSearchlog,
};
