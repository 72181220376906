let dataLayer = window.dataLayer || {};

export const sendDataLayerEvent = (eventName, eventAction, extra) => {

    const data = {
        'event': eventName,
        'event_action': eventAction ?? '',
        'page_type': window.pageData.page_type,
        'client_id': window.pageData.client_id,
        'reference_name': window.pageData.reference_name,
        'page_title': window.pageData.page_title,
        'language': window.pageData.language,
        ...extra
    };

    if(dataLayer.length) {
        dataLayer.push(data);
    }
}