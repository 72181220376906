import filterActions from './filterActions';
import historyActions from './historyActions';
import mapActions from './mapActions';
import notificationActions from './notificationActions';
import overlayActions from './overlayActions';
import referenceActions from './referencesActions';
import searchActions from './searchActions';
import searchLogActions from './searchLogActions';
import userActions from './userActions';

const actions = {
  filterActions,
  mapActions,
  overlayActions,
  searchActions,
  userActions,
  historyActions,
  searchLogActions,
  referenceActions,
  notificationActions,
};

export default actions;
