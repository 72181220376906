export const SET_VIEWPORT = 'SET_VIEWPORT';
export const FLY_TO = 'FLY_TO';

export const OPEN_DETAIL_PAGE = 'OPEN_DETAIL_PAGE';
export const SET_OVERLAY_VIEW_MODE = 'SET_OVERLAY_VIEW_MODE';

export const LOAD_INITIAL_MARKER = 'LOAD_INITIAL_MARKER';
export const LOADED_INITIAL_MARKER = 'LOADED_INITIAL_MARKER';

export const OPEN_MARKER = 'OPEN_MARKER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const LOAD_REFERENCE = 'LOAD_REFERENCE';
export const LOADED_REFERENCE = 'LOADED_REFERENCE';

export const SET_USER = 'SET_USER';

export const ENABLE_FAVORITE_LOADING_SPINNER = 'ENABLE_FAVORITE_LOADING_SPINNER';
export const DISABLE_FAVORITE_LOADING_SPINNER = 'DISABLE_FAVORITE_LOADING_SPINNER';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const GET_MORE_REFERENCES = 'GET_MORE_REFERENCES';
export const LOAD_FAVORITES = 'LOAD_FAVORITES';
export const LOADED_FAVORITES = 'LOADED_FAVORITES';
export const LOADED_MORE_REFERENCES = 'LOADED_MORE_REFERENCES';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const REORDER_FAVORITES = 'REORDER_FAVORITES';

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_ORIGINAL_RESULTS = 'SET_SEARCH_ORIGINAL_RESULTS';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const LOAD_TYPOLOGIES = 'LOAD_TYPOLOGIES';
export const SET_TYPOLOGIES = 'SET_TYPOLOGIES';

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';

export const SET_FILTER = 'SET_FILTER';
export const FILTER_MAP = 'FILTER_MAP';

export const COOKIES_ACCEPTED = 'COOKIES_ACCEPTED';

export const SET_TILE_VIEW_ENABLED = 'TILE_VIEW_ENABLED';

export const SET_HISTORY_ITEM = 'SET_HISTORY_ITEM';
export const LOAD_HISTORY = 'LOAD_HISTORY';
export const SET_HISTORY_ITEMS = 'SET_HISTORY_ITEMS';

export const LOAD_SEARCHLOG = 'LOAD_SEARCHLOG';
export const SET_SEARCHLOG_ITEMS = 'SET_SEARCHLOG_ITEMS';
export const SET_SEARCHLOG_ITEM = 'SET_SEARCHLOG_ITEM';

export const LOAD_SUBMITED_REFERENCES = 'LOAD_SUBMITED_REFERENCES';
export const SET_SUBMITED_REFERENCES_ITEMS = 'SET_SUBMITED_REFERENCES_ITEMS';

export const SET_VIEWED_NOTIFICATION = 'SET_VIEWED_NOTIFICATION';
export const SET_VIEWED_NOTIFICATIONS = 'SET_VIEWED_NOTIFICATIONS';
export const SET_LAST_NOTIFICATION = 'SET_LAST_NOTIFICATION';
