import React from 'react';

import classNames from 'classnames';

import styles from './textarea.module.scss';

const Textarea = ({ name, label, subLabel, onChange, placeholder, disabled, value, invalid }) => {
  return (
    <label className={styles.label} htmlFor={`input-${name}`}>
      {label}
      <span className={styles.labelSub}>{subLabel}</span>
      <textarea
        id={`input-${name}`}
        name={name}
        className={classNames(styles.field, {
          [styles.error]: invalid,
        })}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        defaultValue={value}
        rows={10}
      />
    </label>
  );
};

export default Textarea;
