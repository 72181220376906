import { SET_LAST_NOTIFICATION, SET_VIEWED_NOTIFICATIONS } from '../actionTypes';

const setNotifications = (viewedNotificationRefs) => ({
  type: SET_VIEWED_NOTIFICATIONS,
  payload: { viewedNotificationRefs },
});

const setLastNotification = (lastNotification) => ({
  type: SET_LAST_NOTIFICATION,
  payload: { lastNotification },
});

export default {
  setNotifications,
  setLastNotification,
};
