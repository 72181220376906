import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import { router } from '../../router';
import Button from '../button/Button';
import Headline from '../headline/Headline';
import ProjectTeaser from '../project-teaser/ProjectTeaser';
import Spinner from '../spinner/Spinner';
import styles from './overlayNotificationsPage.module.scss';

const OverlayNotificationsPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const lastNotification = useSelector((state) => state.user.lastNotification)
    ?.split('.')
    .reverse()
    .join('/');
  const viewedNotificationRefs = useSelector((state) => state.user.viewedNotificationRefs);
  const markerList = useSelector((state) => state.map.markerList);
  const [notificationMarkers, setNotificationMarkers] = useState([]);

  useEffect(() => {
    const lastNotificationDate = new Date(lastNotification);
    lastNotificationDate.setHours(23, 59, 59, 0);
    const markers = markerList.filter(
      (marker) =>
        new Date(marker.properties.date) > lastNotificationDate &&
        !viewedNotificationRefs.includes(marker.properties.id)
    );
    setNotificationMarkers(markers);
  }, [lastNotification, markerList, viewedNotificationRefs]);

  const markAllAsRead = () => {
    dispatch(actions.userActions.setViewedNotification([]));
  };

  const navigateTo = (referenceId, slug) => {
    viewedNotificationRefs.push(referenceId);
    if (notificationMarkers.length === 1) {
      dispatch(actions.userActions.setViewedNotification([]));
    } else {
      dispatch(actions.userActions.setViewedNotification(viewedNotificationRefs));
    }

    router.navigate(`/${t('navigation.reference')}/${referenceId}-${slug}.html`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.notifications}>
      <div className={styles.heading}>
        <Headline size='h1'>{t('notifications.headline')}</Headline>
        {notificationMarkers.length !== 0 && (
          <Button onClick={markAllAsRead} look='grey-10'>
            {t('notifications.mark_as_read')}
          </Button>
        )}
      </div>
      {loading && <Spinner />}
      {notificationMarkers.length !== 0 && (
        <div className={styles.results}>
          {notificationMarkers.map((item, index) => {
            return (
              <ProjectTeaser
                key={index}
                image={item.properties.file}
                title={item.properties.title}
                product={item.properties.product}
                date={item.properties.date}
                onClick={() => {
                  navigateTo(item.properties.id, item.properties.slug);
                }}
              />
            );
          })}
        </div>
      )}
      {notificationMarkers.length !== 0 && (
        <div className={styles.markRead}>
          <Button onClick={markAllAsRead} look='grey-10'>
            {t('notifications.mark_as_read')}
          </Button>
        </div>
      )}
      {notificationMarkers.length === 0 && (
        <div className={styles.noReferences}>
          <p>{t('notifications.no_references')}</p>
        </div>
      )}
    </div>
  );
};

export default withTranslation('translations')(OverlayNotificationsPage);
