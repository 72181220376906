const acceptedUTMParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

const fetchSearchParams = () => {
  const filteredParams = {};
  const urlSearchParams = new URLSearchParams(window.location.hash);
  const params = Object.fromEntries(urlSearchParams.entries());

  Object.keys(params).forEach((param, index) => {
    const clean = param.replace('#!/?', '');
    if (acceptedUTMParams.includes(clean)) {
      filteredParams[clean] = params[param];
    }
  });

  return filteredParams;
};

const initialState = {
  queryParams: fetchSearchParams(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
