import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import angleDownIcon from '../../assets/icons/angle-down.svg';
import angleRightIcon from '../../assets/icons/angle-right.svg';
import angleUpIcon from '../../assets/icons/angle-up.svg';
import historyIcon from '../../assets/icons/history.svg';
import trashIcon from '../../assets/icons/trash.svg';
import Button from '../button/Button';
import Headline from '../headline/Headline';
import Modal from '../modal/Modal';
import Spinner from '../spinner/Spinner';
import styles from './overlayHistoryPage.module.scss';

const OverlayHistoryPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const originalHistory = useSelector((state) => state.history);
  const [history] = useState(() => {
    const transformed = originalHistory.map((item) => {
      let date = item.visitDate.split('.');
      if (date.length === 3) {
        item.visitDate = `${date[1]}/${date[0]}/${date[2]}`;
      }

      return item;
    });

    return transformed
      .slice()
      .sort((a, b) => new Date(a.visitDate) - new Date(b.visitDate))
      .reverse();
  });
  const products = useSelector((state) => state.map.markerList);
  const [expanded, setExpanded] = useState([0]);
  const locale = localStorage.getItem('i18nextLng');
  const today = new Date().toLocaleDateString();
  const [showModal, setShowModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(t('history.today'));
  const [selectedDelete, setSelectedDelete] = useState(history.length ? history[0].visitDate : '');
  const [showSelectBox, setShowSelectBox] = useState(false);

  const removeItem = (index, id) => {
    setLoading(true);
    const itemIndex = history[index].visited.indexOf(id);
    if (index !== -1) {
      history[index].visited.splice(itemIndex, 1);
      dispatch(actions.userActions.setHistoryItem(history));
    }
    setLoading(false);
  };

  const toggleExpand = (index) => {
    const expArray = JSON.parse(JSON.stringify(expanded));

    if(expArray.includes(index)) {
      const expIndex = expArray.findIndex((item) => item === index);
      expArray.splice(expIndex, 1);
    } else {
      expArray.push(index);
    }

    setExpanded(expArray);
  }

  const selectDate = (e, index) => {
    e.stopPropagation();
    const date = history[index];
    const visitDate = new Date(date.visitDate);
    const month = visitDate.toLocaleString(
      `${locale}-${locale === 'de' ? locale.toUpperCase() : 'US'}`,
      { month: 'long' }
    );
    setSelectedDeleteItem(
      date.visitDate === today
        ? t('history.today')
        : `${visitDate.getDate()}. ${month} ${visitDate.getFullYear()}`
    );
    setShowSelectBox(false);
    setSelectedDelete(date.visitDate);
  };

  const deleteDate = () => {
    const toDelete = history.findIndex((element) => element.visitDate === selectedDelete);

    history.splice(toDelete, 1);
    setShowModal(false);
    dispatch(actions.userActions.setHistoryItem(history));
  };

  return (
    <div className={styles.history}>
      <div className={styles.heading}>
        <Headline size='h1'>{t('history.headline')}</Headline>
        <Button onClick={() => setShowModal(true)} look='grey-10'>
          {t('history.removeMultiple')}
        </Button>
      </div>
      {loading && <Spinner />}
      {history.length !== 0 && (
        <div className={styles.results}>
          {history.map((item, index) => {
            const visitDate = new Date(item.visitDate);
            const month = visitDate.toLocaleString(
              `${locale}-${locale === 'de' ? locale.toUpperCase() : 'US'}`,
              { month: 'long' }
            );
            return (
              <div key={index} className={styles.dayWrapper}>
                <div className={styles.leftBar}>
                  {expanded.includes(index)  ? (
                    <SVG onClick={() => toggleExpand(index)} className={styles.expanded} src={angleDownIcon} />
                  ) : (
                    <SVG onClick={() => toggleExpand(index)} src={angleRightIcon} />
                  )}
                  <div className={styles.greyBar}></div>
                </div>

                <div className={styles.content}>
                  <div
                    onClick={() => toggleExpand(index)}
                    className={classNames({ [styles.smallTitle]: expanded !== index })}
                  >
                    <div className={styles.day}>
                      <span>
                        {item.visitDate === today
                          ? t('history.today')
                          : `${visitDate.getDate()}. ${month} ${visitDate.getFullYear()}`}
                      </span>
                    </div>
                    <div className={styles.objects}>
                      <span>
                        {item.visited.length} {t('history.objects')}
                      </span>
                    </div>
                  </div>
                  {expanded.includes(index) && (
                    <div className={styles.items}>
                      {item.visited.map((visitedId, key) => {
                        const product = products.find(
                          (product) => product.properties.id === visitedId
                        );
                        if (product) {
                          return (
                            <div key={key} className={styles.item}>
                              <img
                                alt={''}
                                className={styles.image}
                                src={product.properties.file}
                              />
                              <div className={styles.titleAction}>
                                <h2 className={styles.title}>{product.properties.title}</h2>
                                <a
                                  className={styles.trash}
                                  onClick={() => removeItem(index, visitedId)}
                                >
                                  <SVG src={trashIcon} />
                                </a>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <Modal
        onClose={() => setShowModal(false)}
        showModal={showModal}
        black={true}
        hideClose={true}
      >
        <div className={styles.deleteContent}>
          <span className={styles.modalTitle}>
            <SVG className={styles.expanded} src={historyIcon} />
            <span>{t('history.removeAll')}</span>
          </span>
          <div className={styles.deleteWrapper}>
            <span className={styles.deleteLabel}>{t('history.delete')}</span>
            <div className={styles.deleteBox} onClick={() => setShowSelectBox(!showSelectBox)}>
              <span className={styles.selectedItem}>
                {selectedDeleteItem}
                <span className={styles.icons}>
                  <SVG src={angleUpIcon} />
                  <SVG src={angleDownIcon} />
                </span>
              </span>
              <div className={classNames(styles.deleteSelect, { [styles.hidden]: !showSelectBox })}>
                {history.map((item, index) => {
                  const visitDate = new Date(item.visitDate);
                  const month = visitDate.toLocaleString(
                    `${locale}-${locale === 'de' ? locale.toUpperCase() : 'US'}`,
                    { month: 'long' }
                  );
                  return (
                    <span
                      key={index}
                      className={classNames(styles.date, {
                        [styles.selected]:
                          selectedDeleteItem ===
                          `${visitDate.getDate()}. ${month} ${visitDate.getFullYear()}`,
                      })}
                      onClick={(e) => selectDate(e, index)}
                    >
                      {item.visitDate === today
                        ? t('history.today')
                        : `${visitDate.getDate()}. ${month} ${visitDate.getFullYear()}`}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.actionButtons}>
            <Button onClick={() => setShowModal(false)} look='transparent'>
              {t('history.cancel')}
            </Button>
            <Button onClick={deleteDate} look='transparent'>
              {t('history.delete')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation('translations')(OverlayHistoryPage);
