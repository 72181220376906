import { SET_SUBMITED_REFERENCES_ITEMS } from '../actionTypes';

const initialState = {
  page: 1,
  direction: 'DESC',
  sortBy: 'createdAt',
  status: '',
  pageCount: 1,
  totalItems: 0,
  references: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBMITED_REFERENCES_ITEMS: {
      return action.payload.submitedReferences;
    }
    default:
      return state;
  }
};
