import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import * as Cookies from 'js-cookie';
import * as fileDownload from 'js-file-download';

import styles from './techdocs.module.scss';

import angleLeftIcon from '../../assets/icons/angle-left.svg';
import downloadIcon from '../../assets/icons/file-download.svg';

import IconLink from '../icon-link/IconLink';

import SVG from 'react-inlinesvg';
import { spacesApi } from '../../api';
import LoginForm from '../login-form/LoginForm';

const Techdocs = () => {
  const currentUserToken = useSelector((state) => state.user.token);
  const currentReference = useSelector((state) => state.overlay.currentReference);

  const [documents, setDocuments] = useState({
    items: [],
    currentPage: 1,
    itemsPerPage: 10,
  });
  const [loadingDocument, setLoadingDocument] = useState(false);

  const [t] = useTranslation('translations');

  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    const headers = {
      Authorization: `Bearer ${Cookies.get('token')}`,
    };

    const promises = [];
    currentReference.system.map((system) => {
      const params = {
        language:
          undefined === localStorage.getItem('i18nextLng')
            ? 'de'
            : localStorage.getItem('i18nextLng'),
        systems: system.tagValue,
      };

      promises.push(
        spacesApi
          .get('/documents', { headers, params })
          .then((res) => {
            const items = documents.items;
            items.push(...res.data);
            setDocuments({
              ...documents,
              items,
            });
          })
          .catch((err) => {
            console.log(err);
          })
      );
    });

    Promise.all(promises).then(() => {
      setDocuments({
        ...documents,
        items: documents.items.map((item, index) => {
          item.originalIndex = index;

          return item;
        }),
      });
    });
  }, [currentReference.system, currentUserToken]);

  const requestDocument = (index) => {
    if (loadingDocument) {
      return;
    }

    setLoadingDocument(true);
    let requestDocument = documents.items[index];

    const headers = {
      Authorization: `Bearer ${Cookies.get('token')}`,
    };

    const params = {
      id: requestDocument.id,
      filename: requestDocument.file,
      id: requestDocument.id,
    };

    spacesApi
      .get('/documents/download', { headers, responseType: 'blob', params })
      .then((res) => {
        fileDownload(res.data, requestDocument.file);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoadingDocument(false);
      });
  };

  const totalPages = () => {
    return Math.ceil(documents.items.length / documents.itemsPerPage);
  };

  const nextPage = () => {
    const { currentPage } = documents;

    setDocuments({
      ...documents,
      currentPage: currentPage === totalPages() ? 1 : currentPage + 1,
    });
  };

  const previousPage = () => {
    const { currentPage } = documents;

    setDocuments({
      ...documents,
      currentPage: currentPage === 1 ? totalPages() : currentPage - 1,
    });
  };

  const renderDocuments = () => {
    const { items, currentPage, itemsPerPage } = documents;

    const indexOfLastDocument = currentPage * itemsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - itemsPerPage;

    return items.slice(indexOfFirstDocument, indexOfLastDocument);
  };

  return (
    <div className={styles.techdocs}>
      {null === currentUserToken && (
        <div className={styles.login}>
          <LoginForm />
        </div>
      )}
      {null !== currentUserToken && (
        <div
          className={classNames(styles.techdocsList, {
            [styles.loading]: loadingDocument,
          })}
        >
          {renderDocuments().map((document, index) => {
            return (
              <IconLink
                key={index}
                icon={downloadIcon}
                label={document.title}
                onClick={() => requestDocument(document.originalIndex)}
              />
            );
          })}

          {0 !== documents.items.length && 1 < totalPages() && (
            <div className={styles.pagination}>
              <div className={styles.iconLeft}>
                <SVG onClick={previousPage} src={angleLeftIcon} />
              </div>
              <div className={styles.page}>
                {documents.currentPage} / {totalPages()}
              </div>
              <div className={styles.iconRight}>
                <SVG onClick={nextPage} src={angleLeftIcon} />
              </div>
            </div>
          )}

          {0 === documents.items.length && (
            <div className={styles.noFiles}>{t('detail.no_techdocs')}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation('translations')(Techdocs);
