import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import * as Cookies from 'js-cookie';

import { spacesApi } from '../../api';
import actions from '../../redux/actions';

import { faLockAlt, faUser } from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../button/Button';
import Field from '../form/Field';
import styles from './loginForm.module.scss';

const LoginForm = () => {
  const [t] = useTranslation('translations');

  const dispatch = useDispatch();

  const [loginFailed, setLoginFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setInvalidUsername('' === username);
    setInvalidPassword('' === password);

    if ('' === username || '' === password) return;

    setIsLoading(true);

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const response = await spacesApi.post('/login', formData, config);
      const { data } = response;

      Cookies.set('token', data.token, { expires: 7 });
      localStorage.setItem('avatar', data.avatar);
      dispatch(actions.userActions.setUser(data));
    } catch (error) {
      setIsLoading(false);
      setLoginFailed(true);

      setPassword('');
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      {loginFailed && <span>{t('login.failed')}</span>}
      <div className={styles.iconGroup}>
        <FontAwesomeIcon icon={faUser} />
        <Field
          type='text'
          placeholder={t('login.email')}
          disabled={isLoading}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          invalid={invalidUsername}
        />
      </div>
      <div className={styles.iconGroup}>
        <FontAwesomeIcon icon={faLockAlt} />
        <Field
          type='password'
          placeholder={t('login.password')}
          disabled={isLoading}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          invalid={invalidPassword}
        />
      </div>
      <div className={styles.signIn}>
        <a
          rel='noopener noreferrer'
          target='_blank'
          className={styles.resetPassword}
          href={t('login.resetLink')}
        >
          {t('login.reset')}
        </a>
        <div className={styles.submit}>
          <Button disabled={isLoading} size='medium' type='submit' look='primary-red'>
            {!isLoading ? t('login.signin') : <FontAwesomeIcon icon={faSpinnerThird} spin />}
          </Button>
        </div>
      </div>
      <div className={styles.register}>
        <p>{t('login.noAccount')}</p>
        <Button
          size='medium'
          look='grey-10'
          onClick={() =>
            (window.location.href = 'https://my.solarlux.com/apps/users/#/user-registration')
          }
        >
          {t('login.register')}
        </Button>
      </div>
    </form>
  );
};

export default withTranslation('translations')(LoginForm);
