import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import linkIcon from '../../assets/icons/external-link.svg';
import IconLink from '../icon-link/IconLink';
import {sendDataLayerEvent} from "../../gtm/datalayerSendEvent";

const MAPPING = {
  de: {
    15485: 'https://solarlux.com/de-de/produkte/balkonverglasung.html',
    22357: 'https://solarlux.com/de-de/produkte/cero-schiebefenster.html',
    15479: 'https://solarlux.com/de-de/produkte/glas-faltwand.html',
    15497: 'https://solarlux.com/de-de/produkte/horizontal-schiebe-wand.html',
    15519: 'https://solarlux.com/de-de/produkte/schiebetuer.html',
    15494: 'https://solarlux.com/de-de/produkte/schiebetuer.html',
    15489: 'https://solarlux.com/de-de/produkte/terrassenueberdachung.html',
    15476: 'https://solarlux.com/de-de/produkte/wintergarten.html',
    23191: 'https://solarlux.com/de-de/systeme/balkon-verglasung-sl25.html',
    23197: 'https://solarlux.com/de-de/systeme/balkon-sl25xxl-verkleidung.html',
    23215: 'https://solarlux.com/de-de/systeme/balkon-glasschiebetueren-sl25r.html',
    23200: 'https://solarlux.com/de-de/systeme/glas-falttueren-schiebetueren-.html',
    23205: 'https://solarlux.com/de-de/systeme/glaswand-vorhangfassade.html',
    24054: 'https://solarlux.com/de-de/produkte/cero-schiebefenster.html',
    24052: 'https://solarlux.com/de-de/systeme/schiebefenster-cero-2.html',
    24053: 'https://solarlux.com/de-de/systeme/schiebefenster-cero-3.html',
    27932: 'https://solarlux.com/de-de/systeme/faltwand-glas-ecoline.html',
    27931: 'https://solarlux.com/de-de/systeme/glas-faltwand-highline.html',
    23210: 'https://solarlux.com/de-de/systeme/wintergarten-sdl-akzent-plus.html',
    23217: 'https://solarlux.com/de-de/systeme/terrassenueberdachung-sdl-atri.html',
    23194: 'https://solarlux.com/de-de/systeme/glas-falttueren-schiebetueren.html',
    23193: 'https://solarlux.com/de-de/systeme/raumteiler-glas-schiebetuer-sl.html',
    23189: 'https://solarlux.com/de-de/systeme/faltwand-glas-ecoline.html',
    23203: 'https://solarlux.com/de-de/systeme/glas-faltwand-holz-woodline.html',
    23198: 'https://solarlux.com/de-de/systeme/glas-faltwand-holz-woodline.html',
    23204: 'https://solarlux.com/de-de/systeme/glas-faltwand-holz-aluminium.html',
    23201: 'https://solarlux.com/de-de/systeme/glas-faltwand-highline.html',
    23199: 'https://solarlux.com/de-de/systeme/glas-faltwand-highline.html',
    23195: 'https://solarlux.com/de-de/systeme/glas-faltwand-highline.html',
    23192: 'https://solarlux.com/de-de/systeme/falttueren-fenster-sl82-alu.html',
    23211: 'https://solarlux.com/de-de/systeme/holz-aluminium-faltwand-sl-97.html',
    23230: 'https://solarlux.com/de-de/systeme/raumteiler-glas-schiebetuer-sl.html',
    33145: 'https://solarlux.com/de-de/systeme/terrassendach-sdl-acubis.html',
    23196: 'https://solarlux.com/de-de/systeme/wintergarten-terrasse.html',
    23209: 'https://solarlux.com/de-de/systeme/terrassen-ueberdachung-sdlaura.html',
    23223: 'https://solarlux.com/de-de/systeme/glas-schiebetueren-sl-20e.html',
    23224: 'https://solarlux.com/de-de/systeme/glas-schiebetuer-sl22.html',
    33569: 'https://solarlux.com/de-de/systeme/schiebetuer-system-sl23.html',
    23226: 'https://solarlux.com/de-de/systeme/glasschiebetueren-sl-142.html',
    23202: 'https://solarlux.com/de-de/systeme/holz-wintergarten-balkon-haus.html',
    23213: 'https://solarlux.com/de-de/systeme/wintergarten-glasdach-nobiles.html',
    23216: 'https://solarlux.com/de-de/systeme/raumteiler-glas-schiebetuer-sl.html',
    33143: 'https://solarlux.com/de-de/systeme/glas-faltwand-holz-woodline.html',
    23208: 'https://solarlux.com/de-de/systeme/glas-schiebetueren-sl-20e.html',
    23214: 'https://solarlux.com/de-de/systeme/raumteiler-glas-schiebetuer-sl.html',
    23225: 'https://solarlux.com/de-de/systeme/ganzglas-schiebetuer-sl-160.html',
    23207: 'https://solarlux.com/de-de/systeme/terrassendach-aluminium-anova.html',
  },
  en: {
    15485: 'https://solarlux.com/en/products/balcony-glazing.html',
    22357: 'https://solarlux.com/en/products/cero-sliding-window.html',
    15479: 'https://solarlux.com/en/products/bi-folding-doors.html',
    15497: 'https://solarlux.com/en/products/horizontal-sliding-walls.html',
    15519: 'https://solarlux.com/en/products/sliding-doors.html',
    15494: 'https://solarlux.com/en/products/sliding-doors.html',
    15489: 'https://solarlux.com/en/products/glass-canopies.html',
    15476: 'https://solarlux.com/en/products/wintergardens.html',
    23191: 'https://solarlux.com/en/systems/balcony-glazing-sl-25.html',
    23197: 'https://solarlux.com/en/systems/balcony-glazing-sl-25xxl.html',
    23215: 'https://solarlux.com/en/systems/balcony-glazing-sl-25r.html',
    23200: 'https://solarlux.com/en/systems/sl-45.html',
    23205: 'https://solarlux.com/en/systems/facade-solutions-curtain-walls.html',
    24054: 'https://solarlux.com/en/products/cero-sliding-window.html',
    24052: 'https://solarlux.com/en/systems/cero-ii.html',
    24053: 'https://solarlux.com/en/systems/cero-iii.html',
    27932: 'https://solarlux.com/en/systems/ecoline.html',
    27931: 'https://solarlux.com/en/systems/highline.html',
    23210: 'https://solarlux.com/en/systems/sdl-akzent-plus.html',
    23217: 'https://solarlux.com/en/systems/sdl-atrium-plus.html',
    23194: 'https://solarlux.com/en/systems/sl-35.html',
    23193: 'https://solarlux.com/en/systems/horizontally-sliding-wall-sl60.html',
    23189: 'https://solarlux.com/en/systems/ecoline.html',
    23203: 'https://solarlux.com/en/systems/woodline.html',
    23198: 'https://solarlux.com/en/systems/woodline.html',
    23204: 'https://solarlux.com/en/systems/combiline.html',
    23201: 'https://solarlux.com/en/systems/highline.html',
    23199: 'https://solarlux.com/en/systems/highline.html',
    23195: 'https://solarlux.com/en/systems/highline.html',
    23192: 'https://solarlux.com/en/systems/sl-82.html',
    23211: 'https://solarlux.com/en/systems/sl-97.html',
    23230: 'https://solarlux.com/en/systems/horizontally-sliding-wall-sl60.html',
    33145: 'https://solarlux.com/en/systems/sdl-acubis.html',
    23196: 'https://solarlux.com/en/systems/sdl-akzent-vision.html',
    23209: 'https://solarlux.com/en/systems/sdl-aura.html',
    23223: 'https://solarlux.com/en/systems/sl-20e.html',
    23224: 'https://solarlux.com/en/systems/sl-22.html',
    33569: 'https://solarlux.com/en/systems/sl-23.html',
    23226: 'https://solarlux.com/en/systems/sl-142.html',
    23202: 'https://solarlux.com/en/systems/sdl-avantgarde.html',
    23213: 'https://solarlux.com/en/systems/sdl-nobiles.html',
    23216: 'https://solarlux.com/en/systems/horizontally-sliding-wall-sl60.html',
    33143: 'https://solarlux.com/en/systems/woodline.html',
    23208: 'https://solarlux.com/en/systems/sl-20e.html',
    23214: 'https://solarlux.com/en/systems/horizontally-sliding-wall-sl60.html',
    23225: 'https://solarlux.com/en/systems/sl-160.html',
    23207: 'https://solarlux.com/en/systems/sdl-anova.html',
  },
};

const openExternalPage = (url) => {
  window.open(url, '_blank');
};

const getUrlByTag = (tagId, queryParams) => {
  const locale = localStorage.getItem('i18nextLng');
  const url = MAPPING[locale][tagId];

  let params = '';
  if (Object.keys(queryParams).length) {
    Object.keys(queryParams).forEach((param) => (params += param + '=' + queryParams[param]));
  }
  params = params ? '?' + params : '';

  return undefined === url ? 'https://solarlux.com' + params : url + params;
};

const ProductAndSystemLinks = ({ productTag, systemTags }) => {
  const [t] = useTranslation('translations');
  const queryParams = useSelector((state) => state.defaultdata.queryParams);

  const systemClick = () => {
    sendDataLayerEvent('cta_click', '', {link_name: `${t('detail.productAndSystemLink')} ${productTag.tagValue}`})
    openExternalPage(getUrlByTag(productTag.tagId, queryParams));
  }

  return (
    <React.Fragment>
      <IconLink
        onClick={systemClick}
        icon={linkIcon}
        label={`${t('detail.productAndSystemLink')} ${productTag.tagValue}`}
      />
      {systemTags.map((systemTag, index) => {
        return (
          <IconLink
            key={index}
            onClick={() => {
              openExternalPage(getUrlByTag(systemTag.tagId, queryParams));
            }}
            icon={linkIcon}
            label={`${t('detail.productAndSystemLink')} ${systemTag.tagValue}`}
          />
        );
      })}
    </React.Fragment>
  );
};

export default withTranslation('translations')(ProductAndSystemLinks);
