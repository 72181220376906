import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './tile.module.scss';

import ProjectTeaser from '../project-teaser/ProjectTeaser';
import Spinner from '../spinner/Spinner';

import { useTranslation, withTranslation } from 'react-i18next';
import { router } from '../../router';
import actions from "../../redux/actions";
import classNames from "classnames";
import timesIcon from "../../assets/icons/times.svg";
import SVG from "react-inlinesvg";
import {MARKER_TYPES} from "../marker/Marker";

const Tile = () => {
  const [t] = useTranslation('translations');

  const [sliceOffset, setSliceOffset] = useState(1);
  const [slicedPoints, setSlicedPoints] = useState([]);
  const currentFilters = useSelector((state) => state.filter);
  const dispatch = useDispatch();


  const removeTypologyFilter = (typology) => {
    const typologies = currentFilters.options.typologies.filter((item) => item !== typology);
    const products = [];

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const removeProductFilter = (product) => {
    const typologies = [];
    const products = currentFilters.options.products.filter((item) => item !== product);

    const options = { typologies, products };
    dispatch(actions.filterActions.setFilter(options));
    dispatch(actions.mapActions.filterMap(options));
  };

  const points = useSelector((state) => state.map.markerList);

  const addMoreSlices = () => {
    setSlicedPoints(
      slicedPoints.concat(
        points.slice(16 * sliceOffset, 16 * (sliceOffset + 1)).map((item) => item)
      )
    );
    setSliceOffset(sliceOffset + 1);
  };

  useEffect(() => {
    setSliceOffset(1);
    setSlicedPoints(points.slice(0, 16));
    document.getElementById('tiles').scrollTo(0, 0);
  }, [points]);

  const navigateTo = (referenceId, title) => {
    router.navigate(`/${t('navigation.reference')}/${referenceId}-${title}.html`);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div id='tiles' className={styles.tiles} style={{ height: '100%' }}>
        <div className={styles.filters}>
          {currentFilters.options.products.length ? (
              <>
                {currentFilters.options.products.map((product, index) => {
                  const prod = currentFilters.products.find((item) => item.tagId === product);
                  if (!prod) return <></>;

                  return (
                      <span key={index}  className={classNames(styles.filter, {
                        [styles.balcony]: prod.tagId === 15485,
                        [styles.cero]: prod.tagId === 22357,
                        [styles.bifolding]: prod.tagId === 15479,
                        [styles.hsd]: prod.tagId === 15497,
                        [styles.sliding]: prod.tagId === 15494 ||  prod.tagId === 15519,
                        [styles.roof]: prod.tagId === 15523 || prod.tagId === 15489,
                        [styles.wintergarten]: prod.tagId === 15476,

                      })}>
                  {prod.name}
                        <SVG
                            className={classNames(styles.closeIcon)}
                            onClick={() => removeProductFilter(product)}
                            src={timesIcon}
                        />
                </span>
                  );
                })}
              </>
          ) : (<></>)}

          {currentFilters.options.typologies.length ? (
              <>
                {currentFilters.options.typologies.map((typology, index) => {
                  const typ = currentFilters.typologies.find((item) => item.tagId === typology);

                  if (!typ) return <></>;
                  return (
                      <span key={index}
                            className={classNames(styles.filter, {
                              [styles.facade]: MARKER_TYPES.FACADE === typ.type,
                              [styles.apartment]: MARKER_TYPES.APARTMENT_BUILDING === typ.type,
                              [styles.office]: MARKER_TYPES.OFFICE_AND_ADMINISTRATION === typ.type,
                              [styles.commercial]: MARKER_TYPES.COMMERCIAL_AND_INDUSTRIAL === typ.type,
                              [styles.stadiums]: MARKER_TYPES.STADIUMS_AND_ARENAS === typ.type,
                              [styles.wellness]: MARKER_TYPES.WELLNESS_AND_FITNESS === typ.type,
                              [styles.living]: MARKER_TYPES.LIVING_AND_GARDEN === typ.type,
                              [styles.education]: MARKER_TYPES.EDUCATION_AND_SCIENCE === typ.type,
                              [styles.hotels]: MARKER_TYPES.HOTELS_AND_GASTRONOMY === typ.type,
                              [styles.health]: MARKER_TYPES.HEALTH_AND_CARE === typ.type,
                              [styles.culture]: MARKER_TYPES.CULTURE_AND_SACRED_BUILDINGS === typ.type,
                              [styles.other]: MARKER_TYPES.OTHER === typ.type,
                            })}
                      >
                  {typ.text}
                        <SVG
                            className={classNames(styles.closeIcon)}
                            onClick={() => removeTypologyFilter(typology)}
                            src={timesIcon}
                        />
                </span>
                  );
                })}
              </>
          ) : (<></>)}
        </div>
        <InfiniteScroll
          scrollableTarget='tiles'
          next={addMoreSlices}
          hasMore={slicedPoints.length !== points.length}
          loader={
            <div>
              <Spinner />
            </div>
          }
          dataLength={slicedPoints.length}
        >
          {slicedPoints.length !== 0 &&
            slicedPoints.map((item, index) => {
              const { id, file, title, product, slug, date } = item.properties;

              return (
                <ProjectTeaser
                  key={index}
                  image={file}
                  title={title}
                  date={date}
                  //isTopReference={item.isTopReference}
                  product={product}
                  onClick={() => navigateTo(id, slug)}
                />
              );
            })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default withTranslation('translations')(Tile);
