import { SET_SEARCH_ORIGINAL_RESULTS, SET_SEARCH_RESULTS, SET_SEARCH_TERM } from '../actionTypes';

const initialState = {
  term: '',
  results: null,
  original_results: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS: {
      const { results } = action.payload;
      return {
        ...state,
        results,
      };
    }
    case SET_SEARCH_ORIGINAL_RESULTS: {
      const { original_results } = action.payload;
      return {
        ...state,
        original_results,
      };
    }
    case SET_SEARCH_TERM: {
      const { term } = action.payload;
      return {
        ...state,
        term,
      };
    }
    default:
      return state;
  }
};
