import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';

import classNames from 'classnames';
import Cookies from 'js-cookie';

import styles from './cookieBanner.module.scss';

import { useTranslation } from 'react-i18next';
import Button from '../button/Button';

const CookieBanner = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();

  const [closed, setClosed] = useState(true);
  const [flipped, setFlipped] = useState(false);

  const [marketing, setMarketing] = useState(false);
  const [preferences, setPreferences] = useState(false);
  const [statistics, setStatistics] = useState(false);

  useEffect(() => {
    const marketing = Cookies.get('spaces_marketing');
    const preferences = Cookies.get('spaces_preferences');
    const statistics = Cookies.get('spaces_statistics');

    if (undefined === marketing || undefined === preferences || undefined === statistics) {
      deleteCookies();
      setClosed(false);
    } else {
      dispatch(actions.mapActions.cookiesAccepted());
    }
  }, []);

  const acceptAll = () => {
    window.dataLayer.push({ event: 'AllowMarketingCookies' });
    window.dataLayer.push({ event: 'AllowPreferencesCookies' });
    window.dataLayer.push({ event: 'AllowStatisticsCookies' });

    Cookies.set('spaces_marketing', true, { expires: 365 });
    Cookies.set('spaces_preferences', true, { expires: 365 });
    Cookies.set('spaces_statistics', true, { expires: 365 });

    dispatch(actions.mapActions.cookiesAccepted());
    setClosed(true);
  };

  const saveSelection = () => {
    if (marketing) {
      window.dataLayer.push({ event: 'AllowMarketingCookies' });
    }

    if (preferences) {
      window.dataLayer.push({ event: 'AllowPreferencesCookies' });
    }

    if (statistics) {
      window.dataLayer.push({ event: 'AllowStatisticsCookies' });
    }

    Cookies.set('spaces_marketing', marketing, { expires: 365 });
    Cookies.set('spaces_preferences', preferences, { expires: 365 });
    Cookies.set('spaces_statistics', statistics, { expires: 365 });

    dispatch(actions.mapActions.cookiesAccepted());
    setClosed(true);
  };

  const declineAll = () => {
    if (preferences) {
      window.dataLayer.push({ event: 'AllowPreferencesCookies' });
    }

    Cookies.set('spaces_marketing', marketing, { expires: 365 });
    Cookies.set('spaces_preferences', preferences, { expires: 365 });
    Cookies.set('spaces_statistics', statistics, { expires: 365 });

    dispatch(actions.mapActions.cookiesAccepted());
    setClosed(true);
  };

  const deleteCookies = () => {
    const allCookies = document.cookie.split(';');
    allCookies.map((singleCookie) => {
      document.cookie = `${singleCookie}=;expires=${new Date(0).toUTCString()}`;
    });
  };

  return (
    <div
      className={classNames(styles.bg, {
        [styles.hidden]: closed,
      })}
    >
      <div className={styles.wrapper}>
        {!flipped && (
          <React.Fragment>
            <div className={styles.title}>{t('cookies.headline')}</div>
            <div className={styles.text}>{t('cookies.text')}</div>

            <div className={styles.button}>
              <Button look='primary-red' onClick={() => acceptAll()}>
                {t('cookies.accept')}
              </Button>
            </div>

            <div className={styles.button}>
              <Button look='red-border' onClick={() => declineAll()}>
                {t('cookies.decline')}
              </Button>
            </div>

            <div className={styles.footer}>
              <div className={styles.settings}>
                <span onClick={() => setFlipped(true)}>{t('cookies.manage')}</span>
              </div>
              <div className={styles.links}>
                <a target='_blank' href={t('drawer.settings.imprintLink')}>
                  {t('drawer.settings.imprint')}
                </a>
                <a target='_blank' href={t('drawer.settings.datapolicyLink')}>
                  {t('drawer.settings.datapolicy')}
                </a>
              </div>
            </div>
          </React.Fragment>
        )}

        {flipped && (
          <React.Fragment>
            <div className={styles.title}>{t('cookies.manage')}</div>

            <div className={styles.text}>
              <div className={styles.checkbox} onClick={() => setStatistics(!statistics)}>
                <input
                  type='checkbox'
                  checked={statistics}
                  onChange={(e) => setStatistics(e.target.checked)}
                />
                <label />
                <div>
                  <b>{t('cookies.statistics')}</b>
                  <br />
                  {t('cookies.statisticsText')}
                </div>
              </div>

              <div className={styles.checkbox} onClick={() => setMarketing(!marketing)}>
                <input
                  type='checkbox'
                  checked={marketing}
                  onChange={(e) => setMarketing(e.target.checked)}
                />
                <label />
                <div>
                  <b>{t('cookies.marketing')}</b>
                  <br />
                  {t('cookies.marketingText')}
                </div>
              </div>

              <div className={styles.checkbox} onClick={() => setPreferences(!preferences)}>
                <input
                  type='checkbox'
                  checked={preferences}
                  onChange={(e) => setPreferences(e.target.checked)}
                />
                <label />
                <div>
                  <b>{t('cookies.preferences')}</b>
                  <br />
                  {t('cookies.preferencesText')}
                </div>
              </div>

              <div className={styles.checkbox}>
                <input type='checkbox' checked={true} disabled={true} />
                <label />
                <div>
                  <b>{t('cookies.essential')}</b>
                  <br />
                  {t('cookies.essentialText')}
                </div>
              </div>
            </div>

            <div className={styles.button}>
              <Button look='primary-red' onClick={() => acceptAll()}>
                {t('cookies.accept')}
              </Button>
            </div>

            <div className={styles.footer}>
              <div className={styles.settings}>
                <span onClick={() => saveSelection()}>{t('cookies.save')}</span>
              </div>
              <div className={styles.links}>
                <a target='_blank' href={t('drawer.settings.imprintLink')}>
                  {t('drawer.settings.imprint')}
                </a>
                <a target='_blank' href={t('drawer.settings.datapolicyLink')}>
                  {t('drawer.settings.datapolicy')}
                </a>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CookieBanner;
