import React from 'react';

import classNames from 'classnames';

import styles from './field.module.scss';

const Field = ({ type, onChange, placeholder, disabled, value, invalid }) => {
  return (
    <input
      className={classNames(styles.field, {
        [styles.error]: invalid,
      })}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      disabled={disabled}
      value={value}
    />
  );
};

export default Field;
