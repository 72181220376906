import React from 'react';

import classNames from 'classnames';

import styles from './button.module.scss';

const Button = ({className, children, disabled, look, size = 'medium', type, onClick }) => {
  return (
    <button
      className={classNames(styles.basic, className, {
        [styles.disabled]: disabled,
        [styles.primaryRed]: look === 'primary-red',
        [styles.redBorder]: look === 'red-border',
        [styles.grey10]: look === 'grey-10',
        [styles.transparent]: look === 'transparent',
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.thin]: size === 'thin',
      })}
      disabled={disabled}
      type={type}
      onClick={() => {
        if (!type) onClick();
      }}
    >
      {children}
    </button>
  );
};

export default Button;
