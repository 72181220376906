import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import actions from '../../redux/actions';

import styles from './drawerContentProfile.module.scss';

import { router } from '../../router';
import Button from '../button/Button';
import LoginForm from '../login-form/LoginForm';
import Modal from '../modal/Modal';

const DrawerContentProfile = () => {
  const dispatch = useDispatch();

  const currentUserToken = useSelector((state) => state.user.token);
  const currentViewport = useSelector((state) => state.map.viewport);
  const markerList = useSelector((state) => state.map.markerList);
  const [modalOpen, setModalOpen] = useState(false);

  const [t] = useTranslation('translations');

  const [decodedToken, setDecodedToken] = useState(null);
  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    setDecodedToken(jwtDecode(currentUserToken));
  }, [currentUserToken]);

  const createShareLink = () => {
    const shareLink = `${window.location.origin}/#!/location/${currentViewport.latitude}/${currentViewport.longitude}/${currentViewport.zoom}`;
    navigator.clipboard.writeText(shareLink);
    setModalOpen(true);
  };

  const redirectToSearchlog = () => {
    router.navigate(`/${t('navigation.searchlog')}.html`);
  };

  const redirectReferenceSubmit = () => {
    router.navigate(`/${t('navigation.submitReference')}.html`);
  };
  const redirectReferenceList = () => {
    router.navigate(`/${t('navigation.submitedReferences')}.html`);
  };

  const goToMyAccount = () => {
    window.location.href = 'https://my.solarlux.com/apps/users/#/my-profile';
  };

  const signOut = () => {
    localStorage.removeItem('avatar');
    Cookies.remove('token');
    dispatch(actions.userActions.setFavorites(null));
    dispatch(actions.userActions.setUser({ avatar: null, token: null }));
  };

  return (
    <>
      <div className={styles.profile}>
        <div>
          {null === currentUserToken && (
            <div>
              <div className={styles.helpText}>{t('drawerContent.profile.loginHelpText')}</div>
              <LoginForm />
            </div>
          )}
          {null !== currentUserToken && (
            <div style={{ textAlign: 'center' }}>
              <div style={{ marginBottom: '1rem', marginLeft: '2rem', marginRight: '2rem' }}>
                <Button className={styles.fullWidth} look='grey-10' onClick={goToMyAccount}>
                  {t('drawerContent.profile.editProfile')}
                </Button>
              </div>
              {null !== decodedToken &&
                (decodedToken.customerNumber === '5002885' ||
                  decodedToken.customerNumber === '5050829') && (
                  <div style={{ marginBottom: '1rem', marginLeft: '2rem', marginRight: '2rem' }}>
                    <Button className={styles.fullWidth} look='grey-10' onClick={createShareLink}>
                      {t('drawerContent.profile.createSnippet')}
                    </Button>
                  </div>
                )}
              {null !== decodedToken &&
                decodedToken.authorities.includes('ROLE_CMS_SL_MITARBEITER') && (
                  <div style={{ marginBottom: '1rem', marginLeft: '2rem', marginRight: '2rem' }}>
                    <Button className={styles.fullWidth} look='grey-10' onClick={redirectToSearchlog}>
                      {t('drawerContent.profile.searchLog')}
                    </Button>
                  </div>
                )}
              <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                <Button className={styles.fullWidth} onClick={signOut} look='grey-10'>
                  {t('drawerContent.profile.signOut')}
                </Button>
              </div>

              {null !== decodedToken &&
                decodedToken.authorities.includes('ROLE_CMS_SL_MITARBEITER') && (
                  <div style={{ marginBottom: '1rem',  marginTop: '2rem', marginLeft: '2rem', marginRight: '2rem' }}>
                    <Button className={styles.fullWidth} look='grey-10' onClick={redirectReferenceSubmit}>
                      {t('drawerContent.profile.referenceSubmit')}
                    </Button>
                  </div>
                )}

              {null !== decodedToken &&
                decodedToken.authorities.includes('ROLE_CMS_SL_MITARBEITER') && (
                  <div style={{ marginBottom: '1rem', marginLeft: '2rem', marginRight: '2rem' }}>
                    <Button className={styles.fullWidth} look='grey-10' onClick={redirectReferenceList}>
                      {t('drawerContent.profile.referenceList')}
                    </Button>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.referenceNr}>
        {t('drawerContent.profile.references', { referencesNr: markerList.length })}
      </div>

      <Modal
        onClose={() => setModalOpen(false)}
        showModal={modalOpen}
        title={t('drawerContent.profile.snippetMessageTitle')}
      >
        <p className={styles.snippetMessage}>{t('drawerContent.profile.snippetMessage')}</p>
      </Modal>
    </>
  );
};

export default withTranslation('translations')(DrawerContentProfile);
