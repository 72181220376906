import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import { spacesApi } from '../../api';
import angleDownIcon from '../../assets/icons/angle-down.svg';
import angleRightIcon from '../../assets/icons/angle-right.svg';
import actions from '../../redux/actions';
import { router } from '../../router';
import Button from '../button/Button';
import LoginForm from '../login-form/LoginForm';
import PdfDocument from '../pdf-document/PdfDocument';
import ProjectFavorite from '../project-favorite/ProjectFavorite';
import ProjectTeaser from '../project-teaser/ProjectTeaser';
import Spinner from '../spinner/Spinner';
import Tabs from '../tabs/Tabs';
import styles from './overlayFavoritesPage.module.scss';
import Dnd from "../dnd/Dnd";
import SortableItem from "../sortable-item/SortableItem";
import Grid from "../grid/Grid";

const OverlayFavoritesPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();

  const currentFavorites = useSelector((state) => state.user.favorites);
  const allReferences = useSelector((state) => state.map.markerList);;
  const currentFavoriteReferences = useSelector((state) => state.user.favoriteReferences);
  const currentUserToken = useSelector((state) => state.user.token);
  const [favorites, setFavorites] = useState(null);
  const [filteredFavorites, setFilteredFavorites] = useState({});
  const [expanded, setExpanded] = useState(
      Object.keys(filteredFavorites).length > 0 ? [...Object.keys(filteredFavorites)[0]] : []
  );


  const [exportItems, setExportItems] = useState({});
  const [exportItemsFetched, setExportItemsFetched] = useState({});

  const [offset, setOffset] = useState(8);
  const [similarReferences, setSimilarReferences] = useState(null);
  const ref = useRef(null);


  const toggleExpand = (id) => {
    const expArray = JSON.parse(JSON.stringify(expanded));

    if(expArray.includes(id)) {
      const expIndex = expArray.findIndex((item) => item === id);
      expArray.splice(expIndex, 1);
    } else {
      expArray.push(id);
    }

    setExpanded(expArray);
  }

  useEffect(() => {
    const filteredFavorites = {};
    setFavorites(currentFavorites);

    if (currentFavorites) {
      currentFavorites.forEach((favorite) => {
        if (!filteredFavorites[favorite.product.tagId]) {
          filteredFavorites[favorite.product.tagId] = [];
        }

        filteredFavorites[favorite.product.tagId].push(favorite);
        filteredFavorites[favorite.product.tagId].sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
      });

      setFilteredFavorites(filteredFavorites);
      if(expanded.length === 0) {
        setExpanded([parseInt(Object.keys(filteredFavorites)[0])]);
      }
    }
  }, [currentFavorites, favorites]);

  useEffect(() => {
    if(favorites) {
      const ids = favorites.map(fav => fav.id)
      const items = allReferences.filter((item) => !ids.includes(item.properties.id))
      setSimilarReferences(items);
    }
  }, [favorites, currentFavoriteReferences, allReferences]);

  useEffect(() => {
    if (similarReferences && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [ref, similarReferences]);

  const navigateTo = (referenceId, slug) => {
    router.navigate(`/${t('navigation.reference')}/${referenceId}-${slug}.html`);
    window.scrollTo(0, 0);
  };

  const setExport = (referenceId) => {
    const items = JSON.parse(JSON.stringify(exportItems));
    if (!items[referenceId]) {
      spacesApi
        .get(`/spaces/references/${referenceId}`)
        .then(async (res) => {
          items[referenceId] = res.data;
          items[referenceId].image = await getImageBlobFromUrl(res.data.filenames[0]).then(() => {
            setExportItemsFetched(items);
          });

        })
        .catch((err) => {
          console.log(err);
        });
      items[referenceId] = {};
    } else {
      delete items[referenceId];
      setExportItemsFetched(items);
    }

    setExportItems(items);
  };

  const resetSelection = () => {
    setTimeout(() => {
      setExportItemsFetched({});
      setExportItems({});
    }, 0);
  };

  const fadeOutAndRemove = (id) => {
    const newFavorites = favorites.filter((item) => item.id !== id);
    dispatch(actions.userActions.removeFavorite(id, newFavorites));

    setTimeout(() => {
      dispatch(actions.userActions.setFavorites(newFavorites));
    }, 250);
  };


  const reorderItems = (items) => {
    dispatch(actions.userActions.reorderFavorites(items));
  };

  const loadMore = () => {
    const newOffset = offset + 8;
    setOffset(newOffset);
  };

  async function getImageBlobFromUrl(url) {
    return await axios
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => window.URL.createObjectURL(new Blob([response.data])));
  }

  return (
    <div>
      <Tabs>
        <div data-tab-label={t('favorites.saved')}>
          <div
            className={classNames(styles.favorites, {
              [styles.unauthenticated]: null === currentUserToken,
            })}
          >
            {null === currentUserToken && <LoginForm />}
            {null === favorites && null !== currentUserToken && (
              <div>
                <Spinner />
              </div>
            )}

            {null !== favorites &&
              null !== currentUserToken &&
              Object.values(filteredFavorites).map((items, index) => {
                return (
                  <div key={index}>
                    <div className={styles.favoritesGroupTitle}>
                      {expanded.includes(items[0].product.tagId) ? (
                        <>

                          <SVG
                              onClick={() => toggleExpand(items[0].product.tagId)}
                              className={styles.expanded} src={angleDownIcon} />
                          {items[0].product.tagValue}
                          <span
                              onClick={() => toggleExpand(items[0].product.tagId)}
                          >

                        </span>
                        </>
                      ) : (
                        <>
                          <SVG
                            onClick={() => toggleExpand(items[0].product.tagId)}
                            src={angleRightIcon}
                          />
                          <span
                            className={styles.favoritesGroupTitleText}
                            onClick={() => toggleExpand(items[0].product.tagId)}
                          >
                            {items[0].product.tagValue}
                          </span>
                        </>
                      )}
                    </div>
                    {expanded.includes(items[0].product.tagId) && (
                      <div className={styles.favoritesGroup}>
                        <Dnd
                          items={items}
                          fadeOutAndRemove={fadeOutAndRemove}
                          reorderItems={reorderItems}
                          exportItems={exportItems}
                          setExport={setExport}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

            {Object.keys(exportItemsFetched).length > 0 && (
              <div className={styles.favoritesExport}>
                <div className={styles.favoritesExportWrapper} onClick={resetSelection}>
                  <PDFDownloadLink
                    document={
                      <PdfDocument
                        data={exportItemsFetched}
                        favorites={favorites.filter((favorite) => exportItems[favorite.id])}
                        projectSlug={t('navigation.reference')}
                      />
                    }
                    fileName={`solarlux-references-${Math.floor(new Date().getTime() / 1000)}.pdf`}
                    className={styles.pdfDownloadLink}
                  >
                    {({ blob, url, loading, error }) => {
                      return loading ? t('favorites.exportLoading') : t('favorites.export');
                    }}
                  </PDFDownloadLink>

                  <div className={styles.favoritesExportBubble}>
                    {Object.keys(exportItems).length}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div data-tab-label={t('favorites.proposed')}>
          <div
            className={classNames(styles.proposed, {
              [styles.unauthenticated]: null === currentUserToken,
            })}
          >
            {null === currentUserToken && <LoginForm />}
            {null === similarReferences && null !== currentUserToken && (
              <div>
                <Spinner />
              </div>
            )}
            {null !== similarReferences &&
              null !== currentUserToken &&
                (<Grid columns={4}>
                  {similarReferences.map((item, index) => {
                    if(index < offset) {
                      return (

                          <ProjectTeaser
                              key={index}
                              image={item.properties.file}
                              title={item.properties.title}
                              product={item.properties.product}
                              onClick={() => {
                                navigateTo(item.properties.id, item.properties.slug);
                              }}
                          />
                      );
                    } else {
                      return null
                    }

                  })}
                </Grid>)
              }
          </div>
          {null !== similarReferences && null !== currentUserToken && (
            <div ref={ref} className={styles.loadMore}>
              <Button size='medium' look='grey-10' onClick={loadMore}>
                {t('favorites.load_more')}
              </Button>
            </div>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default withTranslation('translations')(OverlayFavoritesPage);
