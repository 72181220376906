import { combineReducers } from 'redux';
import defaultdata from './defaultdata';
import filter from './filter';
import history from './history';
import map from './map';
import overlay from './overlay';
import submitedReferences from './references';
import search from './search';
import searchlog from './searchlog';
import user from './user';

export default combineReducers({
  filter,
  map,
  overlay,
  search,
  user,
  history,
  searchlog,
  submitedReferences,
  defaultdata,
});
