import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { spacesApi } from '../../api';
import ProjectTeaser from '../project-teaser/ProjectTeaser';
import styles from './overlayDetailPageSimilarProjects.module.scss';

const OverlayDetailPageSimiliarProjects = ({ navigateTo, productId, typologyId }) => {
  const [t] = useTranslation('translations');

  const [isMobileViewport, setIsMobileViewport] = useState(false);
  const [projects, setProjects] = useState([1, 2, 3]);
  const sliderRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentImage(newIndex);
    },
  };

  const checkMobileViewport = () => {
    setIsMobileViewport(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobileViewport();
    spacesApi
      .get(`/spaces/references/${productId}/similiar`, {
        params: {
          typologyId: typologyId,
        },
      })
      .then((response) => {
        setProjects(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  window.addEventListener('resize', () => {
    checkMobileViewport();
  });

  return (
    <React.Fragment>
      {isMobileViewport && (
        <Slider className={styles.similarProjects} ref={sliderRef} {...settings}>
          {projects.map((project, index) => {
            return (
              <ProjectTeaser
                key={index}
                image={project.filename}
                title={project.title}
                isTopReference={project.topReference}
                product={project.product}
                onClick={() => {
                  navigateTo(project.id, project.slug);
                }}
              />
            );
          })}
        </Slider>
      )}
      {!isMobileViewport &&
        projects.map((project, index) => {
          return (
            <ProjectTeaser
              key={index}
              image={project.filename}
              title={project.title}
              isTopReference={project.topReference}
              product={project.product}
              onClick={() => {
                navigateTo(project.id, project.slug);
              }}
            />
          );
        })}
    </React.Fragment>
  );
};

export default withTranslation('translations')(OverlayDetailPageSimiliarProjects);
