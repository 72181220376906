import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import actions from '../../redux/actions';

import { router } from '../../router';

import { VIEW_MODES } from '../overlay/Overlay';
import {sendDataLayerEvent} from "../../gtm/datalayerSendEvent";

let pageData = window.pageData || {};

const Router = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation('translations');
  const gridParam = window.location.href
    .split('?')[1]
    ?.split('=')
    .find((el) => el === 'grid');

  if (gridParam) {
    localStorage.setItem('tileViewEnabled', true);
  }

  router
    .on(
      '/',
      ({ data, params, queryString }) => {
        dispatch(actions.overlayActions.setViewMode(null));
        dispatch(actions.overlayActions.setDetailPageMarkerId(null));
      },
      {
        before(done, match) {
          if (gridParam) {
            localStorage.setItem('tileViewEnabled', true);
            window.location.replace(window.location.origin);
          }
          done();
        },
      }
    )
    .on(`/${t('navigation.favorites')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.FAVORITES_PAGES));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.search')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.SEARCH_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.reference')}/:title`, (params) => {
      const { title } = params;
      const id = title.substr(0, title.indexOf('-'));

      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.DETAIL_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(id));
      dispatch(actions.overlayActions.loadReference(id));
    })
    .on(
      '/filter/typology/:id',
      (params, queryString) => {
        const gridParam = queryString.split('=').find((el) => el === 'grid');
        const typologies = params.id.split(',').map((id) => parseInt(id));
        const products = [];

        const options = { typologies, products };
        dispatch(actions.filterActions.setFilter(options));
        dispatch(actions.mapActions.filterMap(options));

        if (gridParam) {
          localStorage.setItem('tileViewEnabled', true);
        }
        router.navigate('/');
        window.scrollTo(0, 0);
      },
      {
        before(done, match) {
          if (gridParam) {
            localStorage.setItem('tileViewEnabled', true);
            window.location.replace(window.location.origin);
          }
          done();
        },
      }
    )
    .on('/filter/product/:id', (params) => {
      const typologies = [];
      const products = params.id.split(',').map((id) => parseInt(id));

      const options = { typologies, products };
      dispatch(actions.filterActions.setFilter(options));
      dispatch(actions.mapActions.filterMap(options));

      if (gridParam) {
        localStorage.setItem('tileViewEnabled', true);
      }

      router.navigate('/');
      window.scrollTo(0, 0);
    })
    .on('/location/:latitude/:longitude/:zoom', ({ latitude, longitude, zoom }) => {
      dispatch(
        actions.mapActions.setViewport({
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          zoom: parseInt(zoom),
          height: '100vh',
          width: '100%',
        })
      );

      router.navigate('/');
      window.scrollTo(0, 0);
    })
    .on(`/${t('navigation.history')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.HISTORY_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.searchlog')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.SEARCHLOG_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.submitReference')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.SUBMIT_REFERENCE_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.submitedReferences')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.SUBMITED_REFERENCES_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.notifications')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.NOTIFICATIONS_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .on(`/${t('navigation.help')}.html`, () => {
      dispatch(actions.overlayActions.closeDrawer());
      dispatch(actions.overlayActions.setViewMode(VIEW_MODES.HELP_PAGE));
      dispatch(actions.overlayActions.setDetailPageMarkerId(null));
    })
    .resolve();
  router.hooks({
    before: function(done, params) {
      const path = window.location.hash.replace('#!/', '').replace('.html', '');
      pageData.reference_name = '';

      if(!path) {
        pageData.page_title = 'Home';
      } else {
        const navigationKey = Object.keys(i18next.options.resources[pageData.language].translations.navigation).find(name => i18next.options.resources[pageData.language].translations.navigation[name] === path)
        if(navigationKey) {
          pageData.page_title = i18next.options.resources[pageData.language].translations[navigationKey].headline
        } else {
          let words = path.split('-');

          for (let i = 0; i < words.length; i++) {
            let word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
          }

          words.shift()
          pageData.page_title = words.join(' ');
          pageData.reference_name = words.join(' ');
        }
      }

      sendDataLayerEvent('page_view')
      done();
    },
  });

  return <div style={{ display: 'none' }} />;
};

export default withTranslation('translations')(Router);
