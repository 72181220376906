import * as Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

let pageData = window.pageData = {
    "page_type": 'site_page',
    "reference_name": '',
    "client_id": 0,
    "language": '',
    "page_title": '',
}

export const preFetch = (i18next) => {
    const path = window.location.hash.replace('#!/', '').replace('.html', '');

    const i18nextLng = localStorage.getItem('i18nextLng');
    const currentUserToken = Cookies.get('token');
    pageData.reference_name = '';

    pageData.language = i18nextLng;
    if(!path) {
        pageData.page_title = 'Home';
    } else {
        const navigationKey = Object.keys(i18next[pageData.language].translations.navigation).find(name => i18next[pageData.language].translations.navigation[name] === path)
        if(navigationKey) {
            pageData.page_title = i18next[pageData.language].translations[navigationKey].headline

        } else {
            let words = path.split('-');

            for (let i = 0; i < words.length; i++) {
                let word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }

            words.shift()
            pageData.page_title = words.join(' ');
            pageData.reference_name = words.join(' ');
        }
    }



    if(currentUserToken) {
        const decoded = jwtDecode(currentUserToken);
        pageData.client_id = decoded.id
    }

}
